<div class="bank-statement-loan-payment">
  <div class="row mb-4">
    <div class="col-12">
      <div fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button
        class="w-lt-md-100"
        (click)="ngOnInit()">
        <span class="label"> Reset </span>
      </button>
        <button mat-flat-button
          class="w-lt-md-100"
          (click)="addTransactions()">
          <!-- <span class="mdi mdi-plus" matPrefix></span> -->
          <span class="label"> Add Transactions </span>
        </button>
        <button mat-flat-button color="primary"
          (click)="onConfirmLoanPayment()"
          class="w-lt-md-100">
          Confirm Loan Payment
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8 col-md-12 mr-auto">
      <div fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign="start center"
          >
          <div class="col-lg-6 col-md-12">
            <mat-form-field fxFlex="1 1 auto" fxFlexAlign="start" class="search">
              <input matInput type="text" class="ml-4" placeholder="Search for transactions..." [formControl]="formControlSearch">
              <span class="mdi mdi-magnify" matPrefix></span>
            </mat-form-field>
          </div>
      </div>
    </div>
    <div class="col-lg-col-md-12 auto mb-1 mr-4">
      <button class="custom-button w-lt-md-100 mr-2" (click)="deleteAll()">
        <span class="mdi mdi-delete-outline icon"></span>
        <span class="text">Delete All</span>
      </button>
      <button class="custom-button w-lt-md-100" (click)="exportCsv()">
        <span class="mdi mdi-export icon"></span>
        <span class="text">Export</span>
      </button>
    </div>
  </div>

  <ng-container>
    <table mat-table [dataSource]="displayedData" class="width-100 mb-2" multiTemplateDataRows matSort (matSortChange)="onSort($event)">
      @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
      <th mat-header-cell mat-sort-header *matHeaderCellDef [arrowPosition]="needAlignRight(column) ? 'before' : 'after'" [ngClass]="{
        'column-width-0': column === 'actions',
        'column-width-2': column === 'date' || column === 'party',
        'column-width-3': column === 'transaction' || column === 'category' || column === 'otherInfo',
        'text-align-right': needAlignRight(column)
      }"> <div class="cell">{{getColumnTitles(column)}}</div> </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
              <div class="cell">
                @if (column !== 'actions') {
                  @if(needCurrencyPipe(column)){
                    <span>{{ displayColumnValue(element, column) | looseCurrency }}</span>
                  }
                  @if(!needCurrencyPipe(column)){
                    <span>{{ displayColumnValue(element, column) }}</span>
                  }
                }
                @if (column === 'actions') {
                  <span class="mdi mdi-delete-outline cursor-pointer" (click)="delete(element)"></span>
                }
              </div>
            </td>
          </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row {{bankTransactionCatogory(element.category)}}">
        </tr>
    </table>

    @if (total<1) {
      <table class="not-found">
        <tr>
          <td> No data found </td>
        </tr>
      </table>
    }
  </ng-container>

    @if (total) {
      <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
      [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
    }
  </div>
