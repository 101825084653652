import {Component, forwardRef, Input,Output, OnInit,EventEmitter, SimpleChanges} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {createNoDecimalInputMask, createThreeDecimalInputMask, createTwoDecimalInputMask, formControlErrorKeys, formControlErrorMessage, maxValidator, minMaxValidator, minValidator, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import {
  BusinessLoanTermValueOptions,
  compareMatch,
  LoanTermsSelectionWithInputValue,
  LoanTermValueOptions,
  NumberInputValue
} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {InputMaskModule} from "@ngneat/input-mask";


@UntilDestroy()
@Component({
    selector: 'loan-terms-selection-with-input',
    templateUrl: './loan-terms-selection-with-input.component.html',
    styleUrls: ['./loan-terms-selection-with-input.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LoanTermsSelectionWithInputComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => LoanTermsSelectionWithInputComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, InputMaskModule, MatInputModule, MatAutocompleteModule, MatSelectModule, MatOptionModule, DisableControlDirective]
})
export class LoanTermsSelectionWithInputComponent extends AbstractControlValueAccessor<LoanTermsSelectionWithInputValue> implements OnInit, Mark{

  @Input({required: false}) required = true;
  @Input({required: false}) min: number | null = 0;
  @Input({required: false}) max: number | null = null;
  @Input({required: false}) decimals: 0 | 2 | 3 = 0;
  @Input({required: false}) businessTermLoans: boolean = false;

  inputMask  = createNoDecimalInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  Number = Number;
  formGroup: FormGroup<{
    selection: FormControl<NumberInputValue>;
  }>;
  formControl: FormControl<NumberInputValue>;
  subscription: Subscription;

  options = LoanTermValueOptions;

  constructor(private formBuilder: FormBuilder) {
    super();
    const validators = this.createValidators();
    this.formControl = formBuilder.control(null, validators);
    this.formGroup = formBuilder.group({
      selection: this.formControl
    });
    this.subscription = this.formControl.valueChanges.pipe(
      distinctUntilChanged(compareMatch),
      tap((r: NumberInputValue) => {
        console.log('======value changes: ', this.formControl.value, this.formControl)
        if (this.formControl.valid) {
          console.log('propagate: ', Number(this.formControl.value ?? 0))
          this.propagateChange(Number(this.formControl.value ?? 0));
       } else {
        console.log('propagate: ', null)

         this.propagateChange(null);
       }
      })
    ).subscribe();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    if (this.businessTermLoans) {
      this.options = BusinessLoanTermValueOptions;
    }
  }

  createValidators(): ValidatorFn[] {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.min != null && this.max != null) {
      validators.push(minMaxValidator(this.min, this.max));
    } else if (this.min != null) {
      validators.push(minValidator(this.min));
    } else if (this.max != null) {
      validators.push(maxValidator(this.max));
    }
    return validators;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if ((changes as any).min || (changes as any).max || (changes as any).required) {
        if (this.formControl) {
          const validators = this.createValidators();
          this.formControl.setValidators(validators);
          this.formControl.updateValueAndValidity();
          if (this.formControl.invalid) {
            this.formControl.setValue(null);
            // this.propagateChange(null);
          }
        }
      } else if ((changes as any).disabled) {
        if (this.formControl) {
          const isDisabled = (changes as any).disabled.currentValue;
          if (isDisabled) {
            this.formControl.disable();
          } else {
            this.formControl.enable();
          }
        }
      }
    }
  }

  doWriteValue(v: LoanTermsSelectionWithInputValue): void | LoanTermsSelectionWithInputValue {
    this.formControl.setValue(v);
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }

  reset(): void {
    this.formControl.reset();
  }

  onTermSelected($event: MatAutocompleteSelectedEvent) {
    const termSelected: string = $event.option.value;
    const termValue = Number(termSelected);
    this.formControl.setValue(termValue);
    // this.propagateChange(companySelected);
    // this.selected = true;
  }
}
