<div class="basiq-categories-table">
  @if(showFilter()) {
    <div>
      <div class="row">
        <div class="col-12 accounts" fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center">
          @for (account of accounts; track account){
            <mat-checkbox color="primary" [formControl]="accountFilter[account.id]" (change)="filterChangeHandler()">
              {{ account.accountHolder }} ({{ account.name }})
            </mat-checkbox>
          }
        </div>
      </div>
    </div>
  }

  @if(accountSelectionChanges && !singleAccount){
  <div >
    <div class="row">
      <div class="col-12" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center start">
        @if (!firstTimeLoadData) {
          <span class="text-primary">Account selection has been changed. Click to reload data</span>
        }
        @if (firstTimeLoadData) {
          <span class="text-primary">Click to load data</span>
        }
        <button class="reload-button arrow-left w-lt-md-100" (click)="reloadChartAndTable()">
          <span class="mdi mdi-refresh"></span>
        </button>
      </div>
    </div>
  </div>
}

@if(dayEndBalanceChartData.length){
  <div class="row mt-3">
    <div class="col-12">
      <div>Day End Balance Chart for Selected Accounts</div>
      <day-end-balance-chart [data]="dayEndBalanceChartData" [index]="999" [lightTheme]="true"></day-end-balance-chart>
    </div>
  </div>
}

  <div class="table-section">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
      @for (column of columnsToDisplay; track column){
        <ng-container matColumnDef="{{ column }}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'text-align-right': needAlignRight(column) }">
            <div class="cell">{{ getColumnTitles(column) }}</div>
          </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-align-right': needAlignRight(column) }">
            <div class="cell">
              @if (column === 'icon'){
                <div class="cell">
                  @if (expandedElement === element){
                    <span class="mdi mdi-chevron-up"></span>
                }
                  @if (expandedElement !== element){
                    <span class="mdi mdi-chevron-down"></span>
                }
                </div>
              }
              @if (column !== 'icon'){
                <div class="cell">
                  @if (element[column] === ''){
                    Uncategorized Transactions
                  }
                  @if (element[column] !== ''){
                    @if (needCurrencyPipe(column)){
                      {{ element[column] | looseCurrency }}
                    }
                    @if (!needCurrencyPipe(column)){
                      {{ element[column] }}
                    }
                  }
                </div>
              }
            </div>
          </td>
        </ng-container>
      }

      <!-- Expanded Content Column -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div class="row mt-3">
              <div class="col">
                @if(element.transactions){
                  <basiq-category-details-table [data]="element.transactions" (checkbox)="handleCheckboxChange($event, i)"></basiq-category-details-table>
                }
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    @if(isData){
    <div class="not-found">
      <table>
        <tr>
          <td>No data found</td>
        </tr>
      </table>
    </div>
  }
  </div>

  <div class="mt-3">
    <div class="col-12">
      <div>Monthly Debits / Credits for Selected Accounts</div>
      <basiq-monthly-debits-credits-chart [data]="selectedDebitCredit" [index]="999" [lightTheme]="true"></basiq-monthly-debits-credits-chart>
    </div>
  </div>
</div>
