import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AccountFilter, AccountMonthlyBalance, AccountSummary, BankStatementsAnalysisData, BasiqAccount, BasiqTransaction, DebitCreditSummary, GetBsaCalculatorFn, SaveBsaCalculatorFn } from '@portal-workspace/grow-shared-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { DatePipe, NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from "@angular/flex-layout";
import { BasiqFullTransactionListComponent } from './basiq-full-transaction-list.component';
import { BasiqCategoriesTableComponent } from './basiq-categories-table.component';
import { DayEndBalanceChartComponent } from '../bank-statement-component/day-end-balance-chart.component';
import { BasiqMonthlyDebitsCreditsChartComponent } from './basiq-monthly-debits-credits-chart.component';
import { MonthlyDebitsCreditsTableComponent } from '../bank-statement-component/monthly-debits-credits-table.component';

@Component({
  selector: 'basiq-statement-overview',
  templateUrl: './basiq-statement-overview.component.html',
  styleUrls: ['./basiq-statement-overview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [MatTableModule, NgClass, ExtendedModule, MatButtonModule, FormsModule, FlexModule, ReactiveFormsModule, MatCheckboxModule, LooseCurrencyPipe, BasiqFullTransactionListComponent, BasiqCategoriesTableComponent, DayEndBalanceChartComponent, BasiqMonthlyDebitsCreditsChartComponent, MonthlyDebitsCreditsTableComponent, DatePipe]
})
export class BasiqStatementOverviewComponent implements OnInit, OnChanges {
  @Input({ required: true }) accounts!: BasiqAccount[];
  @Input({ required: true }) transactions!: BasiqTransaction[];
  @Input({ required: false }) selectedAccounts: string[] = [];
  // @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  // @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Output() accountSelector = new EventEmitter();

  dataSource: BasiqAccount[] = [];
  columnsToDisplay = ['icon', 'BankName', 'BSBAccNumber', 'AccHolder', 'AccName', 'balance', 'CreditLimit', 'product', 'selector'];
  accountFilter: AccountFilter = {};
  expandedElement!: BasiqAccount | null;
  transactionListSelectedAccounts: string[] = [];
  totalDebitCredit: AccountMonthlyBalance | null = null;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.init();
    this.updateDebitCredit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init() {
    this.dataSource = this.accounts;
    this.initAccountFilter();
    this.accounts.forEach((account: BasiqAccount) => {
      this.accountFilter[account.id].setValue(this.selectedAccounts.includes(account.id));
    })
    this.transactionListSelectedAccounts = this.selectedAccounts;
  }

  initAccountFilter() {
    this.accounts.forEach((account: BasiqAccount) => {
      this.accountFilter[account.id] = new FormControl<boolean>(false);
    })
  }

  isEmpty(obj: DebitCreditSummary, table: 'overdrawn' | 'non-preferred-lender' | 'dishonours'): boolean {
    switch (table) {
      case 'non-preferred-lender':
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
      case 'overdrawn':
        return !obj.numdebit;
      case 'dishonours':
        return !obj.numdebit;
      default:
        return !obj.details?.length && !obj.numcredit && !obj.numdebit;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'BankName': return 'Bank Name';
      case 'BSBAccNumber': return 'BSB / Acc. Number';
      case 'AccHolder': return 'Acc. Holder';
      case 'AccName': return 'Acc. Name';
      case 'balance': return 'Balance';
      case 'CreditLimit': return 'Credit Limit';
      case 'product': return 'Product';
      case 'icon': return '';
      case 'selector': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['balance', 'CreditLimit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['currentBalance', 'CreditLimit', 'BSBAccNumber'].includes(column);
  }

  displayColumnValue(element: BasiqAccount, column: string): string {
    switch (column) {
      case 'BankName': return element.institutionDetails?.name ?? element.institution;
      case 'BSBAccNumber': return element.bsb + `${element.bsb ? ',' : '' }` + element.accountNo;
      case 'AccHolder': return element.accountHolder;
      case 'AccName': return element.name;
      case 'balance': return element.balance;
      case 'CreditLimit': return element.creditLimit;
      case 'product': return element.class.product;
      case 'icon': return '';
      case 'selector': return '';
      default: return column;
    }
  }

  onClickRow(element: BasiqAccount) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  onConfirmAccountSelection() {
    this.selectedAccounts = this.accounts.filter((account: BasiqAccount) =>
      this.accountFilter[account.id].value
    ).map((account: BasiqAccount) => account.id);
    this.transactionListSelectedAccounts = this.selectedAccounts;
    this.accountSelector.emit(this.selectedAccounts);
    console.log(this.selectedAccounts)
  }

  viewAccountTransactions(element: BasiqAccount) {
    console.log(element);
    this.transactionListSelectedAccounts = [element.id];
    const el = document.getElementById("transaction-list");
    if (el) {
      el.scrollIntoView();
    }
  }

  updateDebitCredit() {
    const debitCredit: AccountMonthlyBalance = {
      averagecredit: 0,
      averagedebit: 0,
      enddate: '',
      enddatecredit: '',
      enddatedebit: '',
      monthlycredit: {},
      monthlydebit: {},
      numcredit: 0,
      numdebit: 0,
      startdate: '',
      startdatecredit: '',
      startdatedebit: '',
      totalcredit: 0,
      totaldebit: 0
    }

    this.accounts.forEach(account => {
      const balance = account.monthlyBalance;
      console.log('balance for selected accounts', balance);
      if (!balance) return;

      debitCredit.totalcredit += balance.totalcredit;
      debitCredit.totaldebit += balance.totaldebit;
      debitCredit.numcredit += balance.numcredit;
      debitCredit.numdebit += balance.numdebit;

      if (!debitCredit.startdate || balance.startdate < debitCredit.startdate) {
        debitCredit.startdate = balance.startdate;
      }
      if (!debitCredit.enddate || balance.enddate > debitCredit.enddate) {
        debitCredit.enddate = balance.enddate;
      }
      if (!debitCredit.startdatecredit || balance.startdatecredit < debitCredit.startdatecredit) {
        debitCredit.startdatecredit = balance.startdatecredit;
      }
      if (!debitCredit.enddatecredit || balance.enddatecredit > debitCredit.enddatecredit) {
        debitCredit.enddatecredit = balance.enddatecredit;
      }
      if (!debitCredit.startdatedebit || balance.startdatedebit < debitCredit.startdatedebit) {
        debitCredit.startdatedebit = balance.startdatedebit;
      }
      if (!debitCredit.enddatedebit || balance.enddatedebit > debitCredit.enddatedebit) {
        debitCredit.enddatedebit = balance.enddatedebit;
      }

      // Combine monthly credit and debit
      Object.entries(balance.monthlycredit).forEach(([month, amount]) => {
        debitCredit.monthlycredit[month] = (debitCredit.monthlycredit[month] || 0) + amount;
      });

      Object.entries(balance.monthlydebit).forEach(([month, amount]) => {
        debitCredit.monthlydebit[month] = (debitCredit.monthlydebit[month] || 0) + amount;
      });
    });

    debitCredit.averagecredit = debitCredit.totalcredit / debitCredit.numcredit || 0;
    debitCredit.averagedebit = debitCredit.totaldebit / debitCredit.numdebit || 0;

    console.log('debitCredit', debitCredit);

    this.totalDebitCredit = debitCredit;
  }
}
