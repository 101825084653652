import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import {getUser, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {OVERDRAFT_PRODUCT_ACCESS_LEVEL} from '@portal-workspace/grow-shared-library';
import { navigationUrlForNewApplication } from '../service/navigation-urls';


export const overdraftProductAccessGuard = (): CanActivateFn => {
  return (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) => {
    const router = inject(Router);
    const user = getUser()

    if (user?.priviledges.some(priviledge => OVERDRAFT_PRODUCT_ACCESS_LEVEL.includes(priviledge))) {
      return true;
    }
    return router.navigate(navigationUrlForNewApplication())
  }
}


