<div class="full-transaction-list">
  <div class="row">
    <div class="col-lg-8 col-md-12 mr-auto">
      @for (control of formControlSearchList.controls; track control; let i = $index) {
        <div fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign="start center"
          >
          <div class="col-lg-6 col-md-12">
            <mat-form-field fxFlex="1 1 auto" fxFlexAlign="start" class="search">
              <input matInput type="text" class="ml-4" placeholder="Search for transactions..." [formControl]="$any(control)">
              <span class="mdi mdi-magnify" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 "
            fxLayout="row"
            fxLayoutGap="5px"
            fxLayoutAlign="start center">
            @if (i === 0) {
              <div class="col-lg-6 col-md-12">
                <mat-form-field>
                  <mat-select [formControl]="formControlSearchLogic">
                    @for (option of searchLogicOptions; track option) {
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            }
            @if (i !== 0) {
              <span class="col-lg-6 col-md-12 logic-padding-bottom">{{formControlSearchLogic.value}}</span>
            }
            @if (i == formControlSearchList.controls.length - 1) {
              <span class="mdi mdi-plus icon-padding-bottom cursor-pointer" (click)="addSearchControl()"></span>
            }
            @if (i > 0) {
              <span class="mdi mdi-delete icon-padding-bottom cursor-pointer" (click)="removeSearchControl(i)"></span>
            }
          </div>
        </div>
      }
    </div>
    <div class="col-lg-col-md-12 auto mb-1 mr-4">
      <button class="custom-button w-lt-md-100" (click)="exportCsv()">
        <span class="mdi mdi-export icon"></span>
        <span class="text">Export</span>
      </button>
    </div>
  </div>

  @if (showFilter()) {
    <div class="row">
      <div class="col-12 accounts" fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start center">
        @for (account of accounts; track account) {
          <mat-checkbox color="primary" [formControl]="accountFilter[account.id]" (change)="filterChangeHandler()">
            {{account?.accountHolder + "-" + account?.accountName + " (" + account?.bsbAccNumber + ")"}}
          </mat-checkbox>
        }
      </div>
    </div>
  }

  @if (accountSelectionChanges && !singleAccount) {
    <div class="row mb-2">
      <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="start center">
        @if (!firstTimeLoadData) {
          <span class="text-primary">Account selection has been changed. Click to reload data</span>
        }
        @if (firstTimeLoadData) {
          <span class="text-primary">Click to load data</span>
        }
        <button class="reload-button arrow-left" (click)="reloadTable()">
          <span class="mdi mdi-refresh"></span>
        </button>
      </div>
    </div>
  }

  <ng-container>
    <table mat-table [dataSource]="displayedData" class="width-100 mb-2" multiTemplateDataRows matSort (matSortChange)="onSort($event)">
      @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
      <th mat-header-cell mat-sort-header *matHeaderCellDef [arrowPosition]="needAlignRight(column) ? 'before' : 'after'" [ngClass]="{
        'column-width-2': column === 'date' || column === 'party',
        'column-width-3': column === 'transaction' || column === 'category' || column === 'otherInfo',
        'text-align-right': needAlignRight(column)
      }"> <div class="cell">{{getColumnTitles(column)}}</div> </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
              <div class="cell">
                @if(needCurrencyPipe(column)){
                  <span>{{ displayColumnValue(element, column) | looseCurrency }}</span>
                }
                @if(!needCurrencyPipe(column)){
                  <span>{{ displayColumnValue(element, column) }}</span>
                }
              </div>
            </td>
          </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row {{toClass(element.category)}}">
        </tr>
      </table>

      @if (total<1) {
        <table class="not-found">
          <tr>
            <td> No data found </td>
          </tr>
        </table>
      }
    </ng-container>

    @if (total) {
      <app-custom-paginator [length]="total" [pageIndex]="offset" [pageSize]="limit"
      [pageSizeOptions]="[10, 20, 25, 50, 100]" (page)="onPagination($event)"></app-custom-paginator>
    }
  </div>
