import {Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef, OnChanges, SimpleChanges} from '@angular/core';
import {
  AzureStorageDocument,
  Metadata,
  getFileTags,
  DocumentTag,
  AzureFileTagable,
  AzureFileMetadatable, UploadAzureFiles, UploadAzureFileLike,
  UploadAzureFilesValue,
  UploadFileDialogData,
  UploadFilesMultiTagsDialogResult,
  FileWithTags
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApplicationDialogService, duplicateFileNameValidator, formControlErrorKeys, formControlErrorMessage, isMissingTagsCheckValidator, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UploadFilesMultiTagsDialog } from '../application-dialog-component/upload-files-multi-tags.dialog';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'upload-files-multi-tag-dialog',
    templateUrl: './upload-files-multi-tag-dialog.component.html',
    styleUrls: ['./upload-files-multi-tag-dialog.component.scss'],
    standalone: true,
    exportAs: 'uploadFilesMultiTagDialogComponent',
    imports: [MatButtonModule, MatFormFieldModule, FlexModule,UploadFileComponent,FormsModule, ReactiveFormsModule, MatCardModule, MatChipsModule, FlexModule, MatButtonModule, MatDialogModule,MatTooltipModule, NgClass]
})
export class UploadFilesMultiTagDialogComponent implements OnInit {

 

  getFileTags = getFileTags;
  @Input({required: false}) docs: UploadAzureFileLike[] = [];
  @Input({required: false}) metadata: Metadata = {};
  @Input({required: false}) defaultTags: DocumentTag[] = [];
  @Input({required: false}) buttonText: string = "Upload Documents";
  @Output() uploadDocs = new EventEmitter<UploadAzureFiles>();
  @Output() deleteDoc = new EventEmitter<number>();
 


  formGroup: FormGroup<{
    upload: FormControl<UploadAzureFilesValue>,
  }>;
  formControl: FormControl<UploadAzureFilesValue>;
  subscriptions: Subscription[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTags: DocumentTag[] = this.defaultTags ?? [];
  tags: DocumentTag[] = [];
  selectableTags: DocumentTag[] = [];
  enableCustomTag: boolean = false;
  MAX_TAG_ALLOWED = 10;
  showMaxTagErrorMessage = false;
  files: File[] = [];
  filesWithTags: FileWithTags[] = [];
  hasErrors: boolean = false;
  //constructor(private applicationDialogService: ApplicationDialogService) { }

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  constructor(private formBuilder: FormBuilder,
      private dialogService: ApplicationDialogService) {
      this.formControl = this.formBuilder.control(null, [Validators.required,duplicateFileNameValidator()]);

      this.formGroup = this.formBuilder.group({
        upload: this.formControl,
      });
  }

  
  ngOnInit(): void {
    setupUntilDestroy(this)
    this.hasErrors=true
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      tap(arg => {
        if(arg){

          const addedNewFileCheck = arg.filter(file => !this.files.some(prevFile => prevFile.name === file.name));
          this.files = arg
          this.uploadDocs.emit(this.fileData);
          // Log added files
          if (addedNewFileCheck.length === 1) {
              this.openSelectableTagsDialog(addedNewFileCheck[0]);
          }
        }
        
      })
    ).subscribe());
    this.allTags = this.defaultTags
  }

/*   onUploadDoc() {
    this.subscriptions.push(this.dialogService.openUploadFileMultiTagsDialog({
        title: 'Upload document',
        allTags: this.defaultTags ?? [],
        metadata: this.metadata
      }).afterClosed().pipe(
        tap((r) => {
          if (r && r.valid) {
            this.uploadDocs.emit(r.files);
          }
        })
      ).subscribe()
    )
  } */

   openSelectableTagsDialog(file: File){

    this.dialogService.openSelectableTagsDialog({
      selectableTags: this.selectableTags,
      tagsSel: this.getTagsByFileName(file),
      allTags: this.allTags,
      showMaxTagErrorMessage: this.showMaxTagErrorMessage,
    }).afterClosed().pipe(
      tap((r) => {
        if (r && r.valid) {
          this.addFileWithTags(file,r.selectedTags)
          this.uploadDocs.emit(this.fileData);
          if(r.selectedTags.length){
            this.hasErrors=false
          }
        }
      })
    ).subscribe()

   }
  editFile($event: MouseEvent, file: File) {
    this.openSelectableTagsDialog(file);
  }

  addFileWithTags(fileName: File, fileTags: DocumentTag[]): void {
    const fileIndex = this.getFileIndexByFilename(fileName)
    
    const existingFile = this.filesWithTags[fileIndex];
    if (existingFile) {
      // Override the tags for the existing file
       existingFile.fileTags = fileTags;
    } else {
      // Add a new file with tags
      const newFileWithTags: FileWithTags = {  fileName:fileName.name ,fileTags };
      this.filesWithTags[fileIndex] = newFileWithTags
    } 
 }

 removeSingleTagFromFile(file: File, tagValue: DocumentTag): void {
   const existingFileIndex = this.getFileIndexByFilename(file)
   //this.filesWithTags[existingFileIndex].fileTags.filter(tag => tag.value !== tagValue.value);
   const fileData = this.filesWithTags[existingFileIndex];
   if (fileData) {
       // remove max tag error message
       if (fileData.fileTags.length < this.MAX_TAG_ALLOWED) {
         this.showMaxTagErrorMessage = false;
       }
      
     fileData.fileTags = fileData.fileTags.filter(tag => tag.value !== tagValue.value);
     this.uploadDocs.emit(this.fileData);
     if(fileData.fileTags.length==0){
      this.hasErrors=true
     }
   }
    
 }

 removeAllTagFromFile(file: File): void {
 
  const existingFileIndex = this.getFileIndexByFilename(file)
  const newFileWithTags: FileWithTags = {  fileName:null ,fileTags:[] };
  this.filesWithTags[existingFileIndex] = newFileWithTags
 }

 getTagsByFileName(file: File): DocumentTag[] {
   const index = this.getFileIndexByFilename(file)
   const tagList = this.filesWithTags[index];
   return tagList ? tagList.fileTags : []; 
 }

 hasTagsFile(file: File):boolean {
  const index = this.getFileIndexByFilename(file)
  const tagList = this.filesWithTags[index];
   
  if(tagList && tagList.fileTags.length > 0){
      return true
  }else {
     return false
  }
}

   

 getTagsValueByFileName(file: File) {
  const index = this.getFileIndexByFilename(file)
  const tagList = this.filesWithTags[index];
  if(tagList){
    const tagValue = tagList.fileTags.map(tag => tag.value);
    return tagValue ? tagValue : []; 
  }else {
     return []
  }
  
}

 getFileIndexByFilename(file: File){
   return  this.files.indexOf(file);
 }

 deleteFile($event: MouseEvent, file: File) {
  this.removeAllTagFromFile(file)
  this.files = this.files.filter(f => {
    return f !== file;
  });
  this.deleteDoc.emit(this.getFileIndexByFilename(file));
  this.formControl.setValue(this.files)
   
}

  onDeleteDoc(index: number) {
    this.deleteDoc.emit(index);
  }

  
  get fileData() {
    return this.formControl?.value?.map((file) => {
      const tagValue = this.getTagsValueByFileName(file);
      (file).tags = tagValue;
      return file;
    })
  }
}
