import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InputMaskModule } from '@ngneat/input-mask';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { BasiqFullTransactionListComponent } from './basiq-full-transaction-list.component';
import { BasiqStatementOverviewComponent } from './basiq-statement-overview.component';
import { BasiqCategoriesTableComponent } from './basiq-categories-table.component';
import { BasiqCategoryDetailsTableComponent } from './basiq-category-details-table.component';
import { BasiqMonthlyDebitsCreditsChartComponent } from './basiq-monthly-debits-credits-chart.component';
import { BasiqStatementComponent } from './basiq-statement.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputMaskModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        BasiqFullTransactionListComponent,
        BasiqStatementOverviewComponent,
        BasiqCategoriesTableComponent,
        BasiqCategoryDetailsTableComponent,
        BasiqMonthlyDebitsCreditsChartComponent,
        BasiqStatementComponent
    ],
    exports: [BasiqFullTransactionListComponent, BasiqStatementOverviewComponent, BasiqCategoriesTableComponent, BasiqCategoryDetailsTableComponent, BasiqMonthlyDebitsCreditsChartComponent, BasiqStatementComponent]
})
export class BasiqStatementModule { }
