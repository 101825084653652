import {Component, forwardRef, inject, Input, OnInit} from "@angular/core";
import {AbstractControlValueAccessor} from "../abstract-control-value-accessor";
import {
  compareMatch,
  CurrencyInputValue,
  CurrencyWithPeriodValue,
  PaymentPeriodType
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  formControlErrorKeys,
  formControlErrorMessage,
  pxToRem,
  setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import {Mark, MARK} from '@portal-workspace/grow-ui-library/mark';
import {
  FormBuilder,
  FormControl, FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgxCurrencyDirective} from "ngx-currency";
import {FlexModule} from "@angular/flex-layout";
import {MatOption, MatSelect} from "@angular/material/select";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import {CurrencyInputComponent} from '../currency-selection-component/currency-input.component';
import {DisableControlDirective} from '../../directives/disable-control.directive';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'currency-with-period',
  templateUrl: './currency-with-period.component.html',
  styleUrls: ['./currency-with-period.component.scss'],
  standalone: true,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>CurrencyWithPeriodComponent), multi: true},
    {provide: MARK, useExisting: forwardRef(()=>CurrencyWithPeriodComponent)},
  ],
  imports: [
    FormsModule,
    FlexModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxCurrencyDirective,
    DisableControlDirective,
    CurrencyInputComponent,
    MatSelect,
    MatOption,
  ]
})
export class CurrencyWithPeriodComponent extends AbstractControlValueAccessor<CurrencyWithPeriodValue> implements OnInit, Mark {

  pxToRem = pxToRem;

  @Input({required: false}) title = 'Income';
  @Input({required: false}) titleCurrency = 'Currency ';

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  subscriptions: Subscription[] = [];

  periods: PaymentPeriodType[] = ['Weekly', 'Monthly', 'Fortnightly', 'Quarterly'];

  formBuilder = inject(FormBuilder);
  formControlAmount: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPeriod: FormControl<PaymentPeriodType | null> = this.formBuilder.control(null, [Validators.required]);
  formGroup: FormGroup<{
    amount: FormControl<CurrencyInputValue>,
    period: FormControl<PaymentPeriodType | null>
  }> = this.formBuilder.group({
    amount: this.formControlAmount,
    period: this.formControlPeriod,
  });

  mark() {
    this.formGroup.markAllAsTouched();
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(v => {
        if (this.formGroup.valid) {
          this.propagateChange({
            amount: this.formControlAmount.value!,
            period: this.formControlPeriod.value!,
          })
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe());
  }

  override doWriteValue(v?: CurrencyWithPeriodValue | null): void | CurrencyWithPeriodValue {
    if (v) {
      this.formControlAmount.setValue(v.amount);
      this.formControlPeriod.setValue(v.period);
    }
    return undefined;
  }
}
