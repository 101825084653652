import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Observable, of, Subject, Subscription, tap} from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  EnableDisable2faFn, Add2faSecretKeyFn, Get2faTotpVerificationCodeUriFn, GetUserFn,
  LogoutFn,
  MatchConfirmPasswordFn,
  NameComponentValue,
  SlideToggleValue,
  User, Verify2faTokenFn, canChangeOwn2faAccessLevel, isInternalUser, PortalThemeServiceEvent, ChangePortalThemeFn
} from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { getUser } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { createAsyncState, createAsyncStore } from '@ngneat/loadoff';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { ProfileComponentEvent } from '@portal-workspace/grow-shared-library';
import { ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { ToolbarComponentEvent } from '@portal-workspace/grow-ui-library';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SlideToggleComponent } from '../slide-toggle-component/slide-toggle.component';
import { EmailComponent } from '../common fields/email.component';
import { MatDividerModule } from '@angular/material/divider';
import { MobileComponent } from '../mobile-component/mobile.component';
import { NameComponent } from '../name-component/name.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import {FlexModule} from "@angular/flex-layout";
import {PortalThemeComponent} from "../portal-theme-component/portal-theme.component";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
  imports: [
    MatCardModule,
    MatTooltipModule,
    MatTabsModule,
    NgTemplateOutlet,
    MessageBoxComponent,
    NameComponent,
    FormsModule,
    ReactiveFormsModule,
    MobileComponent,
    MatDividerModule,
    EmailComponent,
    SlideToggleComponent,
    MatButtonModule,
    AsyncPipe,
    FlexModule,
    PortalThemeComponent
  ]
})
export class ProfileComponent implements OnInit {
  givenName: string | undefined;
  familyName: string | undefined;
  store = createAsyncStore();

  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: false}) logoutFn!: LogoutFn;
  @Input({required: false}) matchConfirmPasswordFn!: MatchConfirmPasswordFn;
  @Input({required: false}) enableDisable2FaFn!: EnableDisable2faFn;
  @Input({required: false}) add2FaSecretKeyFn!: Add2faSecretKeyFn;
  @Input({required: false}) verify2faTokenFn!: Verify2faTokenFn;
  @Input({required: false}) get2faTotpVerificationCodeFn!: Get2faTotpVerificationCodeUriFn;
  @Input({required: false}) isLoadingProfile!: boolean;
  @Input({required: false}) isLoadingPassword!: boolean;
  @Input({required: false}) allowProfileEdit: boolean = false;
  @Input({required: true}) portalThemeObservable!: Observable<PortalThemeServiceEvent>;
  @Input({required: true}) changePortalThemeFn!: ChangePortalThemeFn;

  isInternalUser = isInternalUser;

  // @Input({required: false}) flag: boolean = true;
  // showError = false
  errorTitle = 'Error Occurred!'
  errorMessage = 'Please try again.'
  retry() {
    this.ngOnInit();
  }
  @Output() events: EventEmitter<ProfileComponentEvent> = new EventEmitter<ProfileComponentEvent>();

  subscriptions: Subscription[] = [];

  email!: string;
  password!: string
  formGroup1: FormGroup<{
    firstName: FormControl<NameComponentValue>;
    lastName: FormControl<NameComponentValue>;
    middleName: FormControl<NameComponentValue>;
    email: FormControl<string | null>;
    mobileNumber: FormControl<string | null>;

  }>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlMiddleName: FormControl<NameComponentValue>
  formControlMobileNumber: FormControl<string | null>;
  formControlEmail: FormControl<string | null>;
  formControl2FA: FormControl<SlideToggleValue>;

  // twoFAChecked: any
  user: User | null = null;

  constructor(private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private router: Router,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlMiddleName = formBuilder.control(null);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required]);
    this.formControl2FA = formBuilder.control(false);
    this.formGroup1 = formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      middleName: this.formControlMiddleName,
      email: this.formControlEmail,
      mobileNumber: this.formControlMobileNumber,
    });

    // this.formControlPassword = formBuilder.control(null, [Validators.required]);
    // this.formGroup2 = formBuilder.group({
    //   password: this.formControlPassword,
    // });
  }

  ngOnInit(): void {
    this.formControlEmail.disable();
    if (this.allowProfileEdit == false) {
      this.formGroup1.disable();
    }
    setupUntilDestroy(this);
    let user = getUser();
    this.getUserFn(user!.UserId,false).pipe(
      // this.toastService.snackBarObservable('Refreshed'),
      tap(r => {
        this.user = r;

        this.givenName = this.user?.GivenName?.charAt(0).toUpperCase();
        this.familyName = this.user?.FamilyName?.charAt(0).toUpperCase();
        if (this.user) {
          this.formControlFirstName.setValue(this.user.GivenName ?? null);
          this.formControlLastName.setValue(this.user.FamilyName ?? null);
          this.formControlMiddleName.setValue(this.user.MiddleName ?? null);
          this.formControlMobileNumber.setValue(this.user.MobileNumber ?? null);
          this.formControlEmail.setValue(this.user.Email);
          if(isInternalUser(this.user)){
            this.formControl2FA.disable();
          }
        }

        else {
          this.toastService.publishErrorNotification({
            type: 'error',
            errorTitle: this.errorTitle,
            errorMessage: this.errorMessage,
            retryFn: this.retry.bind(this),
          });
          this.store.value$ = of(createAsyncState({
            error: new Error(`Invalid user`)
          }));
        }
        this.formControl2FA.setValue(!!this.user?.is2FA, {emitEvent: false});
        this.setup2FaFormControl();
        // if (this.user?.is2FA) {
        //   this.flag = true;
        //   this.formControl2FA.setValue(this.user?.is2FA);
        // } else {
        //   this.flag = false;
        // }
      })
    ).subscribe();
  }

  setup2FaFormControl() {
    this.subscriptions.push(this.formControl2FA.valueChanges.pipe(
      tap(twoFAChecked => {
        const currentLoggedOnUser = getUser();
        const checkCurrentLoggedOnUser=canChangeOwn2faAccessLevel(currentLoggedOnUser)
        if(checkCurrentLoggedOnUser){
        if (twoFAChecked === true) {  // turn 2fa on
          this.applicationDialogService.openEnable2FAPasswordDialog({}).afterClosed().pipe(
            tap()
          ).subscribe(r => {
            if (!r) { // cancel confirm password dialog
                if (this.user) {
                    this.formControl2FA.setValue(this.user.is2FA, {emitEvent: false, onlySelf: true, emitViewToModelChange: false});
                }
            } else { // submit confirm password dialog
              this.email = r?.email!;
              this.password = r?.password!;
              this.add2FaSecretKeyFn(this.email, this.password).pipe(
                this.toastService.spinnerObservable(),
                tap(r => {
                  if (r.status === true) {
                    this.applicationDialogService.openEnable2FADialog({
                        enableDisable2faFn: this.enableDisable2FaFn,
                        verify2faGeneratedTokenCodeFn: this.verify2faTokenFn,
                        get2FaVerificationCodeFn: this.get2faTotpVerificationCodeFn,
                    }).afterClosed().pipe(
                      tap(r => {
                        if (!r) {
                            if (this.user) {
                                this.formControl2FA.setValue(this.user.is2FA, {emitEvent: false, onlySelf: true, emitViewToModelChange: false});
                            }
                        }
                        else { // submit enable 2fa dialog
                        }
                      })
                    ).subscribe();
                  } else {
                    // this.matDialogRef.close();
                    // this.flag = false;
                    this.applicationDialogService.openAlertDialog({
                      message: `Error`,
                      subMessage: `Please check your password.`
                    });
                  }
                })
              ).subscribe();
            }
          });
        } else {  // turn 2fa off
          const email = this.user?.Email;
          if (email) {
              this.enableDisable2FaFn(email, 0).pipe(
                  this.toastService.snackBarObservable(`2FA disabled`),
                  tap()
              ).subscribe();
          }
        }
        } else {
          if (twoFAChecked === true) {
            this.applicationDialogService.openAlertDialog({
              message: `Invalid Access`,
              subMessage: `You do not have this access.`,
            }).afterClosed().pipe(
              tap()
            ).subscribe(r => {
              if (r) { // cancel confirm password dialog
                if (this.user) {
                  this.formControl2FA.setValue(this.user.is2FA, { emitEvent: false, onlySelf: true, emitViewToModelChange: false });
                }
              }
            });
          }
        }
      })
    ).subscribe())
  }

  onCancel($event: Event) {
    this.events.next({type:'cancel'});
  }

  onTopMenuEvent($event: ToolbarComponentEvent['type']) {
    switch ($event) {
      case 'logout':
        this.logoutFn().pipe(
          this.toastService.spinnerObservable(),
          tap(async r => {
            this.events.emit({type: 'logout'});
          })
        ).subscribe();
        break;
    }
  }

  openConfirmPassword($event: Event) {
    let id = this.user?.UserId;
    if (id) {
      this.applicationDialogService.openConfirmPasswordDialog({
        userId: id,
        // updateUserPasswordFn: this.authService.updateUserPasswordFn,
        matchConfirmPasswordFn: this.matchConfirmPasswordFn,
      })
        .afterClosed()
        .pipe(
          tap(async (r) => {
            if (r && r.valid && r.newPassword) { // submitted
              this.onSavePassword(r.newPassword);
            }
          })
        ).subscribe();
    }
  }
  onSaveProfile($event: Event) {
    if (this.user) {
      this.events.next({
        type: 'save-profile',
        userId: this.user.UserId,
        FirstName: this.formControlFirstName.value!,
        MiddleName: this.formControlMiddleName.value!,
        LastName: this.formControlLastName.value!,
        Email: this.formControlEmail.value!,
        MobileNumber: this.formControlMobileNumber.value!,
      })
    }
  }

  onSavePassword(newPassword: string) {
    if (this.user) {
      this.events.next({
        type: 'save-password',
        userId: this.user.UserId,
        password: newPassword,
      })
    }
  }
}
