import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AccessLevel, compareMatch, isAdmin, isAnalyst, isInternalUser, isSalesAM, isSalesBDM, PortalLoginUser, User} from '@portal-workspace/grow-shared-library';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {UntilDestroy} from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import { formControlErrorKeys, formControlErrorMessage, getUser } from '../component-utils';



export const brokerUserOptions: {type: AccessLevel, name: string, show: boolean}[] = [
  {type: 'externalbroker', name: 'External Broker', show: true},
  {type: 'internalbroker', name: 'Internal Broker', show: true},
  {type: 'companyadmin', name: 'Company Admin', show: true},
  {type: 'analyst', name: 'Finance', show: true},
  {type: 'admin', name: 'Admin', show: true},
  // {type: 'customer', name: 'Customer', show: true},
  {type: 'companyoperator', name: 'Company Operator', show: true},
  {type: 'operations', name: 'Operations', show: true},
  {type: 'operations24', name: 'Operations24', show: true},
  {type: 'credit', name: 'Credit', show: true},
  {type: 'settlement', name: 'Settlement', show: true},
  {type: 'salesBDM', name: 'SalesBDM', show: true},
  {type: 'salesAM', name: 'SalesAM', show: true},
]

export type AccessLevelValue = AccessLevel | null;

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'access-level',
    templateUrl: './access-level.component.html',
    styleUrls: ['./access-level.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AccessLevelComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, DisableControlDirective, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class AccessLevelComponent extends AbstractControlValueAccessor<AccessLevelValue> implements OnInit {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  
  options = brokerUserOptions.map((option => ({ ...option })));

  subscriptions: Subscription[] = [];
  @Input({required: false}) user?: Pick<User, 'AccessLevel'> | null; // current logged in user
  formControl: FormControl<AccessLevelValue>;
  loggedInUser: PortalLoginUser | null = getUser();
  isSalesAM = isSalesAM(this.loggedInUser)
  isSalesBDM = isSalesBDM(this.loggedInUser)
  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    this.options = [...this.options].map((item)=> {
      if (this.user && !isInternalUser(this.user)) {
        if (['externalbroker', 'companyadmin', 'companyoperator'].includes(item.type)) {
        } else {
          item.show = false;
        }
      } 
      else if ( this.isSalesAM || this.isSalesBDM){
        if (['externalbroker', 'companyadmin', 'companyoperator'].includes(item.type)) {
        } else {
          item.show = false;
        }
      }
      else {
        if (this.user && !isAdmin(this.user)) {
          if (item.type === 'admin') {
            item.show = false;
          } else {
            item.show = true;
          }
        } else {
          item.show = true;
        }
      }
      return item;
    }).filter(option => option.show)
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  doWriteValue(v: AccessLevelValue | undefined): void | AccessLevelValue {
    if (v) {
      this.formControl.setValue(v);
    } else {
      this.formControl.setValue(null);
    }
    return undefined;
  }
}
