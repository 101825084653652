import {Component, Input, OnInit} from '@angular/core';
import { AccountMonthlyBalance, BankStatementData, BankStatementsAnalysisData, BankTransactionsWithSummary, DebitCreditSummary } from '@portal-workspace/grow-shared-library';
import { FullTransactionListComponent } from './full-transaction-list.component';
import { CategoriesTableComponent } from './categories-table.component';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { MonthlyDebitsCreditsTableComponent } from './monthly-debits-credits-table.component';
import { NonPreferredLenderSummaryComponent } from './non-preferred-lender-summary.component';
import { OverdrawnTableComponent } from './overdrawn-table.component';
import { TransactionTableComponent } from './transaction-table.component';
import { DishonoursTableComponent } from './dishonours-table.component';
import { MonthlyRevenueChartComponent } from './monthly-revenue-chart.component';
import { MonthlyRevenueTableComponent } from './monthly-revenue-table.component';


@Component({
    selector: 'customer-analysis',
    templateUrl: './customer-analysis.component.html',
    styleUrls: ['./customer-analysis.component.scss'],
    standalone: true,
    imports: [MonthlyRevenueTableComponent, MonthlyRevenueChartComponent, DishonoursTableComponent, TransactionTableComponent, OverdrawnTableComponent, NonPreferredLenderSummaryComponent, MonthlyDebitsCreditsTableComponent, MonthlyDebitsCreditsChartComponent, CategoriesTableComponent, FullTransactionListComponent]
})
export class CustomerAnalysisComponent implements OnInit {
  @Input({ required: true }) data!: BankStatementData;
  totalDebitCredit: AccountMonthlyBalance | null = null;

  constructor() {}

  ngOnInit(): void {
    this.updateDebitCredit();
  }

  isEmpty(obj: BankTransactionsWithSummary, table: 'overdrawn' | 'non-preferred-lender' | 'dishonours'): boolean {
    switch (table) {
      case 'non-preferred-lender':
        return !obj.transactions?.length && !obj.summary.numcredit && !obj.summary.numdebit;
      case 'overdrawn':
        return !obj.summary.numdebit;
      case 'dishonours':
        return !obj.summary.numdebit;
      default:
        return !obj.transactions?.length && !obj.summary.numcredit && !obj.summary.numdebit;
    }
  }

  updateDebitCredit() {
    const debitCredit: AccountMonthlyBalance = {
      averagecredit: 0,
      averagedebit: 0,
      enddate: '',
      enddatecredit: '',
      enddatedebit: '',
      monthlycredit: {},
      monthlydebit: {},
      numcredit: 0,
      numdebit: 0,
      startdate: '',
      startdatecredit: '',
      startdatedebit: '',
      totalcredit: 0,
      totaldebit: 0,
    }

    this.data.accounts.forEach(account => {
      const balance = account.monthlyBalance;
      console.log('balance for selected accounts', balance);
      if (!balance) return;

      debitCredit.totalcredit += balance.totalcredit;
      debitCredit.totaldebit += balance.totaldebit;
      debitCredit.numcredit += balance.numcredit;
      debitCredit.numdebit += balance.numdebit;

      if (!debitCredit.startdate || balance.startdate < debitCredit.startdate) {
        debitCredit.startdate = balance.startdate;
      }
      if (!debitCredit.enddate || balance.enddate > debitCredit.enddate) {
        debitCredit.enddate = balance.enddate;
      }
      if (!debitCredit.startdatecredit || balance.startdatecredit < debitCredit.startdatecredit) {
        debitCredit.startdatecredit = balance.startdatecredit;
      }
      if (!debitCredit.enddatecredit || balance.enddatecredit > debitCredit.enddatecredit) {
        debitCredit.enddatecredit = balance.enddatecredit;
      }
      if (!debitCredit.startdatedebit || balance.startdatedebit < debitCredit.startdatedebit) {
        debitCredit.startdatedebit = balance.startdatedebit;
      }
      if (!debitCredit.enddatedebit || balance.enddatedebit > debitCredit.enddatedebit) {
        debitCredit.enddatedebit = balance.enddatedebit;
      }

      // Combine monthly credit and debit
      Object.entries(balance.monthlycredit).forEach(([month, amount]) => {
        debitCredit.monthlycredit[month] = (debitCredit.monthlycredit[month] || 0) + amount;
      });

      Object.entries(balance.monthlydebit).forEach(([month, amount]) => {
        debitCredit.monthlydebit[month] = (debitCredit.monthlydebit[month] || 0) + amount;
      });
    });

    debitCredit.averagecredit = debitCredit.totalcredit / debitCredit.numcredit || 0;
    debitCredit.averagedebit = debitCredit.totaldebit / debitCredit.numdebit || 0;

    console.log('debitCredit', debitCredit);

    this.totalDebitCredit = debitCredit;
  }
}
