import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AccountMonthlyBalance, DebitCreditSummary } from '@portal-workspace/grow-shared-library';
import { Chart, ChartItem, TooltipItem } from 'chart.js';

@Component({
  selector: 'basiq-monthly-debits-credits-chart',
  templateUrl: './basiq-monthly-debits-credits-chart.component.html',
  styleUrls: ['./basiq-monthly-debits-credits-chart.component.scss'],
  standalone: true
})
export class BasiqMonthlyDebitsCreditsChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input({ required: true }) data!: AccountMonthlyBalance;
  @Input({ required: false }) lightTheme = false;
  @Input({ required: false }) index: number = 0;

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  chart!: Chart;
  dynamoneyBlue = '#5D40FF';
  growOrange = '#FF5722';
  growOrangeLight = '#FF7043';
  dynamoneyBlueLight = '#B9A6F0';
  labels: string[] = [];
  debits: number[] = [];
  credits: number[] = [];

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<'bar'>) =>
            `${item.dataset.label}: ${(item.raw as number)
              .toLocaleString("en-AU", { style: "currency", currency: "AUD" })}`,
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        },
        grid: {
          display: true,
          borderDash: [5, 5]
        }
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value: number) {
            return value.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
          }
        }
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.initLabels();
    this.initData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initLabels();
    this.initData();
    setTimeout(() => {
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  initData() {
    if (this.data) {
      const sortedMonths = this.getSortedMonths();
      this.credits = sortedMonths.map(month => this.data.monthlycredit[month] || 0);
      this.debits = sortedMonths.map(month => Math.abs(this.data.monthlydebit[month] || 0));
    }
  }

  initLabels() {
    if (this.data) {
      const sortedMonths = this.getSortedMonths();
      this.labels = sortedMonths;
    }
  }

  private getSortedMonths(): string[] {
    const months = Object.keys(this.data.monthlycredit);
    return months.sort((a, b) => {
      const [monthA, yearA] = a.split(' ');
      const [monthB, yearB] = b.split(' ');
      const dateA = new Date(`${monthA} 1, ${yearA}`);
      const dateB = new Date(`${monthB} 1, ${yearB}`);
      return dateA.getTime() - dateB.getTime();
    });
  }

  display() {
    const chartElement = document.getElementById('basiq-monthly-debits-credits-chart' + this.index) as ChartItem;
    if (chartElement) {
      this.chart = new Chart(chartElement, {
        type: 'bar',
        options: this.chartOptions,
        data: {
          labels: this.labels,
          datasets: [
            {
              label: 'Total Debits',
              borderColor: this.lightTheme ? this.growOrangeLight : this.growOrange,
              backgroundColor: this.lightTheme ? this.growOrangeLight : this.growOrange,
              data: this.debits
            },
            {
              label: 'Total Credits',
              borderColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              backgroundColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              data: this.credits
            }
          ]
        }
      });
    }

  }
}
