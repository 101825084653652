<div class="bank-statement-decisioning">
  <mat-tab-group
    #bsTabs
    [mat-stretch-tabs]="false"
    mat-align-tabs="start"
    (selectedIndexChange)="setCurrentSelection($event)"
  >
    @for (currentTab of tabs; track currentTab) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{ currentTab.name }}
      </ng-template>
    </mat-tab>
    }
  </mat-tab-group>

  @if (currentSection === 'overview') {
  <bank-statement-overview
    [data]="data"
    [selectedAccounts]="selectedAccounts"
    (accountSelector)="onSelectAccounts($event)"
  ></bank-statement-overview>
  } @if (currentSection === 'loan payment') {
  <bank-statement-loan-payment
    [data]="data.transactionsByCategory"
    [selectedAccounts]="selectedAccounts"
    [accounts]="data.accounts"
    [getBsaLenderListFn]="getBsaLenderListFn"
    [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
    [confirmation]="confirmLoanPayment"
    [selectedTransactions]="loanPaymentTransactions"
    [saveBsaCalculatorFn]="saveBsaCalculatorFn"
    [getBsaCalculatorFn]="getBsaCalculatorFn"
    (loanPaymentTransactions)="updataLoanPaymentTransactions($event)"
  ></bank-statement-loan-payment>
  } @if (currentSection === 'income') {
  <bank-statement-income
    [data]="data.transactionsByCategory"
    [selectedAccounts]="selectedAccounts"
    [accounts]="data.accounts"
    [confirmation]="confirmIncome"
    [selectedTransactions]="incomeTransactions"
    [saveBsaCalculatorFn]="saveBsaCalculatorFn"
    [getBsaCalculatorFn]="getBsaCalculatorFn"
    (incomeTransactions)="updateIncomeTransactions($event)"
  ></bank-statement-income>
  } @if (currentSection === 'ato payment') {
  <bank-statement-ato-payment
    [data]="data.transactionsByCategory"
    [selectedAccounts]="selectedAccounts"
    [accounts]="data.accounts"
    [confirmation]="confirmAtoPayment"
    [selectedTransactions]="atoPaymentTransactions"
    [saveBsaCalculatorFn]="saveBsaCalculatorFn"
    [getBsaCalculatorFn]="getBsaCalculatorFn"
    (atoPaymentTransactions)="updateAtoPaymentTransactions($event)"
  ></bank-statement-ato-payment>
  } @if (currentSection === 'dscr calculator') {
  <bank-statement-dscr-calculator
    [data]="data.transactionsByCategory"
    [accountSummary]="data.accounts"
    [selectedAccounts]="selectedAccounts"
    [application]="application"
    [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
    [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
    [loanPaymentTransactions]="loanPaymentTransactions"
    [incomeTransactions]="incomeTransactions"
    [atoPaymentTransactions]="atoPaymentTransactions"
    [saveBsaCalculatorFn]="saveBsaCalculatorFn"
    [getBsaCalculatorFn]="getBsaCalculatorFn"
    [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
    [createApplicationNoteFn]="createApplicationNoteFn"
    [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
    [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
  ></bank-statement-dscr-calculator>
  }
</div>
