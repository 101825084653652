<div class="bank-statement-overview">

  <!-- <div class="row mb-4">
    <basiq-affordability-report [report]="affordabilityReport"></basiq-affordability-report>
  </div> -->

  <div class="row mb-4">
    <div class="col-12">
      <div fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button color="primary"
          (click)="onConfirmAccountSelection()"
          class="w-lt-md-100">
          Confirm Account Selection
        </button>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
            'column-width-3': column === 'BankName' || column === 'BSBAccNumber',
            'column-width-2': column === 'AccHolder' || column === 'AccName' || column === 'balance' || column === 'creditLimit',
            'column-width-1': column === 'product',
            'column-width-icon': column === 'icon' || column === 'selector',
            'text-align-right': needAlignRight(column)
          }">     
              <div class="cell">{{getColumnTitles(column)}}</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}">
              @if (column === 'icon') {
                <div class="cell" (click)="onClickRow(element)">
                  @if (expandedElement === element) {
                    <span class="mdi mdi-chevron-up"></span>
                  }
                  @if (expandedElement !== element) {
                    <span class="mdi mdi-chevron-down"></span>
                  }
                </div>
              }
              @if (column === 'selector') {
                <div class="cell">
                  <mat-checkbox color="primary" [formControl]="accountFilter[element.id]"></mat-checkbox>
                </div>
              }
              @if (column !== 'icon' && column !== 'selector') {
                <div class="cell" (click)="onClickRow(element)">
                    @if (needCurrencyPipe(column)) {
                        {{displayColumnValue(element, column) | looseCurrency}}
                    }
                    @if (!needCurrencyPipe(column)) {
                      {{displayColumnValue(element, column)}}
                    }
                </div>
              }
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expanded-account-details">
                @if(element.monthlyBalance) {
                <div class="row mt-3 decision-table">
                  <div class="col">
                    <h3 class="section-title">Decision Metrics</h3>
                    <table class="details-table width-100">
                      <thead>
                        <tr>
                          <th></th>
                          <th>All</th>
                          <th>Last 180 Days</th>
                          <th>Last 90 Days</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td class="label-cell">$ Credit</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.totalCredit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.totalCredit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.totalCredit | looseCurrency}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell">Monthly Avg. $ Credits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.monthlyAverageCredit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.monthlyAverageCredit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.monthlyAverageCredit | looseCurrency}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell"># Credits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.numberOfCredits}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.numberOfCredits}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.numberOfCredits}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell">Avg. $ Credits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.averageCreditAmount | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.averageCreditAmount | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.averageCreditAmount | looseCurrency}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell">Med. $ Credits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.medianCreditAmount | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.medianCreditAmount | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.medianCreditAmount | looseCurrency}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell">$ Debits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.totalDebit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.totalDebit | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.totalDebit | looseCurrency}}</td>
                          </tr>
                          <tr>
                            <td class="label-cell"># Debits</td>
                            <td class="value-cell">{{element.monthlyBalance.allTimeMetrics.numberOfDebits | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last180DaysMetrics.numberOfDebits | looseCurrency}}</td>
                            <td class="value-cell">{{element.monthlyBalance.last90DaysMetrics.numberOfDebits | looseCurrency}}</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              }

                <div class="row mt-3">
                  <div class="col">
                    <h3 class="section-title">Additional Account Information</h3>
                    <table class="details-table width-100">
                      <tbody>
                        @if (element.depositRate) {
                          <tr>
                            <td class="label-cell">Deposit Rate</td>
                            <td class="value-cell">{{element.depositRate}}%</td>
                          </tr>
                        }
                        @if (element.lendingRate) {
                          <tr>
                            <td class="label-cell">Lending Rate</td>
                            <td class="value-cell">{{element.lendingRate}}%</td>
                          </tr>
                        }
                        @if (element.accountOwnership && element.accountOwnership !== 'UNKNOWN') {
                          <tr>
                            <td class="label-cell">Account Ownership</td>
                            <td class="value-cell">{{element.accountOwnership}}</td>
                          </tr>
                        }
                        @if (element.amortisedLimit) {
                          <tr>
                            <td class="label-cell">Amortised Limit</td>
                            <td class="value-cell">{{element.amortisedLimit | looseCurrency}}</td>
                          </tr>
                        }
                        @if (element.bundleName) {
                          <tr>
                            <td class="label-cell">Bundle Name</td>
                            <td class="value-cell">{{element.bundleName}}</td>
                          </tr>
                        }
                        @if (element.availableFunds) {
                          <tr>
                            <td class="label-cell">Available Funds</td>
                            <td class="value-cell">{{element.availableFunds | looseCurrency}}</td>
                          </tr>
                        }
                        @if (element.lastUpdated) {
                          <tr>
                            <td class="label-cell">Last Updated</td>
                            <td class="value-cell">{{element.lastUpdated | date:'medium'}}</td>
                          </tr>
                        }
                        @if (element.status) {
                          <tr>
                            <td class="label-cell">Status</td>
                            <td class="value-cell">{{element.status}}</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              
                @if (element.transactionIntervals && element.transactionIntervals.length > 0) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Transaction Intervals</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @for (interval of element.transactionIntervals; track interval) {
                            <tr>
                              <td class="label-cell">From</td>
                              <td class="value-cell">{{interval.from | date}}</td>
                              <td class="label-cell">To</td>
                              <td class="value-cell">{{interval.to | date}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.fees && element.meta.fees.length > 0) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Fees</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @for (fee of element.meta.fees; track fee) {
                            <tr>
                              <td class="label-cell">{{fee.name}}</td>
                              <td class="value-cell">{{fee.amount | looseCurrency}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.depositRates && element.meta.depositRates.length > 0) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Deposit Rates</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @for (rate of element.meta.depositRates; track rate) {
                            <tr>
                              <td class="label-cell">{{rate.depositRateType}}</td>
                              <td class="value-cell">{{rate.rate}}%</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.lendingRates && element.meta.lendingRates.length > 0) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Lending Rates</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @for (rate of element.meta.lendingRates; track rate) {
                            <tr>
                              <td class="label-cell">{{rate.lendingRateType}}</td>
                              <td class="value-cell">{{rate.rate}}%</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.termDeposit) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Term Deposit</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @if (element.meta.termDeposit.maturityAmount) {
                            <tr>
                              <td class="label-cell">Maturity Amount</td>
                              <td class="value-cell">{{element.meta.termDeposit.maturityAmount | looseCurrency}}</td>
                            </tr>
                          }
                          @if (element.meta.termDeposit.maturityDate) {
                            <tr>
                              <td class="label-cell">Maturity Date</td>
                              <td class="value-cell">{{element.meta.termDeposit.maturityDate | date}}</td>
                            </tr>
                          }
                          @if (element.meta.termDeposit.lodgementDate) {
                            <tr>
                              <td class="label-cell">Lodgement Date</td>
                              <td class="value-cell">{{element.meta.termDeposit.lodgementDate | date}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.loan) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Loan Details</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @if (element.meta.loan.loanAmount) {
                            <tr>
                              <td class="label-cell">Loan Amount</td>
                              <td class="value-cell">{{element.meta.loan.loanAmount | looseCurrency}}</td>
                            </tr>
                          }
                          @if (element.meta.loan.startDate) {
                            <tr>
                              <td class="label-cell">Start Date</td>
                              <td class="value-cell">{{element.meta.loan.startDate}}</td>
                            </tr>
                          }
                          @if (element.meta.loan.endDate) {
                            <tr>
                              <td class="label-cell">End Date</td>
                              <td class="value-cell">{{element.meta.loan.endDate}}</td>
                            </tr>
                          }
                          @if (element.meta.loan.repaymentType) {
                            <tr>
                              <td class="label-cell">Repayment Type</td>
                              <td class="value-cell">{{element.meta.loan.repaymentType}}</td>
                            </tr>
                          }
                          @if (element.meta.loan.repaymentFrequency) {
                            <tr>
                              <td class="label-cell">Repayment Frequency</td>
                              <td class="value-cell">{{element.meta.loan.repaymentFrequency}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              
                @if (element.meta && element.meta.creditCard) {
                  <div class="row mt-3">
                    <div class="col">
                      <h3 class="section-title">Credit Card Details</h3>
                      <table class="details-table width-100">
                        <tbody>
                          @if (element.meta.creditCard.minPaymentAmount) {
                            <tr>
                              <td class="label-cell">Minimum Payment Amount</td>
                              <td class="value-cell">{{element.meta.creditCard.minPaymentAmount | looseCurrency}}</td>
                            </tr>
                          }
                          @if (element.meta.creditCard.paymentDueAmount) {
                            <tr>
                              <td class="label-cell">Payment Due Amount</td>
                              <td class="value-cell">{{element.meta.creditCard.paymentDueAmount | looseCurrency}}</td>
                            </tr>
                          }
                          @if (element.meta.creditCard.paymentDueDate) {
                            <tr>
                              <td class="label-cell">Payment Due Date</td>
                              <td class="value-cell">{{element.meta.creditCard.paymentDueDate | date}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </div>
              @if(element.dayBalances){
                <div class="row mt-3">
                  <div class="col">
                    <div class="mat-h4-custom">Day End Balance Chart</div>
                    <day-end-balance-chart [data]="element.dayBalances.reverse()" [index]="i"></day-end-balance-chart>
                  </div>
                </div>
              }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (dblclick)="viewAccountTransactions(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
  
  <div class="mt-4">
    <div class="mat-h4-custom">Total Monthly Debits / Credits</div>
    @if (totalDebitCredit) {
      <monthly-debits-credits-table [data]="totalDebitCredit"></monthly-debits-credits-table>
      <div class="mt-4">
        <basiq-monthly-debits-credits-chart [data]="totalDebitCredit"></basiq-monthly-debits-credits-chart>
      </div>
    }
  </div>

  <div class="mt-4">
    <div class="mat-h4-custom">Categories</div>
    @if (transactions && accounts) {
      <basiq-categories-table 
      [transactions]="transactions!"
          [accounts]="accounts!"
          [selectedAccounts]="transactionListSelectedAccounts">
      </basiq-categories-table>
    }
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="mat-h4-custom">Full Transaction List</div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12" id="transaction-list">
      @if (transactions && accounts) {
          <basiq-full-transaction-list
          [transactions]="transactions!"
          [accounts]="accounts!"
          [selectedAccounts]="transactionListSelectedAccounts"
        ></basiq-full-transaction-list>
      }
    </div>
  </div>
</div>
