import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AccountFilter,
  AccountMonthlyBalance,
  AccountSummary,
  BankStatementAnalysisCategoryByBankId,
  BankStatementsAnalysisCategory,
  BankStatementsAnalysisTransactionDetails,
  BasiqAccount,
  BasiqTransaction,
  BasiqTransactionCategory,
  DayEndBalanceSummary,
  DebitCreditSummary,
} from '@portal-workspace/grow-shared-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationDialogService, PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MonthlyDebitsCreditsChartComponent } from '../bank-statement-component/monthly-debits-credits-chart.component';
import { CategoryDetailsTableComponent } from '../bank-statement-component/category-details-table.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { DayEndBalanceChartComponent } from '../bank-statement-component/day-end-balance-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { loadingFor } from '@ngneat/loadoff';
import { BasiqCategoryDetailsTableComponent } from './basiq-category-details-table.component';
import moment from 'moment';
import { BasiqMonthlyDebitsCreditsChartComponent } from './basiq-monthly-debits-credits-chart.component';


class InternalDataSource extends DataSource<BasiqTransactionCategory> {

  subject = new BehaviorSubject<BasiqTransactionCategory[]>([]);

  connect(): Observable<BasiqTransactionCategory[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: BasiqTransactionCategory[]) {
    this.subject.next(data);
  }
}

interface CategoryTransactionCheckbox {
  [key: number]: { [id: string]: boolean }; // key - category index
}

@Component({
  selector: 'basiq-categories-table',
  templateUrl: './basiq-categories-table.component.html',
  styleUrls: ['./basiq-categories-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, MatCheckboxModule, AsyncPipe, FormsModule, MatButtonModule, ReactiveFormsModule, DayEndBalanceChartComponent, MatTableModule, NgClass, ExtendedModule, CategoryDetailsTableComponent, MonthlyDebitsCreditsChartComponent, LooseCurrencyPipe, BasiqCategoryDetailsTableComponent, BasiqMonthlyDebitsCreditsChartComponent]
})
export class BasiqCategoriesTableComponent implements OnInit, OnChanges {
  @Input({ required: false }) accounts: BasiqAccount[] = [];
  @Input({ required: false }) transactions: BasiqTransaction[] = [];
  @Input({ required: false }) selectedAccounts: string[] = [];
  dataSource = new InternalDataSource();
  columnsToDisplay: string[] = ['icon', 'category', 'amountcredit', 'numcredit', 'amountdebit', 'numdebit'];
  expandedElement!: BasiqTransactionCategory | null;
  accountFilter: { [key: string]: FormControl<boolean | null> } = {};
  dayEndBalanceChartData: DayEndBalanceSummary[] = [];
  categoryTransactionCheckbox: CategoryTransactionCheckbox = {};
  isData!: boolean;
  selectedDebitCredit!: AccountMonthlyBalance;
  tableData: any[] = [];
  accountSelectionChanges: boolean = true;
  firstTimeLoadData: boolean = true;
  loader = loadingFor('tableLoading');
  singleAccount = false;

  constructor(
    private applicationDialogService: ApplicationDialogService,
    private cdr: ChangeDetectorRef
  ) { }

  private updateSingleAccountData() {
    this.dataSource.update(this.accounts[0].transactionCategories! as any);
    this.tableData = this.accounts[0].transactionCategories!;
    this.initCategoryTransactionCheckbox();
    this.updateSelectedDebitCredit();
  }

  ngOnInit(): void {

    this.singleAccount = this.accounts.length === 1;
    this.dataSource.update([]);
    this.initAccountFilter();
    if (this.singleAccount) {
      this.updateSingleAccountData();
      this.getDayEndBalance(this.accounts.map(account => account.id));
    }

    if (this.selectedAccounts.length) {
      this.reloadChartAndTable();
    }
    this.firstTimeLoadData = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['accounts'] || changes['transactions'] || changes['selectedAccounts']) {
      this.initAccountFilter();

      console.log('selected account changes', this.selectedAccounts);

      if (this.singleAccount) {
        this.updateSingleAccountData();
      }

      if (changes['selectedAccounts']) {
        this.reloadChartAndTable();
      }

      this.cdr.markForCheck();
    }
  }

  initAccountFilter() {
    this.accounts.forEach((account: BasiqAccount) => {
      this.accountFilter[account.id] = new FormControl<boolean>(this.singleAccount);
    })

    if (this.selectedAccounts.length) {
      this.accounts.forEach((account: BasiqAccount) => {
        this.accountFilter[account.id].setValue(this.selectedAccounts.includes(account.id));
      })
    }
  }

  initCategoryTransactionCheckbox() {
    for (let i = 0; i < this.dataSource.subject.value.length; i++) {
      this.categoryTransactionCheckbox[i] = {};
      this.categoryTransactionCheckbox[i][this.tableData[i].id] = true;
    }
  }

  getDayEndBalance(accountId: string[]) {
    this.dayEndBalanceChartData = []
    if (this.accounts.length) {
      if (!this.accounts[0]?.dayBalances?.length) return;
      for (let i = 0; i < this.accounts[0].dayBalances?.length; i++) {
        let sum = 0;
        for (const accSummary of this.accounts) {
          if (accSummary.dayBalances)
          if (accountId.includes(accSummary.id)) {
            sum += Number(accSummary.dayBalances[i]?.balance ?? 0);
          }
        }
        this.dayEndBalanceChartData.push({
          date: this.accounts[0].dayBalances[i].date,
          balance: String(sum)
        })
      }
      this.dayEndBalanceChartData;
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'category': return 'Category';
      case 'numcredit': return '# Credits';
      case 'amountcredit': return '$ Credits';
      case 'numdebit': return '# Debits';
      case 'amountdebit': return '$ Debits';
      case 'icon': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['amountdebit', 'amountcredit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['numcredit', 'amountcredit', 'numdebit', 'amountdebit'].includes(column);
  }

  filterChangeHandler() {
    this.accountSelectionChanges = true;
    this.firstTimeLoadData = false;
  }

  reloadChartAndTable() {
    // const ref = this.applicationDialogService.openProgressSpinnerDialog();
    // setTimeout(() => {
    //   ref.close();
    // }, 3000);
    const accountIds = Object.keys(this.accountFilter);
    const filteredAccountIds: string[] = [];
    for (const accountId of accountIds) {
      const checked = this.accountFilter[accountId].value;
      if (checked) {
        filteredAccountIds.push(accountId);
      }
    }

    const filteredAccounts = this.accounts.filter(account => filteredAccountIds.includes(account.id));

    const categories = filteredAccounts.flatMap(account => account.transactionCategories!);

    this.dataSource.update(categories as any);
    this.tableData = categories;
    if (this.dataSource.subject.value.length == 0) {
      this.isData = true;
    } else {
      this.isData = false;
    }

    this.getDayEndBalance(filteredAccountIds);
    this.initCategoryTransactionCheckbox();
    this.accountSelectionChanges = false;
    this.updateSelectedDebitCredit();
  }

  showFilter() {
    return this.accounts && this.accounts.length && this.accounts.length > 1;
  }

  handleCheckboxChange(checkbox: { [id: number]: boolean }, index: number) {
    this.categoryTransactionCheckbox[index] = checkbox;
    this.updateSelectedDebitCredit();
  }

  updateSelectedDebitCredit() {
    const debitCredit: AccountMonthlyBalance = {
      averagecredit: 0,
      averagedebit: 0,
      enddate: '',
      enddatecredit: '',
      enddatedebit: '',
      monthlycredit: {},
      monthlydebit: {},
      numcredit: 0,
      numdebit: 0,
      startdate: '',
      startdatecredit: '',
      startdatedebit: '',
      totalcredit: 0,
      totaldebit: 0,
    }

    const _selectedAcc = this.accounts.filter(account => this.selectedAccounts.includes(account.id));

    console.log('selected account', _selectedAcc);

    _selectedAcc.forEach(account => {
      const balance = account.monthlyBalance ? { ...account.monthlyBalance } : null;
      console.log('balance for selected accounts', balance);
      if (!balance) return;

      debitCredit.totalcredit += balance.totalcredit;
      debitCredit.totaldebit += balance.totaldebit;
      debitCredit.numcredit += balance.numcredit;
      debitCredit.numdebit += balance.numdebit;

      if (!debitCredit.startdate || balance.startdate < debitCredit.startdate) {
        debitCredit.startdate = balance.startdate;
      }
      if (!debitCredit.enddate || balance.enddate > debitCredit.enddate) {
        debitCredit.enddate = balance.enddate;
      }
      if (!debitCredit.startdatecredit || balance.startdatecredit < debitCredit.startdatecredit) {
        debitCredit.startdatecredit = balance.startdatecredit;
      }
      if (!debitCredit.enddatecredit || balance.enddatecredit > debitCredit.enddatecredit) {
        debitCredit.enddatecredit = balance.enddatecredit;
      }
      if (!debitCredit.startdatedebit || balance.startdatedebit < debitCredit.startdatedebit) {
        debitCredit.startdatedebit = balance.startdatedebit;
      }
      if (!debitCredit.enddatedebit || balance.enddatedebit > debitCredit.enddatedebit) {
        debitCredit.enddatedebit = balance.enddatedebit;
      }

      // Combine monthly credit and debit
      Object.entries(balance.monthlycredit).forEach(([month, amount]) => {
        debitCredit.monthlycredit[month] = (debitCredit.monthlycredit[month] || 0) + amount;
      });

      Object.entries(balance.monthlydebit).forEach(([month, amount]) => {
        debitCredit.monthlydebit[month] = (debitCredit.monthlydebit[month] || 0) + amount;
      });
    });

    debitCredit.averagecredit = debitCredit.averagecredit ?? 0
    debitCredit.averagedebit = debitCredit.averagedebit ?? 0;

    console.log('debitCredit', debitCredit);

    this.selectedDebitCredit = debitCredit;
  }
}
