import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GetBasiqAuthLinkFn,
} from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApplicationDialogService, PortalHotToastService, TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { finalize, tap } from 'rxjs/operators';
import { concat, Subscription } from 'rxjs';
import { MinimalLayoutService } from '../../layout/minimal-layout/minimal-layout.service';
import { DigitalIdComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { BasiqService } from '../../service/basiq.service';



@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './basiq-user-auth.page.html',
  styleUrls: ['./basiq-user-auth.page.scss'],
  standalone: true,
  imports: [MessageBoxComponent, DigitalIdComponent, MatButtonModule, TagBoxComponent]
})
export class BasiqUserAuthPage implements OnInit {

  getBasiqAuthLinkFn!: GetBasiqAuthLinkFn;

  subscriptions: Subscription[] = [];

  alerts: string[] = [];
  messages: string[] = [];
  basiqId: string | null = null;
  authLink: string | null = null;

  constructor(private basiqService: BasiqService,
    private minimalLayoutService: MinimalLayoutService,
    private dialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService,
    private route: ActivatedRoute) {
    this.getBasiqAuthLinkFn = this.basiqService.getBasiqAuthLinkFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: false
    });
    const basiqId = this.route.snapshot.params.basiqId;
    if (!basiqId) {
      this.alerts.push('Invalid id link');
      return;
    }
    this.basiqId = basiqId;
    this.refresh();
  }

  refresh() {
    this.authLink = null;
    if (!this.basiqId) {
      return;
    }
    const sub = this.basiqService.getBasiqAuthLinkFn(this.basiqId)
      .pipe(
        tap(r => {
          if (!r.status) {
            const messages = r.message;
            this.alerts.push(...messages);
          } else {
            this.authLink = r.payload;
          }
        })
      ).subscribe();
    this.subscriptions.push(sub);
  }

  linkBankAccount() {
    console.log('auth link', this.authLink);
    window.location.href = this.authLink!;
  }
}
