import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  AccessLevel,
  AggregatorAndOriginatorListValue,
  AggregatorAndOriginatorListValueGetFn, allowEmailAccessLevel, ApproveOrRejectUserFn, DisableUsersFn,
  EnableDisable2faFn, EnableUsersFn, Add2faSecretKeyFn, Get2faTotpVerificationCodeUriFn, GetUserFn,
  isInternalUser,
  canChangeOthers2faAccessLevel,
  NameComponentValue, SlideToggleValue, UpdateUserFn,
  UpdateUserPasswordFn,
  User, UserandPriviledges, Verify2faTokenFn, canUser2faAccessBeChangedByInternalUser, Priviledges,
  PortalLoginUser,
  EditableEmailValue
} from '@portal-workspace/grow-shared-library';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { AsyncPipe, JsonPipe, Location } from '@angular/common';
import {debounceTime, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {loadingFor} from '@ngneat/loadoff';
import {MatInputModule} from '@angular/material/input';
import {InputMaskModule} from '@ngneat/input-mask';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { NameComponent } from '../name-component/name.component';
import { AggregatorAndOriginatorListComponent } from '../aggregator-search-component/aggregator-and-originator-list.component';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { AccessLevelComponent, AccessLevelValue } from '../access-level-component/access-level.component';
import { SlideToggleComponent } from '../slide-toggle-component/slide-toggle.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import {createEmailInputMask, createMobilePhoneNumberInputMask, getUser, setupUntilDestroy } from '../component-utils';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from 'apps/portal2/src/app/service/auth.service';
import { validEmailValidator,formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { distinctUntilChanged } from 'rxjs/operators';
import { EditableComponentModule } from '../editable-component/editable-component.module';

const INTERNAL_LEVELS: Priviledges = [ 'admin' , 'internalbroker','salesBDM', 'salesAM', 'analyst', 'operations' , 'operations24' , 'credit', 'settlement'];

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'broker-user-details',
  templateUrl: './broker-user-details.component.html',
  styleUrls: ['./broker-user-details.component.scss'],
  standalone: true,
  imports: [
    NameComponent,
    ReactiveFormsModule,
    AggregatorAndOriginatorListComponent,
    DisableControlDirective,
    MatInputModule,
    InputMaskModule,
    MatDividerModule,
    AccessLevelComponent,
    MatCardModule,
    SlideToggleComponent,
    AsyncPipe,
    MessageBoxComponent,
    MatButtonModule,
    JsonPipe,
    MatCheckboxModule,
    EditableComponentModule
]
})
export class BrokerUserDetailsComponent implements OnInit, AfterViewInit {

  loader = loadingFor('saving');

  subscriptions: Subscription[] = [];

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: true}) user!: UserandPriviledges;
  @Input({required: true}) aggregatorAndOriginatorListValueGetFn!: AggregatorAndOriginatorListValueGetFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) approveOrRejectFn!: ApproveOrRejectUserFn;
  @Input({required: true}) updateUserFn!: UpdateUserFn;
  @Input({required: true}) add2faSecretKeyFn!: Add2faSecretKeyFn;
  @Input({required: true}) enableDisable2faFn!: EnableDisable2faFn;
  @Input({required: true}) verify2faTokenFn!: Verify2faTokenFn;
  @Input({required: true}) get2faTotpVerificationCodeUriFn!: Get2faTotpVerificationCodeUriFn;
  @Input({required: true}) updateUserPasswordFn!: UpdateUserPasswordFn;
  @Input({required: true}) disableUserFn!: DisableUsersFn;
  @Input({required: true}) enableUserFn!: EnableUsersFn;

  createMobilePhoneNumberInputMask = createMobilePhoneNumberInputMask();
  createEmailInputMask = createEmailInputMask();
  isInternalUser = isInternalUser;

  loggedInUser : PortalLoginUser | null= getUser();
  canAccess = this.loggedInUser?.priviledges?.includes('admin') || 
   this.loggedInUser?.priviledges?.includes('operations') || 
   this.loggedInUser?.priviledges?.includes('salesAM') ||
   this.loggedInUser?.priviledges?.includes('salesBDM');
  canUser2faBeChanged = true;
  isLoggedinUser:boolean =false;

  formGroup1: FormGroup<{
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    aggregatorOrOriginator: FormControl<AggregatorAndOriginatorListValue>,
    // email: FormControl<string | null>,
    accessLevel: FormControl<AccessLevelValue>,
    mobileNumber: FormControl<string | null>
  }>;
  formControlFirstName: FormControl<NameComponentValue>;
  formControlLastName: FormControl<NameComponentValue>;
  formControlAggregatorOrOriginator: FormControl<AggregatorAndOriginatorListValue>;
  formControlMobileNumber: FormControl<string | null>;
  formControlEmail: FormControl<EditableEmailValue | null>;
  formControlAccessLevel: FormControl<AccessLevelValue>;
  formControlApproved: FormControl<SlideToggleValue>;
  formControlLocked: FormControl<SlideToggleValue>;
  formControl2FA: FormControl<SlideToggleValue>;
  formControlEnabled: FormControl<SlideToggleValue>;

  constructor(
              private toastService: PortalHotToastService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private applicationDialogService: ApplicationDialogService,
              private _location: Location) {
    this.formControlFirstName = formBuilder.control(null, [Validators.required]);
    this.formControlLastName = formBuilder.control(null, [Validators.required]);
    this.formControlAggregatorOrOriginator = formBuilder.control(null, [Validators.required]);
    this.formControlMobileNumber = formBuilder.control(null, [Validators.required]);
    this.formControlEmail = formBuilder.control(null, [Validators.required],validEmailValidator(this.authService.validEmailCheckFn));
    this.formControlAccessLevel = formBuilder.control(null, [Validators.required]);
    this.formControlApproved = formBuilder.control(false);
    this.formControlLocked = formBuilder.control(false);
    this.formControl2FA = formBuilder.control(false);
    this.formControlEnabled = formBuilder.control(false);
    this.formGroup1 = this.formBuilder.group({
      firstName: this.formControlFirstName,
      lastName: this.formControlLastName,
      aggregatorOrOriginator: this.formControlAggregatorOrOriginator,
      // email: this.formControlEmail,
      accessLevel: this.formControlAccessLevel,
      mobileNumber: this.formControlMobileNumber
    });
  }


  reload() {
      if (this.user) {
        this.subscriptions.push(this.getUserFn(this.user.UserId,true).pipe(
          tap(usr => {
              if (usr) {
                this.user = usr;
              }
          })
        ).subscribe());

        this.can2FAChanged(); // fetching updated details
      }
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.isLoggedinUser = this.loggedInUser?.Email === this.user.Email
    if(!this.canAccess && !this.isLoggedinUser){
      this.formControlEnabled.disable();
      this.formControlApproved.disable();
      this.formControlLocked.disable();
      this.formControl2FA.disable();
    }
    if (this.user) {
        const givenName = this.user.GivenName ?? (this.user.Name ? this.user.Name.split(' ')?.[0] : '');
        const familyName = this.user.FamilyName ?? (this.user.Name ? this.user.Name.split(' ')?.[1] : '');
        this.formControlApproved.setValue(!!this.user?.approval);
        this.formControlEnabled.setValue(!this.user?.isDisabled);
        this.formControlLocked.setValue(this.user?.isLock);
        this.formControlFirstName.setValue(givenName);
        this.formControlLastName.setValue(familyName);
        const v: AggregatorAndOriginatorListValue = this.user.OriginatorBusinessId ? {
            kind: this.user.priviledges.includes('aggregator') ? 'Aggregator': 'Originator',
            OriginatorBusinessId: this.user.OriginatorBusinessId,
            EntityName: '',
            SalesforceId: '',
        } : null;
        this.formControlAggregatorOrOriginator.setValue(v);
        this.formControlMobileNumber.setValue(this.user.MobileNumber ?? null);
        this.formControlEmail.setValue(this.user.Email ?? null);
        this.formControlAccessLevel.setValue(this.user.AccessLevel ?? null);
        if (!isInternalUser(this.loggedInUser)) {
            this.formControlAggregatorOrOriginator.disable();
            this.formControlEmail.disable();
        }
        this.formControl2FA.setValue(!!this.user?.is2FA);
    }

    this.subscriptions.push(this.formControlEnabled.valueChanges.pipe(
      tap(enabledChecked => {
        this.enableChangetoggle(enabledChecked!);
      })
    ).subscribe());
    this.subscriptions.push(this.formControlApproved.valueChanges.pipe(
      tap(approvedChecked => {
        this.approvalChangetoggle(approvedChecked!);
      })
    ).subscribe());
    this.subscriptions.push(this.formControlLocked.valueChanges.pipe(
      tap(lockUnlockChecked => {
        this.lockUnlockChangetoggle(lockUnlockChecked!);
      })
    ).subscribe());

    this.can2FAChanged();
    this.subscriptions.push(
      this.formControlEmail.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.validateEmail())
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.formControlAccessLevel.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.validateEmail())
        )
        .subscribe()
    );
    this.subscriptions.push(this.formControl2FA.valueChanges.pipe(
      distinctUntilChanged(),
      tap(twoFAChecked => {
          const email = this.user?.Email;
            if (email) {
              this.subscriptions.push(this.enableDisable2faFn(email, twoFAChecked ? 1 : 0).pipe(
                this.toastService.spinnerObservable(),
                this.toastService.snackBarObservable(twoFAChecked ? `2fa enabled for ${email}` : `2fa disabled for ${email}`),
              ).subscribe());
            } else {
              this.applicationDialogService.openAlertDialog({
                message: `Email` ,
                subMessage: `No email address exists for user, cannot enable 2FA`,
              });
            }
      })
  ).subscribe());
  }

  validateEmail = (): boolean => {
    const email = this.formControlEmail.value;
    const accessLevel = this.formControlAccessLevel.value;
    const isValid = allowEmailAccessLevel(email!, accessLevel!);

    if (!isValid) {
      this.applicationDialogService.openAlertDialog({
        message: `Access Level`,
        subMessage: `Email ${this.formControlEmail.value} is not allowed access level ${this.formControlAccessLevel.value!}`,
      });
      return false;
    } 
    
    return isValid;
  }

  onEmailTick(isValid: any) {
    if (isValid) {
      console.log('Save was successful');
    } else {
      console.log('Save failed due to validation');
    }
  }


  can2FAChanged() {
    if(!this.canAccess && !this.isLoggedinUser){
     this.formControl2FA.disable();
    }else{
    const _canChangeOthers2faAccessLevel = canChangeOthers2faAccessLevel(this.loggedInUser);
    this.canUser2faBeChanged = canUser2faAccessBeChangedByInternalUser(this.user);
    if(_canChangeOthers2faAccessLevel && this.canUser2faBeChanged){
      this.formControl2FA.enable();
      // this.setup2FaFormControl();
    } else{
      this.formControl2FA.disable();
    }
  }
  }

  approveUser(user?: User) {
    if (user) {
      this.approveOrRejectFn(user.UserId, true).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User approved`),
        tap(r => {
          this.reload();
        })
      ).subscribe()
    }
  }

  rejectUser(user?: User) {
    if (user) {
      this.subscriptions.push(this.approveOrRejectFn(user.UserId, false).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User rejected`),
        tap(r => {
          this.reload();
        })
      ).subscribe());
    }
  }

  lockUser(user?:User){
    if(user){
      this.authService.lockOrUnlockUser(user.UserId,true).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('User locked'),
        tap(r => {
          this.reload();
        })
      ).subscribe();
    }
  }

  unlockUser(user?: User){
    if(user){
      this.authService.lockOrUnlockUser(user.UserId,false).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable('User unlocked'),
        tap(r => {
          this.reload();
        })
      ).subscribe();
    }
  }

  updateEmail(user?: User){
    if(user){
      const data:{
        Email: string,
      }={
        Email: this.formControlEmail.value!
      }
      const accessLevel = this.formControlAccessLevel.value!;
      const allowInternalAccess = allowEmailAccessLevel(this.formControlEmail.value!, accessLevel);
      if (!allowInternalAccess) {
        this.applicationDialogService.openAlertDialog({
          message: `Access Level`,
          subMessage: `Email ${this.formControlEmail.value} is not allow access level ${this.formControlAccessLevel.value!}`,
        });
        return;
      }
      this.subscriptions.push(this.updateUserFn(user.UserId, data).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User Email saved`),
        tap(r => {
          this.reload();
        })
      ).subscribe());
    }
  }

  onSave(user?: User) {
    // NOTE: when saving FormGroup should be valid, due to validators set
    if (user) {
      const data: {
        GivenName: string,
        FamilyName: string,
        AccessLevel: AccessLevel,
        Email: string,
        OriginatorBusinessId: null | number,
        MobileNumber: string,
        // PhoneNumber: string,
        is2FA: boolean
      } = {
        GivenName: this.formControlFirstName.value!,
        FamilyName: this.formControlLastName.value!,
        AccessLevel: this.formControlAccessLevel.value!,
        Email: this.formControlEmail.value!,
        OriginatorBusinessId: null,
        MobileNumber: this.formControlMobileNumber.value!,
        // PhoneNumber: this.formControlPhoneNumber.value!,
        is2FA: this.formControl2FA.value!
      };

      const email = this.formControlEmail.value!;
      const accessLevel = this.formControlAccessLevel.value!;
      const allowInternalAccess = allowEmailAccessLevel(email, accessLevel);
      if (!allowInternalAccess) {
        this.applicationDialogService.openAlertDialog({
          message: `Access Level`,
          subMessage: `Email ${email} is not allow access level ${accessLevel}`,
        });
        return;
      }
      const o: AggregatorAndOriginatorListValue = this.formControlAggregatorOrOriginator.value;
      if (o) {
        data.OriginatorBusinessId = o.OriginatorBusinessId;
      }
      if (INTERNAL_LEVELS.includes(this.formControlAccessLevel.value!))  {
        data.is2FA = true;
        this.formControl2FA.setValue(true);
      }
      this.subscriptions.push(this.updateUserFn(user.UserId, data).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`User details saved`),
        tap(r => {
          if(r) {
            this.user = r; // to fetch the updated user details
          }
          this.reload();
        })
      ).subscribe());
    }
  }

  setup2FaFormControl() {
    this.subscriptions.push(this.formControl2FA.valueChanges.pipe(
        distinctUntilChanged(),
        tap(twoFAChecked => {
            const email = this.formControlEmail.value;
              if (email) {
                this.subscriptions.push(this.enableDisable2faFn(email, twoFAChecked ? 1 : 0).pipe(
                  this.toastService.spinnerObservable(),
                  this.toastService.snackBarObservable(twoFAChecked ? `2fa enabled for ${email}` : `2fa disabled for ${email}`),
                ).subscribe());
              } else {
                this.applicationDialogService.openAlertDialog({
                  message: `Email` ,
                  subMessage: `No email address exists for user, cannot enable 2FA`,
                });
              }
        })
    ).subscribe());
  }


  openConfirmPasswordAdmin($event: Event) {
    let id = this.user?.UserId;
    if(!this.canAccess && !this.isLoggedinUser){
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'You do not have access'
      });
    }else{
    if (id) {
      this.applicationDialogService.openConfirmPasswordAdminDialog({
        userId: id,
        updateUserPasswordFn: this.updateUserPasswordFn,
      })
        .afterClosed()
        .pipe(
          tap(async (r) => {
          })
        ).subscribe();
    }
  }
  }

  enableChangetoggle(enabledChecked: boolean) {
    if(enabledChecked) {
      let id: number | undefined = this.user?.UserId;
      if (id) {
        this.enableUser([id]);
      }
    } else {
      let Ids: number[] = [];
      let id: number | undefined = this.user?.UserId;
      if (id) {
        Ids.push(id);
      }
      this.disableUser(Ids);
    }
  }

  approvalChangetoggle(approvedChecked: boolean) {
    if(approvedChecked) { // approve
      this.approveUser(this.user);
    } else { // reject
      this.rejectUser(this.user);
    }
  }

  lockUnlockChangetoggle(lockUnlock: boolean){
    if(lockUnlock) {
      this.lockUser(this.user);
    }
    else{
      this.unlockUser(this.user);
    }
  }

  disableUser(Ids: number[]) {
    this.subscriptions.push(this.disableUserFn(Ids).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`User disabled`),
      tap(r => {
        this.reload();
      })
    ).subscribe());
  }


  enableUser(Ids: number[]) {
    this.subscriptions.push(this.enableUserFn(Ids).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable(`User enabled`),
      tap(r => {
        this.reload();
      })
    ).subscribe());
  }

}
