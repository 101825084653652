<div class="transaction-summary">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        <!--Trans Period-->
        <ng-container matColumnDef="transPeriod">
            <th mat-header-cell *matHeaderCellDef class="column-width-3">
              <div class="cell">Trans Period</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="cell">{{element['firstDate']| date}} - <br>{{element['lastDate']|date}}</div>
            </td>
        </ng-container>
        <!--Days -->
        <ng-container matColumnDef="days">
            <th mat-header-cell *matHeaderCellDef class="column-width-1 text-align-right">
              <div class="cell">Days</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <span class="cell"> {{getDays(element['firstDate'],element['lastDate'])}} </span>
            </td>
        </ng-container>
        <!--Opening balanace-->
        <ng-container matColumnDef="openingBalance">
          <th mat-header-cell *matHeaderCellDef class="column-width-3 text-align-right">
            <div class="cell">Opening Bal</div>
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            <div class="cell">{{element.balance?.opening ?? 0 | looseCurrency}}</div>
          </td>
        </ng-container>
        <!--Total Credits / # of Credits-->
        <ng-container matColumnDef="totalCredits">
          <th mat-header-cell *matHeaderCellDef class="column-width-3 text-align-right">
            <div class="cell">Total Credits <br> # of Credits</div>
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            <div class="cell">{{element.monthlyBalance?.totalcredit | looseCurrency}} <br>{{element.monthlyBalance?.numcredit}}</div>
          </td>
        </ng-container>
        <!--Total Debits / # of Debits-->
        <ng-container matColumnDef="totalDebits">
            <th mat-header-cell *matHeaderCellDef class="column-width-3 text-align-right">
              <div class="cell">Total Debits <br> # of Debits</div>
            </th>
            <td mat-cell *matCellDef="let element" class="text-align-right">
              <div class="cell">{{element.monthlyBalance?.totaldebit | looseCurrency}} <br>{{element.monthlyBalance?.numdebit}}</div>
            </td>
        </ng-container>
        <!--Min Day End Balance-->
        <ng-container matColumnDef="minDayEndBalance">
          <th mat-header-cell *matHeaderCellDef class="column-width-3 text-align-right">
            <div class="cell">Min Day End Bal</div>
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            <div class="cell">{{element.minBalance ?? 'N/A' | looseCurrency}}</div>
          </td>
        </ng-container>
        <!--Avg Day End Balance-->
        <ng-container matColumnDef="avgDayEndBalance">
          <th mat-header-cell *matHeaderCellDef class="column-width-3 text-align-right">
            <div class="cell">Avg Day End Bal</div>
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            <div class="cell">{{element.avgBalance ?? 'N/A' | looseCurrency}}</div>
          </td>
        </ng-container>
        <!--Days in Negative-->
        <ng-container matColumnDef="daysInNegative">
          <th mat-header-cell *matHeaderCellDef class="column-width-2 text-align-right">
            <div class="cell">Days In Neg</div>
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            <div class="cell"> {{element.daysInNegative ?? 'N/A'}}</div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
    </table>
</div>
