import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import {
  navigationUrlForForgotPassword,
  navigationUrlForSignup,
  navigationUrlForAccreditation,navigationUrlForDashboard,
  navigationUrlFor2FA,
  navigationUrlForOverdraftCustomerDashboard,
} from '../../service/navigation-urls';
import { Router, RouterLink } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import {map, switchMap, tap} from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import {concat, forkJoin, Subscription} from 'rxjs';
import { ApplicationDialogService, formControlErrorKeys, formControlErrorMessage } from '@portal-workspace/grow-ui-library';
import { hasMultipleRole, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { MinimalLayoutService } from '../../layout/minimal-layout/minimal-layout.service';
import { loadingFor } from '@ngneat/loadoff';
import { environment } from '../../../environments/environment';
import { EmailComponentValue, PayloadApiResponse, PromotionResponse, UpdateIpToken, User } from '@portal-workspace/grow-shared-library';
import { AdminService } from '../../service/admin.service';
import { TwoFactorAuthService } from '../../service/2fa.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ApplicationService } from '../../service/application.service';
import {WhitelabelService} from "../../service/whitelabel.service";



@UntilDestroy({ arrayName: 'subscriptions', checkProperties: true })
@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [FlexModule, MessageBoxComponent, NgTemplateOutlet, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, RouterLink, MatCheckboxModule, MatTooltipModule, MatButtonModule, MatDividerModule, AsyncPipe]
})
export class LoginPage implements AfterViewInit {

  environment = environment;

  subscriptions: Subscription[] = [];

  loader = loadingFor('logon', 'whitelabelLoad');
  ip: string = '';
  currentTemplate: TemplateRef<any> | null = null;


  @ViewChild('step1') step1Template!: TemplateRef<any>;
  @ViewChild('step2') step2Template!: TemplateRef<any>;
  @ViewChild('codeVerifyMobile') codeVerifyMobile!: TemplateRef<any>;
  @ViewChild('codeVerifyEmail') codeVerifyEmail!: TemplateRef<any>;
  formGroup!: FormGroup<{
    email: FormControl<string | null>,
    password: FormControl<string | null>
  }>;
  formControlEmail!: FormControl<string | null>;
  formControlPassword!: FormControl<string | null>;
  codes = [1, 2, , 3, 4, 5]
  loginSubscription?: Subscription;
  ipToken?: string;
  loginError: string | null = null;
  data?: PromotionResponse

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    private minimalLayoutService: MinimalLayoutService,
    private toastService: PortalHotToastService,
    private applicationService: ApplicationService,
    private dialogService: ApplicationDialogService,
    private whitelabelService: WhitelabelService,
    private TwoFA: TwoFactorAuthService) {
    this.formControlEmail = formBuilder.control('', [Validators.required, Validators.email]);
    this.formControlPassword = formBuilder.control('', [Validators.required]);
    this.formGroup = formBuilder.group({
      email: this.formControlEmail,
      password: this.formControlPassword,
    });
  }

  hide = true;

  ngOnInit() {
    setupUntilDestroy(this);
    this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe();
    this.reload();
    this.minimalLayoutService.settings({
      showPrev: false,
      showCross: false,
      showContact: true,
      showAccreditationInContact: true,
    });
    this.subscriptions.push(this.minimalLayoutService.headerEventChanges().pipe(
      tap(async r => {
        if (r) {
          switch (r.type) {
            case 'accreditation-clicked': {
              await this.router.navigate(navigationUrlForAccreditation());
            }
          }
        }
      })
    ).subscribe());
  }

  navigationForForgotPassword () {
    return  navigationUrlForForgotPassword();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.step1Template;
    });
  }

  private reload() {
    this.adminService.getPromotionByType('login').pipe(
      tap(r => {
        this.data = r.payload
      })
    ).subscribe();
  }



  async onLogin($event: MouseEvent) {
    let isIpToken: PayloadApiResponse<UpdateIpToken>;
    this.loginError = null;
    const email = this.formControlEmail.value ?? '';
    const password = this.formControlPassword.value ?? '';
    const ipAddress = this.ip ?? '';
    this.authService.getUserPublicStatus(email).pipe(
      this.loader.logon.track(),
      tap(r => {
        if (r.payload?.isLock) {
          this.subscriptions.push(this.dialogService.openLockUserDialog({
            message: 'Too many failed attempts',
            subMessage: 'Your login has been temporarily blocked due to too many failed attempts.  Please contact us on 1300 001 420 or reset your password.'
          }).afterClosed().pipe(
            tap(r => {
              if (r && r.type === 'reset') {
                this.router.navigate(navigationUrlForForgotPassword());
              }
            })
          ).subscribe());
        } else {
          this.loginSubscription = this.authService.login(email, password, ipAddress)
            .pipe(
              this.loader.logon.track(),
              tap(async r => {
                if (r.status) {
                  const user = r.payload.user;
                  if (user?.UserId) {
                    this.subscriptions.push(
                      this.whitelabelService
                        .loadFromRemoteAndTriggerWhitelabelEvent(user?.UserId)
                        .pipe(
                          this.loader.whitelabelLoad.track(),
                          tap(async r => {
                            if (user?.is2FA) {
                              await this.router.navigate(navigationUrlFor2FA());
                            } else {
                              await this.authService.redirectUser(this.router, user);
                            }
                          })
                        ).subscribe());
                  }
                  // if (user?.is2FA) {
                  //   await this.router.navigate(navigationUrlFor2FA());
                  // } else {
                  //   await this.authService.redirectUser(this.router, user);
                  // }
                } else {
                  this.loginError = r.message;
                }
              })
            ).subscribe()
        }
      })
    ).subscribe();
  }

  async onSignUp($event: MouseEvent) {
    await this.router.navigate(navigationUrlForSignup());
  }
}
