import { environment } from '../../environments/environment';
import {
  ApiResponse,
  GetBasiqAuthLinkFn,
  RefreshBasiqConnectionsFn,
} from '@portal-workspace/grow-shared-library';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { httpOptions } from '@portal-workspace/grow-ui-library';
import { PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import { map } from 'rxjs/operators';

const apiHost = environment.api2Host;

const URL_GET_BASIQ_AUTH_LINK = (id: string) => `${apiHost}/api2/get-basiq-auth-link/${id}`;
const URL_REFRESH_BASIQ_CONNECTIONS = (userId: string) => `${apiHost}/api2/refresh-basiq-connections/${userId}`;

@Injectable()
export class BasiqService {

  constructor(private httpClient: HttpClient) { }

  getBasiqAuthLinkFn: GetBasiqAuthLinkFn = (id: string) => {
    return this.getBasiqAuthLink(id).pipe(map(r => r))
  }

  getBasiqAuthLink(id: string): Observable<PayloadApiResponse<string | null>> {
    return this.httpClient.get<PayloadApiResponse<string | null>>(URL_GET_BASIQ_AUTH_LINK(id), httpOptions()).pipe();
  }

  refreshBasiqConnectionsFn: RefreshBasiqConnectionsFn = (userId: string) => this.refreshBasiqConnections(userId)

  refreshBasiqConnections(userId: string): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_REFRESH_BASIQ_CONNECTIONS(userId), {}, httpOptions()).pipe();
  }
}

