import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { AccountMonthlyBalance, DebitCreditSummary } from '@portal-workspace/grow-shared-library';
import {Chart, ChartItem, TooltipItem} from 'chart.js';
import {getCssVariableContent} from "@portal-workspace/grow-ui-library";

@Component({
    selector: 'monthly-debits-credits-chart',
    templateUrl: './monthly-debits-credits-chart.component.html',
    styleUrls: ['./monthly-debits-credits-chart.component.scss'],
    standalone: true
})
export class MonthlyDebitsCreditsChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input({ required: true }) data!: AccountMonthlyBalance;
  @Input({required: false}) lightTheme = false;
  @Input({required: false}) index: number = 0;

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  chart!: Chart;
  dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
  growOrange =  getCssVariableContent('--grow-warn-palette-800'); // '#FF5722';
  growOrangeLight =  getCssVariableContent('--grow-warn-palette-400') // '#FF7043';
  dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200') // '#B9A6F0';
  labels: string[] = [];
  debits: number[] = [];
  credits: number[] = [];

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index'
    },
    plugins:{
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (item: TooltipItem<'bar'>) =>
            `${item.dataset.label}: ${(item.raw as number)
              .toLocaleString("en-AU",{ style:"currency", currency:"AUD" })}`,
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        },
        grid: {
          display: true,
          borderDash: [5, 5]
        }
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback: function(value: number) {
            return value.toLocaleString("en-AU",{ style:"currency", currency:"AUD" });
          }
        }
      }
    }
  };

  constructor() {}

  ngOnInit(): void {
    this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
    this.growOrange =  getCssVariableContent('--grow-warn-palette-800'); // '#FF5722';
    this.growOrangeLight =  getCssVariableContent('--grow-warn-palette-400') // '#FF7043';
    this.dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200') // '#B9A6F0';
    this.initLabels();
    this.initData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
      this.growOrange =  getCssVariableContent('--grow-warn-palette-800'); // '#FF5722';
      this.growOrangeLight =  getCssVariableContent('--grow-warn-palette-400') // '#FF7043';
      this.dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200') // '#B9A6F0';
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initLabels();
    this.initData();
    setTimeout(() => {
      this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
      this.growOrange =  getCssVariableContent('--grow-warn-palette-800'); // '#FF5722';
      this.growOrangeLight =  getCssVariableContent('--grow-warn-palette-400') // '#FF7043';
      this.dynamoneyBlueLight = getCssVariableContent('--grow-primary-palette-200') // '#B9A6F0';
      if (this.chart) {
        this.chart.destroy();
      }
      this.display();
    });
  }

  initData() {
    if (this.data) {
      const sortedMonths = this.getSortedMonths();
      this.credits = sortedMonths.map(month => this.data.monthlycredit[month] || 0);
      this.debits = sortedMonths.map(month => Math.abs(this.data.monthlydebit[month] || 0));
    }
  }

  initLabels() {
    if (this.data) {
      const sortedMonths = this.getSortedMonths();
      this.labels = sortedMonths;
    }
  }

  private getSortedMonths(): string[] {
    const months = Object.keys(this.data.monthlycredit);
    return months.sort((a, b) => {
      const [monthA, yearA] = a.split(' ');
      const [monthB, yearB] = b.split(' ');
      const dateA = new Date(`${monthA} 1, ${yearA}`);
      const dateB = new Date(`${monthB} 1, ${yearB}`);
      return dateA.getTime() - dateB.getTime();
    });
  }

  display() {
    const chartElement = document.getElementById('monthly-debits-credits-chart' + this.index) as ChartItem;
    if (chartElement) {
      this.chart = new Chart(chartElement, {
        type: 'bar',
        options: this.chartOptions,
        data: {
          labels: this.labels,
          datasets: [
            {
              label: 'Total Debits',
              borderColor: this.lightTheme ? this.growOrangeLight : this.growOrange,
              backgroundColor: this.lightTheme ? this.growOrangeLight : this.growOrange,
              data: this.debits
            },
            {
              label: 'Total Credits',
              borderColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              backgroundColor: this.lightTheme ? this.dynamoneyBlueLight : this.dynamoneyBlue,
              data: this.credits
            }
          ]
        }
      });
    }

  }
}
