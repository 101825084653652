
<div class="identification-component">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          <mat-label>ID Type</mat-label>
          <mat-select placeholder="Select ID Type" [formControl]="formControlIdType" required>
            @for (idType of idTypes; track idType) {
              <mat-option [value]="idType.value">{{ idType.label }}</mat-option>
            }
          </mat-select>
          @for (errorKey of errorKeys(formControlIdType); track errorKey) {
            <mat-error>
              @if (formControlIdType.touched && formControlIdType.hasError(errorKey)) {
                {{ errorMessage(formControlIdType, errorKey) }}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-12">
        <mat-form-field>
          <mat-label>{{idNumberLabel}}</mat-label>
          <input matInput placeholder="Enter ID number..." [formControl]="formControlIdNumber" [required]="mandatory">
          @for (errorKey of errorKeys(formControlIdNumber); track errorKey) {
            <mat-error>
              @if (formControlIdNumber.touched && formControlIdNumber.hasError(errorKey)) {
                {{ errorMessage(formControlIdNumber, errorKey) }}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
  </form>
  </div>
