import { Component, Input, OnInit } from '@angular/core';
import { Application, BankStatement, BankStatementsAnalysisData, CreateApplicationNoteFn, GetBankStatementsAnalysisFn, GetBsaCalculatorFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, GetDscrCalculatorHistoryFn, GetDscrCalculatorValueFn, PayloadApiResponse, RefreshBankStatementFn, RemoveApplicationNoteByNoteIdFn, SaveBsaCalculatorFn, UpdateDscrCalculatorValueFn, User, isAdmin, isAnalyst, isInternalUser, GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, GetBankStatementAndBasiqDataStatusFn, BankstatementAndBasiqDataStatus, encodeReferenceForBankstatement, BasiqReferenceType } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ApplicationDialogService, GetNotesByApplicationIdFn, getAccessToken, getUser } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { getAbn } from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomerAnalysisComponent } from '../bank-statement-component/customer-analysis.component';
import { AccountSummaryComponent } from '../bank-statement-component/account-summary.component';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { BankStatementDecisioningComponent } from '../bank-statement-component/bank-statement-decisioning.component';

import { MatCardModule } from '@angular/material/card';
import { Response } from 'request';
import { DscrCalculatorComponent } from './dscr-calculator.component';
import { FlexModule } from "@angular/flex-layout";
import moment from 'moment';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { BankStatementComponent } from '../bank-statement-component/bank-statement.component';
import { BasiqStatementComponent } from '../basiq-statement-component/basiq-statement.component';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatButtonModule,
    AccountSummaryComponent,
    CustomerAnalysisComponent,
    DscrCalculatorComponent,
    FlexModule,
    BankStatementDecisioningComponent,
    BankStatementComponent,
    BasiqStatementComponent,
    MatSelectModule,
    MatOptionModule
  ]
})
export class BankComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;

  @Input() apiUrl!: string;
  @Input() bankStatementsUrl!: string;
  @Input() application!: Application;
  @Input() getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input() refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({ required: true }) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({ required: true }) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({ required: true }) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({ required: true }) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({ required: true }) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({ required: true }) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({ required: true }) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;

  //Basiq
  @Input({ required: true }) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({ required: true }) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({ required: true }) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;

  salesforceId?: string | null;
  formControlBankStatementLink: FormControl<string | null>;
  getAbn = getAbn
  hasBankStatement: boolean = false;
  hasBasiqStatement: boolean = false;
  isAdmin = isAdmin;
  isAnalyst = isAnalyst;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  bankStatementMessage!: string;
  bankStatementInfo: string | null = null;
  selectedStatementSource: 'basiq' | 'illion' | null = null;
  reference: string | null = null;
  referenceType: BasiqReferenceType | null = null;
  bankStatementLink: string | null = null;
  bankStatementNotAvailable: boolean = false;

  // TODO: refactoring move all services out, replace with functions passed in as @Input()
  constructor(private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService,) {
    this.formControlBankStatementLink = formBuilder.control(null);
  }

  ngOnInit(): void {
    console.log('bank-statements application', this.application)
    if(this.application.ApplicationType == 'Consumer') {
      this.referenceType = 'Individual';
      const applicant = (this.application.Individuals ?? []).find(i => i.Role === 'Applicant');
      const id = applicant?.Identification?.idNumber;
      if (id) {
        this.reference = encodeReferenceForBankstatement(id, 'Individual');
        this.bankStatementLink = `${this.bankStatementsUrl}/bank-statements/basiq?reference=${this.reference}`;
      } else {
        this.bankStatementNotAvailable = true;
      }
    } else {
      this.referenceType = 'Business';
      this.reference = encodeReferenceForBankstatement(getAbn(this.application), 'Business');
      this.bankStatementLink = `${this.bankStatementsUrl}/bank-statements?reference=${this.reference}`;
    }

    if(!this.reference) {
      this.salesforceId = this.application.AppInfo.SalesforceId;
    if (this.salesforceId) {
      this.formControlBankStatementLink.setValue(this.bankStatementLink);
    }
      return;
    };
    this.subscriptions.push(this.getBankStatementAndBasiqDataStatusFn(this.reference, this.referenceType)
      .pipe(
        this.portalHotToastService.spinnerObservable(),
        tap((r: PayloadApiResponse<BankstatementAndBasiqDataStatus | null>) => {
          console.log('result', r)
          if (r.status && r.payload) {
            this.hasBankStatement = r.payload.hasBankStatement;
            this.hasBasiqStatement = r.payload.hasBasiqStatement;

            if (r.payload.Status === 'pending' && r.payload.SelectedProvider) {
              this.bankStatementInfo = `On ${moment(r.payload.UpdatedTime).format('DD/MM/YYYY HH:mm:ss')} the applicant elected to use ${r.payload.SelectedProvider == 'basiq' ? 'Basiq' : 'Illion BankStatements'} to provide bank statements.
              The retrieval of bank statements is in progress. If the customer did not complete the application or if this failed,
              you can create a new link to provide to the customer.`;
            }

            if(r.payload.hasBankStatement && !r.payload.hasBasiqStatement) {
              this.selectedStatementSource = 'illion';
            } else if (r.payload.hasBasiqStatement && !r.payload.hasBankStatement) {
              this.selectedStatementSource = 'basiq';
            } else if(r.payload.hasBankStatement && r.payload.hasBasiqStatement) {
              this.selectedStatementSource = 'illion';
            }
          }
          else {
            this.hasBankStatement = false;
            this.hasBasiqStatement = false;
            this.bankStatementMessage = r.message;
          }
        })
      )
      .subscribe())
    // }
    this.salesforceId = this.application.AppInfo.SalesforceId;
    if (this.salesforceId) {
      this.formControlBankStatementLink.setValue(this.bankStatementLink);
    }
  }

  onStatementSourceChange() {
    console.log('source changed', this.selectedStatementSource);
  }
}
