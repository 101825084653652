import { AbstractControlValueAccessor } from "../abstract-control-value-accessor";
import { EditableEmailValue } from "@portal-workspace/grow-shared-library";
import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from "@angular/core";
import { MARK, Mark } from "@portal-workspace/grow-ui-library/mark";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {createEmailInputMask, formControlErrorKeys, formControlErrorMessage} from "../component-utils";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {Mode} from "@ngneat/edit-in-place/lib/mode";
import {EditableComponent, EditableSaveDirective, EditModeDirective, ViewModeDirective} from "@ngneat/edit-in-place";
import { InputMaskModule } from '@ngneat/input-mask';
import { DisableControlDirective } from '../../directives/disable-control.directive';
import { validEmailValidator } from "../../validators/validators";
import { AuthService } from "apps/portal2/src/app/service/auth.service";

@Component({
  selector: 'editable-email',
  templateUrl: './editable-email.component.html',
  styleUrls: ['./editable-email.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexModule,
    JsonPipe,
    AsyncPipe,
    MatTooltipModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    EditableComponent,
    EditableSaveDirective,
    EditModeDirective,
    ViewModeDirective,
    InputMaskModule,
    DisableControlDirective
  ],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(()=>EditableEmailComponent), multi: true},
    { provide: MARK, useExisting: forwardRef(() => EditableEmailComponent), multi: true},
  ]
})
export class EditableEmailComponent extends AbstractControlValueAccessor<EditableEmailValue> implements OnInit, Mark {
  mode: Mode = 'view';
  readValue: EditableEmailValue = null;
  formControl!: FormControl<EditableEmailValue>;
  initialEmailValue: EditableEmailValue = null;
  createEmailInputMask = createEmailInputMask();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  formGroup!: FormGroup<{
    email: FormControl<EditableEmailValue>
  }>;
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService) {
    super();
    this.formControl = this.formBuilder.control(null, [Validators.required, Validators.email], validEmailValidator(this.authService.validBrokerEmailCheckFn));
  }

  mark(): void {
    this.formControl.markAllAsTouched();
  }

  ngOnInit(): void {
  }

  doWriteValue(v?: EditableEmailValue | undefined): void | EditableEmailValue {
    if (v != null || v != undefined) {
      this.readValue = v;
      this.formControl.setValue(v);
    } else {
      this.readValue = null;
      this.formControl.setValue(null);
    }
  }

  onSave() {
    this.mark();
    this.readValue = this.formControl.value;
    this.propagateChange(this.readValue);
  }

  onCancel() {
    this.formControl.setValue(this.initialEmailValue, { emitEvent: false });
    this.mode = 'view'; // Switch to view mode after canceling
  }

  modeChange($event: Mode) {
    this.mode = $event;
    if ($event === 'edit') {
      this.initialEmailValue = this.formControl.value; // Store the initial value on entering edit mode
    } else if ($event === 'view') {
      this.formControl.setValue(this.readValue, { emitEvent: false }); // Ensure form control is set to the saved value when switching to view mode
    }
  }

  trackByFn(index: number, item: any): any {
    return index; // or item.id if items have unique ids
  }
}
