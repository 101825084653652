@if (this.data?.showMessage==true) {
  <div class="promo-wrapper container-fluid" fxLayout="column" fxLayoutAlign="center center">
    <message-box type="promo-style1" [url]="this.data?.link||''">{{this.data?.message}}
    </message-box>
  </div>
}

<div class="login-page container-fluid">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>

<ng-template #step1>
  <div class="step1">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-h3 title">To continue, log in to Dynamoney</div>
    </div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroup">
      <mat-form-field fxFlex="1 1 auto">
        <input matInput type="text" [formControl]="formControlEmail" placeholder="Email">
        @for (errorKey of errorKeys(formControlEmail); track errorKey) {
          <mat-error>
            @if (formControlEmail.touched && formControlEmail.hasError(errorKey)) {
              {{errorMessage(formControlEmail, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field fxFlex="1 1 auto">
        <input matInput [formControl]="formControlPassword" placeholder="Password" [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide" class="mdi mdi-eye-outline"></mat-icon>
        @for (errorKey of errorKeys(formControlPassword); track errorKey) {
          <mat-error>
            @if (formControlPassword.touched && formControlPassword.hasError(errorKey)) {
              {{errorMessage(formControlPassword, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>

      @if (loginError) {
        <div fxFlex="1 1 auto" class="width-100 separator-bottom vivify shake duration-600">
          <message-box type="danger" title="Error">
            {{loginError}}
          </message-box>
        </div>
      }
      <div class="width-100 separator-bottom forgot-password"
        fxFlex="1 1 auto"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center center"
        fxLayout="row"
        fxLayoutAlign="center center">
        <div class="mat-body bottom-text icon-hover mr-1">Forgot your password?</div>
        <a href="#" [routerLink]="navigationForForgotPassword()">Recover it now<span class="mdi mdi-arrow-right"></span></a>
      </div>
      <div fxFlex="1 1 auto" class="form-field">
        <div fxFlex="1 0 auto" class="row" fxFlexAlign="center center">
          <div class="col-md-6 pr-0">
            <mat-checkbox class="rememberme" color="primary"
              #rememberMeTooltip="matTooltip"
              matTooltip="Remember me feature is always on"
            (click)="$event.preventDefault(); rememberMeTooltip.show()" [checked]="true">Remember me</mat-checkbox>
          </div>
          <div class="col-md-6 form-button">
            <button
              mat-flat-button color="primary"
              [disabled]="formGroup.invalid || (loader.logon.inProgress$ | async) || (loader.whitelabelLoad.inProgress$ | async)" (click)="onLogin($event)">
              @if ((loader.logon.inProgress$ | async) || (loader.whitelabelLoad.inProgress$ | async)) {
                <span>Signing in ...</span>
              } @else {
                <span>Sign In</span>
              }
            </button>
          </div>
        </div>
      </div>
      <div fxFlex="1 1 auto" class="width-100 separator-bottom">
        <mat-divider [inset]="false"></mat-divider>
      </div>
      <div class="width-100" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
        <div class="mat-h4-custom">Don't have an account?</div>
      </div>
      <div fxFlex="1 1 auto" class="form-button">
        <button mat-stroked-button color="primary" (click)="onSignUp($event)">Sign Up</button>
      </div>
    </form>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-caption version">{{environment.gitTag ? environment.gitTag : environment.gitHash}}</div>
    </div>
  </div>
</ng-template>
