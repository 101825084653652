import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  BankStatementsAnalysisCategory, BankStatementsAnalysisTransactionDetails,
  BankStatementsAnalysisTransactionData, TransactionTag, DEFAULT_LIMIT, DEFAULT_OFFSET, AccountFilter, BasiqTransaction, BasiqAccount,
} from '@portal-workspace/grow-shared-library';
import { PageEvent } from '@angular/material/paginator';
import { FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationDialogService, setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Sort, MatSortModule } from '@angular/material/sort';
import { compare } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import _ from 'lodash';
import moment from 'moment';

interface BasiqStatementAnalysisData {
  date: string;
  description: string;
  credit: string;
  debit: string;
  accountId: string;
  id?: string;
  class: string;
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'basiq-full-transaction-list',
  templateUrl: './basiq-full-transaction-list.component.html',
  styleUrls: ['./basiq-full-transaction-list.component.scss'],
  standalone: true,
  imports: [FlexModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatTableModule, MatSortModule, NgClass, ExtendedModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class BasiqFullTransactionListComponent implements OnInit, OnChanges {
  @Input({ required: false }) transactions: BasiqTransaction[] = [];
  @Input({ required: false }) accounts: BasiqAccount[] = [];
  @Input({ required: false }) selectedAccounts: string[] = [];

  columnsToDisplay: string[] = ['date', 'description', 'class', 'account', 'credit', 'debit'];
  dataSource: BasiqStatementAnalysisData[] = [];
  filteredDataSource: BasiqStatementAnalysisData[] = [];
  displayedData: BasiqStatementAnalysisData[] = [];

  subscriptions: Subscription[] = [];
  formControlSearch!: FormControl<string | null>;
  formControlSearchList: FormArray = new FormArray<any>([]);
  formControlSearchLogic!: FormControl<'AND' | 'OR' | null>;
  searchLogicOptions = ['AND', 'OR'];
  accountFilter: { [key: string]: FormControl<boolean | null> } = {};
  limit!: number;
  offset!: number;
  total: number = 0;
  filter: string[] = [];
  showMessage!: string;
  accountSelectionChanges: boolean = true;
  firstTimeLoadData: boolean = true;
  singleAccount = false;

  constructor(
    private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService
  ) {
    this.formControlSearch = this.formBuilder.control('');
    this.formControlSearchLogic = this.formBuilder.control('OR');
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.singleAccount = this.accounts.length === 1;
    this.addSearchControl();
    this.initAccountFilter();

    if (this.singleAccount) {
      this.initPageData();
    }

    this.subscriptions.push(
      this.formControlSearchList.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r: string[]) => {
          this.filter = r.filter(i => !!i).map(i => i.toLowerCase().trim());
          this.filterAccounts(true);
          this.onSearch(false);
          this.initPagination();
        })
      ).subscribe()
    );

    this.subscriptions.push(
      this.formControlSearchLogic.valueChanges.pipe(
        tap(() => {
          this.filter = this.formControlSearchList.value.filter((i: string[]) => !!i).map((i: string) => i.toLowerCase().trim());
          this.filterAccounts(true);
          this.onSearch(false);
          this.initPagination();
        })
      ).subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initAccountFilter();
    if (this.singleAccount) {
      this.initPageData();
    }
    if (changes['selectedAccounts']) {
      this.reloadTable();
    }
  }

  initAccountFilter() {
    console.log('accounts', this.accounts)
    this.accounts.forEach((account: BasiqAccount) => {
      this.accountFilter[account.id] = new FormControl<boolean | null>(this.singleAccount);
    });

    if (this.selectedAccounts.length) {
      this.accounts.forEach((account: BasiqAccount) => {
        this.accountFilter[account.id].setValue(this.selectedAccounts.includes(account.id));
      });
    }
  }

  initPageData(): void {
    this.dataSource = [];
    this.transactions.forEach((transaction: BasiqTransaction) => {
      this.dataSource.push({
        id: transaction.id,
        date: transaction.postDate,
        description: transaction.description,
        credit: transaction.direction == 'credit' ? transaction.amount : '',
        debit: transaction.direction == 'debit' ? transaction.amount : '',
        accountId: transaction.account ?? '',
        class: transaction.class
      })
    })
    console.log('transaction in datasource', this.transactions)
    this.dataSource.sort((a, b) => (a.date > b.date ? -1 : 1));
    this.filteredDataSource = this.dataSource;
    this.initPagination();
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    console.log('total', this.total);
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  onSearch(firstTriggered: boolean) {
    if (this.filter.length) {
      this.filteredDataSource = (firstTriggered ? this.dataSource : this.filteredDataSource).filter(data => {
        const s = (
          data.date +
          data.description +
          data.credit.toString() +
          data.debit.toString() +
          this.getAccountNameById(data.accountId)
        ).toLowerCase();
        if (this.formControlSearchLogic.value === 'OR') {
          for (const f of this.filter) {
            if (s.includes(f)) {
              return true;
            }
          }
          return false;
        } else {
          for (const f of this.filter) {
            if (!s.includes(f)) {
              return false;
            }
          }
          return true;
        }
      });
    } else {
      this.filteredDataSource = this.dataSource;
    }
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  getAccountNameById(accountId: string) {
    return this.accounts.find(a => a.id == accountId)?.name ?? ''
  }

  onSort(sort: Sort) {
    const data = this.filteredDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredDataSource = data;
      return;
    }

    console.log('data', data)

    this.filteredDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'credit':
          return compare(Number(a.credit), Number(b.credit), isAsc);
        case 'debit':
          return compare(Number(a.debit), Number(b.debit), isAsc);
        case 'account':
          return compare(this.getAccountNameById(a.accountId), this.getAccountNameById(b.accountId), isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'description': return 'Description';
      case 'credit': return '$ Credit';
      case 'debit': return '$ Debit';
      case 'account': return 'Account';
      case 'class': return 'Category';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['credit', 'debit'].includes(column);
  }

  displayColumnValue(element: BasiqStatementAnalysisData, column: string): string {
    if (column == 'date') {
      return moment(element.date).format('DD/MM/YYYY')
    }
    else if (column == 'description') {
      return element.description
    }
    else if (column == 'credit') {
      return element.credit
    }
    else if (column == 'debit') {
      return element.debit
    }
    else if (column == 'class') {
      return element.class
    }
    else if (column == 'account') {
      return this.getAccountNameById(element.accountId)
    } else {
      return 'N/A'
    }
  }

  onPagination(event: PageEvent) {
    this.limit = event.pageSize;
    this.offset = event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  exportCsv() {
    const rawCsvData = this.filteredDataSource.map((d: any) => {
      const row: any = {};
      [...this.columnsToDisplay].forEach((col: string) => {
        row[this.getColumnTitles(col)] = d[col];
      });
      return row;
    });
    const header = Object.keys(rawCsvData[0]);
    const csv = rawCsvData.map(row => header.map(fieldName => JSON.stringify(row[fieldName])).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'transactions.csv');
  }

  filterAccounts(firstTriggered: boolean) {
    const accountIds = Object.keys(this.accountFilter);
    const filteredAccountIds: string[] = [];
    for (const accountId of accountIds) {
      const checked = this.accountFilter[accountId].value;
      if (checked) {
        filteredAccountIds.push(accountId);
      }
    }
    this.filteredDataSource = (firstTriggered ? this.dataSource : this.filteredDataSource).filter(data => {
      return filteredAccountIds.includes(data.accountId);
    });

    if (this.filteredDataSource.length === 0) {
      this.showMessage = 'No data found';
    }
  }

  filterChangeHandler() {
    this.accountSelectionChanges = true;
    this.firstTimeLoadData = false;
  }

  reloadTable() {
    // const ref = this.applicationDialogService.openProgressSpinnerDialog();
    // setTimeout(() => {
    //   ref.close();
    // }, 3000);

    this.initPageData();
    this.onSearch(true);
    this.filterAccounts(false);
    this.initPagination();

    this.accountSelectionChanges = false;
  }

  showFilter() {
    return (
      this.accounts &&
      this.accounts.length &&
      this.accounts.length > 1 &&
      (this.accounts[0]?.id !== undefined || this.accounts[0]?.id !== null)
    );
  }

  addSearchControl() {
    this.formControlSearchList.push(new FormControl<string | null>(''));
  }

  removeSearchControl(index: number) {
    this.formControlSearchList.removeAt(index);
  }
}
