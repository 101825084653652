<div class="document-details-component">
  <div class="header mb-3"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="end center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center start"
    >
    <!-- <button mat-flat-button color="primary" class="active w-lt-md-100" matTooltip="Submit for review the documents by Dynamoney" (click)="onSubmitForReview()">
    <span class="mdi mdi-upload-multiple" matPrefix></span>
    Submit for Review
  </button> -->

  <!-- <button
      mat-flat-button
      class="w-lt-md-100"
      (click)="onSubmitDocuments()">
      <span class="label">Request Review</span>
    </button> -->
    

  <!-- for asset finance and consumer asset as both the logics are same-->
  @if (isAssetApplication) {
    <button
      mat-flat-button
      class="w-lt-md-100"
      [disabled]="isAssetEmpty || (notAllAssetAccepted && isInternalUser(loggedInUser)) || !applicationStageAllowSettleLoan(application) || (missingBankDetails && isInternalUser(loggedInUser))"
      (click)="onAssetSettleLoan()">
      <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
      <span class="label">Request Settlement</span>
    </button>
  }

  <!-- for Business Term Loan and business overdraft as both the logics are same -->
  @if (isBusinessApplication) {
    <button
      mat-flat-button
      [disabled]="!applicationStageAllowSettleLoan(application)"
      class="w-lt-md-100"
      (click)="onBusinessSettleLoan()">
      <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
      <span class="label">Request Settlement</span>
    </button>
  }

  <!-- for corporate loan -->
  @if (isCorporateLoanApplication) {
    <button
      mat-flat-button
      [disabled]="!applicationStageAllowSettleLoan(application)"
      class="w-lt-md-100"
      (click)="onCorporateLoanSettleLoan()">
      <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
      <span class="label">Request Settlement</span>
    </button>
  }


  <button mat-flat-button class="inactive w-lt-md-100"
    [matTooltip]="moreActionTooltips"
    matTooltipClass="tooltip-list"
    [matMenuTriggerFor]="moreActions">
    <span class="mdi mdi-dots-vertical" matPrefix></span>
    More
    <span class="mdi mdi-chevron-down"></span>
  </button>
  <mat-menu #moreActions="matMenu">
    <ng-container>
      <button mat-menu-item class="menu-items-large" matTooltip="Download all docs as ZIP"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="onDownloadAll()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Download All</div>
          <div><span class="mdi mdi-tray-arrow-down"></span></div>
        </div>
      </button>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container>
      <button mat-menu-item class="menu-items-large" matTooltip="Upload document"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="uploadDocument()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Upload Document</div>
          <div><span class="mdi mdi-file-document-alert-outline"></span></div>
        </div>
      </button>
      <mat-divider></mat-divider>
    </ng-container>

    @if (isInternalUser(user)) {
      <button mat-menu-item class="menu-items-large" matTooltip="Request additional doc types for this application"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="onRequestAdditionalDoc()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Request Additional Docs</div>
          <div><span class="mdi mdi-file-document-alert-outline ml-3"></span></div>
        </div>
      </button>
      <mat-divider></mat-divider>
    }
    @if (isInternalUser(user)) {
      <button mat-menu-item class="menu-items-large" matTooltip="Send notification emails about current doc status"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="onSendNotification()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Send Notification</div>
          <div><span class="mdi mdi-bell-outline"></span></div>
        </div>
      </button>
      <mat-divider></mat-divider>
    }
    @if (isInternalUser(user)) {
      <button mat-menu-item class="menu-items-large" matTooltip="Complete the evaluation and remove this application from document worklist"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="onComplete()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Complete</div>
          <div><span class="mdi mdi-check-circle-outline"></span></div>
        </div>
      </button>
      <mat-divider></mat-divider>
    }
    @if (isInternalUser(user) && (
      application.ApplicationType === 'AssetFinance' || application.ApplicationType === 'Consumer' ||
      application.ApplicationType === 'BusinessOverdraft' || application.ApplicationType === 'BusinessLoans'
      )) {
      <button mat-menu-item class="menu-items-large" matTooltip="Send emails with approval notice"
        matTooltipClass="tooltip-list" matTooltipPosition="left" (click)="onSendApprovalNotice()">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>Approval Notice</div>
          <div><span class="mdi mdi-information-outline"></span></div>
        </div>
      </button>
    }
  </mat-menu>
</div>

<div class="documents-table">

  @if (loader.documentDetails.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  <!--    standard condition table-->
  <div [ngClass]="{hidden: (loader.documentDetails.inProgress$ | async)}">
    <table mat-table [dataSource]="dataSourceStandardConditions" multiTemplateDataRows class="width-100 mb-4">
      @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-5': column === 'checkBox',
          'column-width-4': column === 'action',
          'column-width-3': column === 'documents',
          'column-width-2': column === 'status',
          'column-width-1': column === 'groupName',
          'column-width-0': column === 'icon'
          }">
            <div class="cell">{{getColumnTitles(column, 'standard condition')}}</div>
          </th>
          <td mat-cell *matCellDef="let element"
            (click)="expandedElementStandardCondition = (column === 'action' ? expandedElementStandardCondition : (expandedElementStandardCondition === element ? null : element))">
            <div class="cell">
              @if (column === 'icon') {
                @if (expandedElementStandardCondition === element) {
                  <span class="mdi mdi-chevron-up"></span>
                }
                @if (expandedElementStandardCondition !== element) {
                  <span class="mdi mdi-chevron-down"></span>
                }
              }
              @if (column === 'groupName') {
                <div class="text-bold text-danger">
                  {{element[column]}}
                </div>
                <div class="date-text">{{element.description}}</div>
              }
              @if (column === 'stageRequired') {
                <tag-box [state]="'Something here'"></tag-box>
              }
              @if (column === 'status') {
                <tag-box [state]="element?.docs?.length ? element[column] : (element?.standardConditionStatus ?? 'Pending')"></tag-box>
              }
              @if (column === 'documents') {
                @if (element?.docs) {
                  @for (doc of element?.docs?.slice(0, 15); track doc) {
                    <span class="mdi mdi-file-document-outline cursor-pointer" (click)="onDownloadDocument(doc)" [matTooltip]="doc?.metadata?.name"></span>
                  }
                }
              }
              @if (column === 'checkBox') {
                <mat-checkbox [ngClass]="{hidden: !showCheckboxColumn}"
                  color="primary"
                  (change)="onSelectionChanged($event, element)"
                  [checked]="selectedGroupName.isSelected(element)"
                ></mat-checkbox>
              }  
              @if (column === 'action') {
                <div fxLayout="row" fxLayoutAlign="end center">
                  <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
                </div>
                <mat-menu #menu="matMenu">
                  @if (!element?.disableDocumentUpload) {
                    <button mat-menu-item class="menu-items" (click)="onUploadDocument(element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Attach</div>
                        <div><span class="mdi mdi-attachment"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onApproveDocument(element.docs, element.value, 'standardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Accept</div>
                        <div><span class="mdi mdi-check"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onDeclineDocument(element.docs, element.value, 'standardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Reject</div>
                        <div><span class="mdi mdi-close"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onUndoDocument(element.docs, element.value, 'standardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Undo</div>
                        <div><span class="mdi mdi-arrow-u-left-top"></span></div>
                      </div>
                    </button>
                  }
                </mat-menu>
              }
            </div>
          </td>
        </ng-container>
      }

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="expanded-row" [attr.colspan]="columnsToDisplay.length"
          [ngClass]="{'hide-border': element == expandedElementStandardCondition && element?.docs?.length}">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElementStandardCondition ? 'expanded' : 'collapsed'">
            @if (element?.docs && element?.docs?.length) {
              <document-details-sub-table
                class="sub-table"
                [data]="element.docs"
                [tagName]="element.value"
                [application]="application"
                [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
                [approveApplicationDocumentFn]="approveApplicationDocumentFn"
                [declineApplicationDocumentFn]="declineApplicationDocumentFn"
                [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
                [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
                [undoApplicationDocumentFn]="undoApplicationDocumentFn"
                (reload)="reload()"
              ></document-details-sub-table>
            }
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row" [ngClass]="{'drag-focus': dragElement === element}"
        (drop)="onDrop($event, element)"
        (dragover)="onDragOver($event, element)"
        [class.example-expanded-row]="expandedElementStandardCondition === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>

  <!--    non-standard condition table-->
  <div [ngClass]="{hidden: (loader.documentDetails.inProgress$ | async)}">
    <table mat-table [dataSource]="dataSourceNonStandardConditions" multiTemplateDataRows class="width-100 mb-4">
      @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-5': column === 'checkBox',
          'column-width-4': column === 'action',
          'column-width-3': column === 'documents',
          'column-width-2': column === 'status',
          'column-width-1': column === 'groupName',
          'column-width-0': column === 'icon'
          }">
            <div class="cell">{{getColumnTitles(column, 'non-standard condition')}}</div>
          </th>
          <td mat-cell *matCellDef="let element"
            (click)="expandedElementNonStandardCondition = (column === 'action' ? expandedElementNonStandardCondition : (expandedElementNonStandardCondition === element ? null : element))">
            <div class="cell">
              @if (column === 'icon') {
                @if (expandedElementNonStandardCondition === element) {
                  <span class="mdi mdi-chevron-up"></span>
                }
                @if (expandedElementNonStandardCondition !== element) {
                  <span class="mdi mdi-chevron-down"></span>
                }
              }
              @if (column === 'groupName') {
                <div class="text-bold text-danger">
                  {{element[column]}}
                </div>
                <div class="date-text">{{element.description}}</div>
              }
              @if (column === 'stageRequired') {
                <tag-box [state]="'Something here'"></tag-box>
              }
              @if (column === 'status') {
                <tag-box [state]="element?.docs?.length ? element[column] : (element?.nonStandardConditionStatus ?? 'Pending')"></tag-box>
              }
              @if (column === 'documents') {
                @if (element?.docs) {
                  @for (doc of element?.docs?.slice(0, 15); track doc) {
                    <span class="mdi mdi-file-document-outline cursor-pointer" (click)="onDownloadDocument(doc)" [matTooltip]="doc?.metadata?.name"></span>
                  }
                }
              }
             @if (column === 'checkBox' && showCheckboxColumn) {
                <mat-checkbox
                  color="primary"
                  (change)="onSelectionChanged($event, element)"
                  [checked]="selection.isSelected(element)"
                ></mat-checkbox>
              } 
              @if (column === 'action') {
                <div fxLayout="row" fxLayoutAlign="end center">
                  <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
                </div>
                <mat-menu #menu="matMenu">
                  @if (!element?.disableDocumentUpload) {
                    <button mat-menu-item class="menu-items" (click)="onUploadDocument(element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Attach</div>
                        <div><span class="mdi mdi-attachment"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onApproveDocument(element.docs, element.value, 'nonStandardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Accept</div>
                        <div><span class="mdi mdi-check"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onDeclineDocument(element.docs, element.value, 'nonStandardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Reject</div>
                        <div><span class="mdi mdi-close"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (!element?.disableDocumentUpload) {
                    @if (isInternalUser(user)) {
                      <button mat-menu-item class="menu-items" (click)="onDeleteDocument(element.value, element.groupName)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Delete</div>
                          <div><span class="mdi mdi-trash-can-outline"></span></div>
                        </div>
                      </button>
                    }
                    <mat-divider></mat-divider>
                  }
                  @if (isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onUndoDocument(element.docs, element.value, 'nonStandardCondition')">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Undo</div>
                        <div><span class="mdi mdi-arrow-u-left-top"></span></div>
                      </div>
                    </button>
                  }
                </mat-menu>
              }
            </div>
          </td>
        </ng-container>
      }

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="expanded-row" [attr.colspan]="columnsToDisplay.length"
          [ngClass]="{'hide-border': element == expandedElementNonStandardCondition && element?.docs?.length}">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElementNonStandardCondition ? 'expanded' : 'collapsed'">
            @if (element?.docs && element?.docs?.length) {
              <document-details-sub-table
                class="sub-table"
                [data]="element.docs"
                [tagName]="element.value"
                [application]="application"
                [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
                [approveApplicationDocumentFn]="approveApplicationDocumentFn"
                [declineApplicationDocumentFn]="declineApplicationDocumentFn"
                [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
                [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
                [undoApplicationDocumentFn]="undoApplicationDocumentFn"
                (reload)="reload()"
              ></document-details-sub-table>
            }
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row" [ngClass]="{'drag-focus': dragElement === element}"
        (drop)="onDrop($event, element, true)"
        (dragover)="onDragOver($event, element)"
        [class.example-expanded-row]="expandedElementNonStandardCondition === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>

  <div [ngClass]="{hidden: (loader.documentDetails.inProgress$ | async)}">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-4">
      @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
          'column-width-5': column === 'checkBox',
          'column-width-4': column === 'action',
          'column-width-3': column === 'documents',
          'column-width-2': column === 'status',
          'column-width-1': column === 'groupName',
          'column-width-0': column === 'icon'
          }">
            <div class="cell">{{getColumnTitles(column)}}</div>
          </th>
          <td mat-cell *matCellDef="let element"
            (click)="expandedElement = (column === 'action' ? expandedElement : (expandedElement === element ? null : element))">
            <div class="cell">
              @if (column === 'icon') {
                @if (expandedElement === element) {
                  <span class="mdi mdi-chevron-up"></span>
                }
                @if (expandedElement !== element) {
                  <span class="mdi mdi-chevron-down"></span>
                }
              }
              @if (column === 'groupName') {
                <div class="text-bold" [ngClass]="{'text-danger': element.required}">
                  {{element[column]}}
                </div>
                <div class="date-text">{{element.description}}</div>
              }
              @if (column === 'stageRequired') {
                <tag-box [state]="'Something here'"></tag-box>
              }
              @if (column === 'status') {
                <tag-box [state]="element[column]"></tag-box>
              }
              @if (column === 'documents') {
                @if (element?.docs) {
                  @for (doc of element?.docs?.slice(0, 15); track doc) {
                    <span class="mdi mdi-file-document-outline cursor-pointer" (click)="onDownloadDocument(doc)" [matTooltip]="doc?.metadata?.name"></span>
                  }
                }
              }
               @if (column === 'checkBox' && showCheckboxColumn) {
                <mat-checkbox
                  color="primary"
                  (change)="onSelectionChanged($event, element)"
                  [checked]="selection.isSelected(element)"
                ></mat-checkbox>
              }  
              @if (column === 'action') {
                <div fxLayout="row" fxLayoutAlign="end center">
                  <span class="mdi mdi-dots-vertical cursor-pointer" [matMenuTriggerFor]="menu"></span>
                </div>
                <mat-menu #menu="matMenu">
                  @if (!element?.disableDocumentUpload) {
                    <button mat-menu-item class="menu-items" (click)="onUploadDocument(element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Attach</div>
                        <div><span class="mdi mdi-attachment"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (element?.docs?.length && isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onApproveDocument(element.docs, element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Accept</div>
                        <div><span class="mdi mdi-check"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (element?.docs?.length && isInternalUser(user)) {
                    <button mat-menu-item class="menu-items" (click)="onDeclineDocument(element.docs, element.value)">
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>Reject</div>
                        <div><span class="mdi mdi-close"></span></div>
                      </div>
                    </button>
                    <mat-divider></mat-divider>
                  }
                  @if (!element?.disableDocumentUpload) {
                    @if (isInternalUser(user)) {
                      <button mat-menu-item class="menu-items" (click)="onDeleteDocument(element.value, element.groupName)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Delete</div>
                          <div><span class="mdi mdi-trash-can-outline"></span></div>
                        </div>
                      </button>
                    }
                    <mat-divider></mat-divider>
                  }
                  @if (element?.docs?.length && isInternalUser(user)) {
                    @if (isInternalUser(user)) {
                      <button mat-menu-item class="menu-items" (click)="onUndoDocument(element.docs, element.value, element.groupName)">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div>Undo</div>
                          <div><span class="mdi mdi-arrow-u-left-top"></span></div>
                        </div>
                      </button>
                    }
                  }
                </mat-menu>
              }
            </div>
          </td>
        </ng-container>
      }

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" class="expanded-row" [attr.colspan]="columnsToDisplay.length"
          [ngClass]="{'hide-border': element == expandedElement && element?.docs?.length}">
          <div class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            @if (element?.docs && element?.docs?.length) {
              <document-details-sub-table
                class="sub-table"
                [data]="element.docs"
                [tagName]="element.value"
                [application]="application"
                [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
                [approveApplicationDocumentFn]="approveApplicationDocumentFn"
                [declineApplicationDocumentFn]="declineApplicationDocumentFn"
                [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
                [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
                [undoApplicationDocumentFn]="undoApplicationDocumentFn"
                (reload)="reload()"
              ></document-details-sub-table>
            }
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row" [ngClass]="{'drag-focus': dragElement === element}"
        (drop)="onDrop($event, element)"
        (dragover)="onDragOver($event, element)"
        [class.example-expanded-row]="expandedElement === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>
</div>
 <div
  fxLayout="row"
  fxLayoutAlign="end center"
  >
  <!-- <button [ngClass]="{hidden: this.selectedGroupName.selected.length == 0}"
        mat-flat-button
        class="w-lt-md-100 floating-btn"
        (click)="onSubmitDocuments()">
        <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
        <span class="label">Submit Request Review</span>
  </button> -->
</div> 

</div>
