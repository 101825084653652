<div class="payment-chart-component"
  gdAreas="chart data"
  gdAreas.lt-md="chart | data"
  gdRows="auto"
  gdColumns="50% 50%"
  gdColumns.lt-md="100%">
  <div gdArea="chart" class="chart-wrapper">
    <div class="canvas-wrapper">
      <canvas #canvasElement id="{{chartId}}"></canvas>
      <div class="chart-text">
        <div class="mat-h2">{{data.emiAmt | currency}}</div>
        <div class="mat-body">Your {{data.paymentFrequency}} Payment</div>
      </div>
    </div>
  </div>
  <div gdArea="data" class="data">
    <!-- row 1 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-75">
        <div class="amount-financed-logo"></div>
        <div class="amount-financed-logo-text">Amount Financed @if (data.applicationType !== 'Consumer') {
          (incl Brokerage)
        }</div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">
        @if (data.applicationType === 'Consumer') {
          {{data.principalAmt | looseCurrency}}
        }
        @if (data.applicationType !== 'Consumer') {
          {{(data.principalAmt + data.brokerageAmount) | looseCurrency}}
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 2 -->
    <div fxLayout="row" class="separator">
      <div class="mat-body width-50">
        <div class="total-interest-logo"></div>
        <div class="total-interest-logo-text"> Total Interest </div>
      </div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.interestAmt | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 3-->
    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-50">Total Payment</div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.totalAmt | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 4-->
    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-50">Interest Rate</div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">
        @if (data.applicationType !== 'Consumer') {
          {{data.totalInterest | percentage : '1.2-2'}}
        }
        @if (data.applicationType === 'Consumer') {
          {{data.displayedInterest | percentage : '1.2-2'}}
        }
        @if (calculationLog) {
          <span class="mdi mdi-information-outline" (click)="onShowRateCalculation()"></span>
        }
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 5-->
    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-50">Loan Terms</div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.loanTerm | number}} Months</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 6-->
    @if (data.applicationType === 'AssetFinance' || data.applicationType === 'Consumer') {
      <div fxLayout="row" class="separator">
        <div class="no-logo-text mat-body width-50">Invoice Amount</div>
        <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.invoiceAmount | looseCurrency}}</div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }

    <!-- row 7-->
    @if ((data.applicationType === 'AssetFinance' || data.applicationType === 'Consumer') && data.deposit) {
      <div fxLayout="row" class="separator">
        <div class="no-logo-text mat-body width-50">Deposit (incl GST)</div>
        <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.deposit | looseCurrency}}</div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }

    <!-- row 8-->
    @if (hideBalloonPayment) {
      <div fxLayout="row" class="separator">
        <div class="no-logo-text mat-body width-50">Balloon Payment (Residual)</div>
        <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.rv | looseCurrency}}</div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    }

    <!-- row 9-->
    @if (!hideBrokerage) {
      <div fxLayout="row" class="separator">
        <div class="no-logo-text mat-body width-50">Brokerage Amount</div>
        <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.brokerageAmount | looseCurrency}}</div>
      </div>
    }
    @if (!hideBrokerage) {
      <mat-divider [inset]="true"></mat-divider>
    }

    <!-- row 10-->
    <div fxLayout="row" class="separator">
      <div class="no-logo-text mat-body width-50">{{data.applicationType==='AssetFinance' ? 'Doc Fee' : data.applicationType==='Consumer' ? 'Doc Fee (incl PPSR Fee)' : 'Doc Fee'}}</div>
      <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.docFee | looseCurrency}}</div>
    </div>
    <mat-divider [inset]="true"></mat-divider>

    <!-- row 11-->
    @if (!hideBrokerOrigiantionFee) {
      <div fxLayout="row" class="separator">
        <div class="no-logo-text mat-body width-50">
          @if (data.applicationType === 'Consumer') {
            Credit Assistance Fee
          }
          @if (data.applicationType !== 'Consumer') {
            Broker Origination Fee
          }
        </div>
        <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.brokerOriginationFee | looseCurrency}}</div>
      </div>
    }
  </div>
</div>
