import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  BankStatementsAnalysisTransactionDetails,
  BasiqCategoryDetailsTableData,
  BasiqTransaction,
  CategoryTransactionCheckbox, compare,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET
} from '@portal-workspace/grow-shared-library';
import { PageEvent } from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import moment from 'moment';

@Component({
  selector: 'basiq-category-details-table',
  templateUrl: './basiq-category-details-table.component.html',
  styleUrls: ['./basiq-category-details-table.component.scss'],
  standalone: true,
  imports: [MatTableModule, MatSortModule, NgClass, ExtendedModule, MatCheckboxModule, CustomPaginatorComponent, LooseCurrencyPipe]
})
export class BasiqCategoryDetailsTableComponent implements OnInit, OnChanges {
  @Input({ required: false }) data: BasiqTransaction[] = [];
  @Output() checkbox: EventEmitter<{ [id: string]: boolean }> = new EventEmitter();
  columnsToDisplay: string[] = ['date', 'description', 'credit', 'debit', 'action'];
  dataSource: BasiqCategoryDetailsTableData[] = [];
  displayedData: BasiqCategoryDetailsTableData[] = [];
  // transactionCheckbox: { [id: string]: boolean } = {};

  limit!: number;
  offset!: number;
  total!: number;
  actionButtonClicks = 0;
  constructor() { }

  ngOnInit(): void {
    this.initPageData();
    // this.initTransactionCheckbox();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initPageData();
    // this.initTransactionCheckbox();
  }

  initPageData(): void {
    this.dataSource = [];
    this.data.forEach((d: BasiqTransaction) => {
      let row: BasiqCategoryDetailsTableData = {
        date: d.postDate ? moment(d.postDate).format('DD/MM/YYYY') : 'Pending',
        description: d.description,
        debit: d.direction == 'debit' ? Number(d.amount) : 0,
        credit: d.direction == 'credit' ? Number(d.amount) : 0,
        id: d.id,
      };
      this.dataSource.push(row);
    })

    this.total = this.dataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  // initTransactionCheckbox() {
  //   for (const d of this.data) {
  //     this.transactionCheckbox[d.id] = true;
  //   }
  // }

  // onToggleCheckbox(id: number) {
  //   this.transactionCheckbox[id] = !this.transactionCheckbox[id];
  //   this.checkbox.emit(this.transactionCheckbox);
  // }

  // clickAll() {
  //   for (const key of Object.keys(this.transactionCheckbox)) {
  //     this.transactionCheckbox[Number(key)] = !(this.actionButtonClicks % 2);
  //   }
  //   this.checkbox.emit(this.transactionCheckbox);
  //   this.actionButtonClicks++;
  // }

  updateDisplayedData() {
    this.displayedData = this.dataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onSort(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'date': return 'Date';
      case 'description': return 'Description';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'action': return '';
      default: return column;
    }
  }

  displayColumnValue(element: BasiqTransaction, column: string): string {
    console.log('element', element)
    switch (column) {
      case 'date': return element.postDate;
      case 'description': return element.description;
      case 'debit': return element.direction == 'debit' ? element.amount : '';
      case 'credit': return element.direction == 'credit' ? element.amount : '';
      case 'action': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needAlignRight(column: string) {
    return ['debit', 'credit', 'action'].includes(column);
  }
}
