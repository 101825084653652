import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AccountFilter,
  AccountMonthlyBalance,
  AccountSummary,
  BankStatementAccount,
  BankStatementAnalysisCategoryByBankId,
  BankStatementsAnalysisCategory,
  BankStatementsAnalysisTransactionDetails,
  BankStatementTransaction,
  BankTransactionsByCategory,
  CategoryTransactionCheckbox,
  DayEndBalanceSummary,
  DebitCreditSummary,
} from '@portal-workspace/grow-shared-library';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApplicationDialogService, PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { MonthlyDebitsCreditsChartComponent } from './monthly-debits-credits-chart.component';
import { CategoryDetailsTableComponent } from './category-details-table.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { DayEndBalanceChartComponent } from './day-end-balance-chart.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { loadingFor } from '@ngneat/loadoff';
 
interface CategoriesTableData {
  category: string;
  amountcredit: number;
  numcredit: number;
  amountdebit: number;
  numdebit: number;
  transactions: BankStatementTransaction[];
}

class InternalDataSource extends DataSource<CategoriesTableData> {

  subject = new BehaviorSubject<CategoriesTableData[]>([]);

  connect(): Observable<CategoriesTableData[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(data: CategoriesTableData[]) {
    this.subject.next(data);
  }
}

@Component({
    selector: 'categories-table',
    templateUrl: './categories-table.component.html',
    styleUrls: ['./categories-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [FlexModule, MatCheckboxModule, AsyncPipe, FormsModule, MatButtonModule, ReactiveFormsModule, DayEndBalanceChartComponent, MatTableModule, NgClass, ExtendedModule, CategoryDetailsTableComponent, MonthlyDebitsCreditsChartComponent, LooseCurrencyPipe]
})
export class CategoriesTableComponent implements OnInit, OnChanges {
  @Input({ required: false }) data: BankTransactionsByCategory[] = [];
  @Input({ required: false }) accounts: BankStatementAccount[] = [];
  @Input({ required: false }) selectedAccounts: string[] = [];
  dataSource = new InternalDataSource();
  columnsToDisplay: string[] = ['icon', 'category', 'amountcredit', 'numcredit', 'amountdebit', 'numdebit'];
  expandedElement!: CategoriesTableData | null;
  accountFilter: AccountFilter = {};
  dayEndBalanceChartData: DayEndBalanceSummary[] = [];
  categoryTransactionCheckbox: CategoryTransactionCheckbox = {};
  isData!: boolean;
  selectedDebitCredit!: AccountMonthlyBalance;
  tableData: CategoriesTableData[] = [];
  accountSelectionChanges: boolean = true;
  firstTimeLoadData: boolean = true;
  loader = loadingFor('tableLoading');
  singleAccount = false;

  constructor(
    private applicationDialogService: ApplicationDialogService,
    ) {}

  ngOnInit(): void {

    this.singleAccount = this.accounts.length === 1;
    this.dataSource.update([]);
    this.initAccountFilter();
    if (this.singleAccount) {
      this.mapToTableData();
      this.getDayEndBalance(this.accounts.map(account => account.id));
      // this.initCategoryTransactionCheckbox();
      this.updateSelectedDebitCredit();
    }

    if (this.selectedAccounts.length) {
      this.reloadChartAndTable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initAccountFilter();
    if (this.singleAccount) {
      this.mapToTableData();
      // this.initCategoryTransactionCheckbox();
      this.updateSelectedDebitCredit();
    }

    if (changes['selectedAccounts'] || changes['data']) {
      this.reloadChartAndTable();
    }
  }

  // Map this.data to CategoriesTableData
  mapToTableData() {
    if (this.data && this.data.length) {
      const _tableData = this.data.map((category: BankTransactionsByCategory) => {
        return {
          category: category.category,
          amountcredit: category.summary.totalcredit,
          numcredit: category.summary.numcredit,
          amountdebit: category.summary.totaldebit,
          numdebit: category.summary.numdebit,
          transactions: category.transactions
        }
      })
      this.tableData = _tableData;
      this.dataSource.update([]);
      this.dataSource.update(_tableData);
    }
    else {
      this.dataSource.update([]);
    }
  }

  initAccountFilter() {
    this.accounts.forEach((account: BankStatementAccount) => {
      this.accountFilter[account.id] = new FormControl<boolean>(this.singleAccount);
    })

    if (this.selectedAccounts.length) {
      this.accounts.forEach((account: BankStatementAccount) => {
        this.accountFilter[account.id].setValue(this.selectedAccounts.includes(account.id));
      })
    }
  }

  initCategoryTransactionCheckbox() {
    for (let i = 0; i < this.data.length; i++) {
      this.categoryTransactionCheckbox[i] = {};
      for (const d of this.tableData[i].transactions) {
        this.categoryTransactionCheckbox[i][d.id] = true;
      }
    }
  }

  getDayEndBalance(accountId: string[]) {
    this.dayEndBalanceChartData = []
    if (this.accounts.length && this.accounts[0].dayEndBalances) {
      for (let i = 0; i < this.accounts[0].dayEndBalances.length; i++) {
        let sum = 0;
        for (const accSummary of this.accounts) {
          if (accountId.includes(accSummary.id)) {
            sum += Number(accSummary.dayEndBalances?.[i]?.balance ?? 0);
          }
        }
        this.dayEndBalanceChartData.push({
          date: this.accounts[0].dayEndBalances[i].date,
          balance: String(sum)
        })
      }
    }
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'category': return 'Category';
      case 'numcredit': return '# Credits';
      case 'amountcredit': return '$ Credits';
      case 'numdebit': return '# Debits';
      case 'amountdebit': return '$ Debits';
      case 'icon': return '';
      default: return column;
    }
  }

  needCurrencyPipe(column: string) {
    return ['amountdebit', 'amountcredit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['numcredit', 'amountcredit', 'numdebit', 'amountdebit'].includes(column);
  }

  filterChangeHandler() {
    this.accountSelectionChanges = true;
    this.firstTimeLoadData = false;
  }

  onAccountSelect() {
    this.selectedAccounts = this.accounts.filter(account => this.accountFilter[account.id].value).map(account => account.id);
    this.reloadChartAndTable();
  }

  reloadChartAndTable() {
    // const ref = this.applicationDialogService.openProgressSpinnerDialog();
    // setTimeout(() => {
    //   ref.close();
    // }, 3000);
    const accountIds = Object.keys(this.accountFilter);
    const filteredBankIds: string[] = [];
    for (const accountId of accountIds) {
      const checked = this.accountFilter[accountId].value;
      if (checked) {
        filteredBankIds.push(accountId)
      }
    }

    // const filteredSummary = filteredBankIds.map(bankId => this.accounts.filter(account => account.id == bankId).flatMap(a => a.transactionsByCategory));
    // const filteredData: CategoriesTableData[] = [];

    // for (let i = 0; i < this.data.length; i++) {
    //   const amountcredit = filteredSummary.reduce((partial: number, summary: BankTransactionsByCategory[]) => partial + summary[i].summary.totalcredit, 0);
    //   const amountdebit = filteredSummary.reduce((partial: number, summary: BankTransactionsByCategory[]) => partial + summary[i].summary.totaldebit, 0);
    //   const numcredit = filteredSummary.reduce((partial: number, summary: BankTransactionsByCategory[]) => partial + summary[i].summary.numcredit, 0);
    //   const numdebit = filteredSummary.reduce((partial: number, summary: BankTransactionsByCategory[]) => partial + summary[i].summary.numdebit, 0);
    //   const details = filteredSummary.reduce(
    //     (partial: BankStatementTransaction[], summary: BankTransactionsByCategory[]) =>
    //       [...partial, ...summary[i].transactions], []
    //   );
    //   const avgcredit = numcredit ? Number((amountcredit / numcredit).toFixed(2)) : 0;
    //   const avgdebit = numdebit ? Number((amountdebit / numdebit).toFixed(2)) : 0;
    //   const category = filteredSummary?.length ? filteredSummary[0][i].category : '';
    //   filteredData.push({
    //     amountcredit,
    //     amountdebit,
    //     category,
    //     numcredit,
    //     numdebit,
    //     transactions: details,
    //   })
    // }

    // this.dataSource.update(filteredData);
    // this.tableData = filteredData;
    if (this.dataSource.subject.value.length == 0) {
      this.isData = true
    }else{
      this.isData = false
    }

    this.getDayEndBalance(filteredBankIds);
    // this.initCategoryTransactionCheckbox();
    this.updateSelectedDebitCredit();

    this.accountSelectionChanges = false;
  }

  showFilter() {
    return this.accounts && this.accounts.length && this.accounts.length > 1 &&
      (this.accounts[0]?.id !== undefined || this.accounts[0]?.id !== null);

// this code works good in production but not on test.
// If anything goes wrong we can uncomment the below code which works good on test as well as production.

    // return this.accountSummary && this.accountSummary.length && this.accountSummary.length > 1 &&
    // (this.accountSummary[0].hasOwnProperty('BankID'));
  }

  handleCheckboxChange(checkbox: { [id: number]: boolean }, index: number) {
    this.categoryTransactionCheckbox[index] = checkbox;
    this.updateSelectedDebitCredit();
  }

  updateSelectedDebitCredit() {
    const debitCredit: AccountMonthlyBalance = {
      averagecredit: 0,
      averagedebit: 0,
      enddate: '',
      enddatecredit: '',
      enddatedebit: '',
      monthlycredit: {},
      monthlydebit: {},
      numcredit: 0,
      numdebit: 0,
      startdate: '',
      startdatecredit: '',
      startdatedebit: '',
      totalcredit: 0,
      totaldebit: 0,
    }

    const _selectedAcc = this.accounts.filter(account => this.selectedAccounts.includes(account.id));

    console.log('selected account', _selectedAcc);

    _selectedAcc.forEach(account => {
      const balance = account.monthlyBalance ? { ...account.monthlyBalance } : null;
      console.log('balance for selected accounts', balance);
      if (!balance) return;

      debitCredit.totalcredit += balance.totalcredit;
      debitCredit.totaldebit += balance.totaldebit;
      debitCredit.numcredit += balance.numcredit;
      debitCredit.numdebit += balance.numdebit;

      if (!debitCredit.startdate || balance.startdate < debitCredit.startdate) {
        debitCredit.startdate = balance.startdate;
      }
      if (!debitCredit.enddate || balance.enddate > debitCredit.enddate) {
        debitCredit.enddate = balance.enddate;
      }
      if (!debitCredit.startdatecredit || balance.startdatecredit < debitCredit.startdatecredit) {
        debitCredit.startdatecredit = balance.startdatecredit;
      }
      if (!debitCredit.enddatecredit || balance.enddatecredit > debitCredit.enddatecredit) {
        debitCredit.enddatecredit = balance.enddatecredit;
      }
      if (!debitCredit.startdatedebit || balance.startdatedebit < debitCredit.startdatedebit) {
        debitCredit.startdatedebit = balance.startdatedebit;
      }
      if (!debitCredit.enddatedebit || balance.enddatedebit > debitCredit.enddatedebit) {
        debitCredit.enddatedebit = balance.enddatedebit;
      }

      // Combine monthly credit and debit
      Object.entries(balance.monthlycredit).forEach(([month, amount]) => {
        debitCredit.monthlycredit[month] = (debitCredit.monthlycredit[month] || 0) + amount;
      });

      Object.entries(balance.monthlydebit).forEach(([month, amount]) => {
        debitCredit.monthlydebit[month] = (debitCredit.monthlydebit[month] || 0) + amount;
      });
    });

    debitCredit.averagecredit = debitCredit.averagecredit ?? 0
    debitCredit.averagedebit = debitCredit.averagedebit ?? 0;

    console.log('debitCredit', debitCredit);

    this.selectedDebitCredit = debitCredit;
  }
}
