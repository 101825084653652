<div class="digital-id-individual-verification-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3 text-align-center">Basiq Bankstatement</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      @for (alert of alerts; track alert) {
      <message-box title="Alert" type="danger"> {{alert}} </message-box>
      } @for (message of messages; track message) {
      <message-box title="Info" type="info"> {{message}} </message-box>
      }
    </div>
  </div>

  @if (authLink) {
    <button mat-stroked-button (click)="linkBankAccount()" class="w-lt-md-100">
      Link Bank Accounts
    </button>
  } 
</div>
