<div class="pismo-edit-account-dialog m-4">
  <h6 mat-dialog-title>{{title}}</h6>
  <mat-dialog-content class="pt-2">
    <div class="row">
      <div class="col-12">
        @if (errorMessage) {
          <mat-error class="matError">
            {{errorMessage}}
          </mat-error>
        }
      </div>
      <div class="col-12">
          <div class="checkbox-label">Select the 
          @for (status of alltagStatus; track status) {
            <mat-checkbox class="pl-1 pr-1"
                    color="primary"
                    (change)="onStatusSelectionChanged($event, status)"
                    [checked]="filtertagStatus.includes(status)"
            >{{status}}</mat-checkbox>
          }
          conditions that you would like reviewed.</div>
      </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="data.groupNames">
             <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{element.groupName}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">
                <mat-slide-toggle
                  color="primary"
                  (change)="toggleApplicantPrivacyConsent($event, element)"
                  [checked]="selectedGroupName.isSelected(element)">
                </mat-slide-toggle> 
                 
              </td>
            </ng-container>  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="col-12 mb-5">
          <span [matTooltip]="'Refresh Listing'" class="float-right refresh-button mdi mdi-refresh cursor-pointer" (click)="refresh()"></span>
        </div>
    </div>  -->
    <div class="row">
      <div class="col selected-chips">
        <mat-card appearance="outlined" class="card">
          <mat-card-content>
            <mat-chip-listbox #chipList aria-label="Tag selection">
              @for (tag of filterTag; track tag) {
                <mat-chip-option
                (removed)="remove(tag)">
                  {{tag.groupName}}
                  <button matChipRemove>
                    <span class="mdi mdi-close-circle-outline"></span>
                  </button>
                </mat-chip-option>
              }
            </mat-chip-listbox>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-12 mb-2 mt-1">
        <span [matTooltip]="'Refresh Listing'" class="float-right refresh-button mdi mdi-refresh cursor-pointer" (click)="refresh()"></span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Information</mat-label>
          <textarea matInput rows="5" wrap="hard" maxlength="7000" placeholder={{notesPlaceholder}} [formControl]="formControlApplicationNotes"></textarea>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave()">Submit</button>
    </div>
  </mat-dialog-actions>
</div>
