<div class="card" (click)="onClick()">
  <div class="card-body">
    <div class="application">
      @if (application && application.LoanAmount) {
        <div><h4>${{application.LoanAmount| number}}</h4></div>
      }
      @if (application && application.BrokerAppID) {
        <div class="small-medium-text broker-id">APP ID: {{application.BrokerAppID}}</div>
      }
    </div>
    @if (application && application.CompanyName) {
      <div class="mb-2 company-name" >{{application.CompanyName}}</div>
    }
    @if (application && application.StageName) {
      <div class="small-medium-text" >Application Stage: {{application.StageName ? application.StageName : 'Pending Submission'}}</div>
    }
    @if (application && application.Status) {
      <div class="small-medium-text mb-2" >Status : {{application.Status}}</div>
    }
    <div class="progress-bar">
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 1 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 2 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 3 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 4 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 5 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 6 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep >= 7 && applicationStep <= 8, 'danger-color': applicationStep === 9}"></div>
      <div class="bar" [ngClass]="{'active-color': applicationStep === 8, 'danger-color': applicationStep === 9}"></div>
    </div>
  </div>
</div>
