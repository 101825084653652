<div class="years-and-months-component" [formGroup]="formGroup">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>Time at address (years)</mat-label>
        <input matInput type="number" min="0" step="1" [formControl]="formControlYears" placeholder="Select year">
        @for (errorKey of errorKeys(formControlYears); track errorKey) {
          <mat-error>
            @if (formControlYears.touched && formControlYears.hasError(errorKey)) {
              {{errorMessage(formControlYears, errorKey)}}
            }
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-label>Time at address (months)</mat-label>
        <mat-select [formControl]="formControlMonths" placeholder="Select month">
          @for (monthOption of monthsOptions; track monthOption) {
            <mat-option [value]="monthOption">{{monthOption}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  @if (requirePrevAddress) {
    <div>
      <custom-address title="Previous address" [mark]="markObservable"  [formControl]="formControlPreviousAddress"></custom-address>
    </div>
  }
</div>
