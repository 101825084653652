<div class="bankfeeds-institutions-page"
  fxLayout="column"
  fxLayoutAlign="top center">
  <mat-card class="w-lt-md-100" [ngClass]="{'full-screen': fullWidth}">
    <mat-card-header class="separator-bottom">
      <mat-card-title class="mat-h3">Illion BankStatements - Institutions</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      @if (loader.institutions.inProgress$ | async) {
        <custom-content-loader></custom-content-loader>
      }
      @if (!(loader.institutions.inProgress$ | async)) {
        <div>
          <div class="row mb-2">
            <div class="col-lg-6 col-md-12">
              <span>Last update: {{now | looseDate: 'DD/MM/yyyy hh:mm:ss a'}}</span>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-6 col-md-12">
              <message-box type="info">
                Information is provided by <a href="https://bankstatement.com.au">bankstatement.com.au</a>.
                Dynamoney has no authority to fix or rectify existing issues.
              </message-box>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <input matInput name="search" [formControl]="formControlSearch" placeholder="Search ...">
              </mat-form-field>
            </div>
          </div>
          <table mat-table
            class="institutions-table w-100"
            [dataSource]="datasource"
            matSort
            (matSortChange)="onSort($event)"
            multiTemplateDataRows
            >
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let element">
                @if (isTableRowExpanded(element)) {
                  <span class="mdi mdi-chevron-up cursor-pointer"></span>
                }
                @if (!isTableRowExpanded(element)) {
                  <span class="mdi mdi-chevron-down cursor-pointer"></span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{element.name}}
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">
                {{element.type}}
              </td>
            </ng-container>
            <ng-container matColumnDef="connectionStatus">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Connection Status</th>
              <td mat-cell *matCellDef="let element">
            <span [ngClass]="{
              'status-healthy': element.connectionStatus == 'Healthy',
              'status-deprecated': element.connectionStatus == 'Deprecated',
              'status-degraded': element.connectionStatus == 'Degraded',
            }">
                  {{element.connectionStatus}}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedRowDetails">
              <td mat-cell
                *matCellDef="let element"
                [attr.colspan]="4">
                <div class="expandedRowDetailsContent"
                  [@detailExpand]="isTableRowExpanded(element) ? 'expanded' : 'collapsed'">
                  <table mat-table
                    class="institution-details-table w-100"
                       [dataSource]="[
                        {name: 'Name', value: element.name},
                        {name: 'Type', value: element.type},
                        {name: 'Connection Status', value: element.connectionStatus},
                        {name: 'Region', value: element.region},
                        {name: 'Is Business Bank', value: element.isBusinessBank},
                        {name: 'Estatement Available', value: element.isEStatementAvailable},
                        {name: 'Transaction Listing Available', value: element.isTransactionListingAvailable},
                     ]">
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>Name</th>
                      <td mat-cell *matCellDef="let prop">
                        {{prop.name}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="value">
                      <th mat-header-cell *matHeaderCellDef>Value</th>
                      <td mat-cell *matCellDef="let prop">
                        {{prop.value}}
                      </td>
                    </ng-container>
                  <!--tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr-->
                  <tr mat-row *matRowDef="let prop; columns: ['name', 'value']"></tr>
                </table>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row
            *matHeaderRowDef="columnsToDisplay; sticky: true">
          </tr>
          <tr mat-row
            class="institution-row cursor-pointer"
            (click)="onTableRowClicked(element)"
            *matRowDef="let element; columns: columnsToDisplay">
          </tr>
          <tr mat-row
            class="institution-details-row"
            [ngStyle]="{'display': isTableRowExpanded(element) ? null : 'none'}"
            *matRowDef="let element; columns: ['expandedRowDetails']">
          </tr>
        </table>
      </div>
    }
  </mat-card-content>
</mat-card>
</div>
