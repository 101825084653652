import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe } from "@angular/common";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  ApplicationDialogService,
  applicationToAbnGstAgeAboveThreshold,
  applicationToAdverseOnFile,
  applicationToAsset,
  applicationToAssetCondition, applicationToBalloonPayment,
  applicationToBalloonPaymentPrecentage,
  applicationToBalloonPaymentPrecentageNumber,
  applicationToBrokerage, applicationToBrokerageAmount,
  applicationToBrokerOriginationFee,
  applicationToBusinessSearchValue,
  applicationToCrediRateAdjustment,
  applicationToDepositAmount, applicationToDirectorScore, applicationToDocFee,
  applicationToDocFeeFinanced,
  applicationToEquifaxScoreAboveThreshold,
  applicationToFinanceType,
  applicationToHybrid,
  applicationToInvoiceAmount,
  applicationToLastUpdatedByUser,
  applicationToLoanAmount,
  applicationToLoanTerms,
  applicationToLoanTermsValue,
  applicationToPaymentFrequency,
  applicationToPreviousLoan,
  applicationToPropertyOwner,
  applicationToRateDiscount,
  applicationToTransactionValue,
  ASSET_FINANCE_OPTIONS_FILTER,
  AssetSelectionComponentSearchFn,
  createTwoDecimalInputMask,
  getUser, minMaxValidator,
  PortalHotToastService
} from "@portal-workspace/grow-ui-library";
import {DisableControlDirective} from '../../../directives/disable-control.directive';
import { AssetConditionComponent } from '../../asset-cat-and-type-component/asset-condition.component';
import { AssetSelectionComponent, AssetSelectionComponentEvent } from '../../asset-cat-and-type-component/asset-selection.component';
import { CurrencyInputComponent } from '../../currency-selection-component/currency-input.component';
import { BalloonPaymentComponent } from '../../balloon-payment-component/balloon-payment.component';
import { BrokerageSelectionComponent } from '../../brokerage-component/brokerage-selection.component';
import { FinanceTypeSelectionComponent } from '../../finance-type-component/finance-type-selection.component';
import { LoanTermsSelectionComponent } from '../../loan-terms-selection-component/loan-terms-selection.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { PaymentFrequencySelectionComponent } from '../../payment-frequency-selection-component/payment-frequency-selection.component';
import { TransactionTypeSelectionComponent } from '../../transaction-type-selection-component/transaction-type-selection.component';
import { YesNoComponent } from '../../yes-no-component/yes-no.component';
import { PercentageInputComponent } from '../../percentage-input-component/percentage-input.component';
import {MatInputModule} from "@angular/material/input";
import {InputMaskModule} from "@ngneat/input-mask";
import {LooseCurrencyPipe} from '../../../pipes/loose-currency.pipe';
import {PercentagePipe} from '../../../pipes/percentage.pipe';
import {
  AppCalculator,
  Application,
  AssetConditionValue,
  AssetFinanceApplication,
  AssetSelectionComponentValue,
  BalloonPaymentValue,
  BalloonPaymentValueOptions,
  booleanToYesNo,
  BrokerageSelectionValue,
  BusinessSearchResultValue,
  BusinessSearchValue,
  calculateAssetFinanceEstimation,
  CalculateAssetFinanceEstmationResult,
  CurrencyInputValue,
  FinanceTypeValue,
  getBalloonPayment,
  getBrokerage,
  getBrokerageAmount,
  getDocFeeFinanced,
  getInterestRate,
  GetRateCardDetailsFn,
  getRepaymentFrequency,
  isNotAdminOrCreditOrSalesAMInternalUser,
  isAdmin,
  isCreditUser,
  LoanTermValue,
  minDepositForAssetFinance,
  PaymentFrequencyValue,
  RatecardDetails,
  SaveApplicationData,
  SearchCompanyByABNResult,
  TermRate,
  TotalPaymentBreakupDialogData,
  TransactionValue,
  UpdateApplicationData,
  UpdateApplicationFn,
  UpdateApplicationSfFn,
  YesNoValue,
  isAdminOrCreditUserOrSalesAM,
  isInternalUser,
  PrivateSaleType,
  getRepayment,
  GetContractStatusFn,
  isAdminOrCreditUser,
  isAdminOrCreditUserOrSalesAmOrSalesBDM,
  CalculateConsumerAssetFinanceEstimationResult,
  FinanceTypeValueOptions,
  PaymentFrequencyValueOptions,
  calculateConsumerAssetFinanceEstimation,
  minDepositForConsumerAssetFinance,
  getApr,
  BrokerageSelectionType,
  constants,
  isAssetVehicles,
  isAssetNewCars,
  SliderComponentValue,
  OriginatorBusiness,
  GetOriginatorBusinessByIdFn,
  LoanTermType,
  LoanTermsSelectionWithInputValue,
  BalloonPaymentType,
} from '@portal-workspace/grow-shared-library';
import {loadingFor} from "@ngneat/loadoff";
import {UntilDestroy} from "@ngneat/until-destroy";
import {combineLatest, Subject, Subscription} from "rxjs";
import {pairwise, tap} from "rxjs/operators";
import _ from "lodash";
import moment, {Moment} from "moment/moment";
import numeral from "numeral";
import { MatTableModule} from "@angular/material/table";
import { MatCardModule } from '@angular/material/card';
import { ConsumerLoanTermsSelectionComponent } from '../../loan-terms-selection-component/consumer-loan-terms-selection.component';
import {MarkDirective} from '../../../directives/mark-as-dirty.directive';
import { LoanTermsSelectionWithInputComponent } from '../../loan-terms-selection-component/loan-terms-selection-with-input.component';
import { SliderComponent } from '../../slider-component/slider.component';

export type ConsumerPricingComponentEvent = ConsumerPricingComponentSavedEvent;

export interface ConsumerPricingComponentSavedEvent {
  type: 'consumer-pricing-saved',
  rst: CalculateConsumerAssetFinanceEstimationResult,
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'consumer-pricing',
  templateUrl: './consumer-pricing.component.html',
  styleUrls: ['./consumer-pricing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatFormFieldModule,
    AsyncPipe,
    JsonPipe,
    AssetConditionComponent,
    AssetSelectionComponent,
    BalloonPaymentComponent,
    BrokerageSelectionComponent,
    CurrencyInputComponent,
    FinanceTypeSelectionComponent,
    LoanTermsSelectionComponent,
    PaymentFrequencySelectionComponent,
    TransactionTypeSelectionComponent,
    YesNoComponent,
    MessageBoxComponent,
    DisableControlDirective,
    MatInputModule,
    InputMaskModule,
    MatTableModule,
    MatCardModule,
    PercentageInputComponent,
    LooseCurrencyPipe,
    ConsumerLoanTermsSelectionComponent,
    PercentagePipe,
    MarkDirective,
    LoanTermsSelectionWithInputComponent,
    SliderComponent,
  ]
})
export class ConsumerPricingComponent implements OnInit, AfterViewInit {

  markSubject = new Subject<boolean>();

  MIN_ORIGINATION_FEE = 0;
  MAX_ORIGINATION_FEE = 1200;

  @Input({required: true}) assetSearchFn!: AssetSelectionComponentSearchFn;
  @Input({required: true}) getRateCardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) application!: Application;
  @Input({required:true}) currentRstValue!: TotalPaymentBreakupDialogData;
  @Output() events = new EventEmitter<ConsumerPricingComponentEvent>();

  @ViewChild('balloonPaymentComponent') balloonPaymentComponent!: BalloonPaymentComponent;

  dataSource: (
    {name: string, highlight?: (value: string | null | undefined) => boolean, type: 'text', value: string | null | undefined} |
    {name: string, highlight?: (value: number | string | null | undefined) => boolean, type: 'currency', value: number | string | null | undefined} |
    {name: string, highlight?: (value: number | string | null | undefined)=>boolean, type: 'percentage', value: number | string | null | undefined}
  )[] = [];

  currentTemplate: TemplateRef<any> | null = null;

  @ViewChild('readMode') readModeTemplate!: TemplateRef<any>;
  @ViewChild('editMode') editModeTemplate!: TemplateRef<any>;

  subscriptions: Subscription[] = [];

  formBuilder: FormBuilder = inject(FormBuilder);
  applicationDialogService: ApplicationDialogService = inject(ApplicationDialogService);
  toastService: PortalHotToastService = inject(PortalHotToastService);

  loader = loadingFor('saveRate');

  MIN_CREDIT_RATE = -1;
  MAX_CREDIT_RATE = 5;
  MIN_LOAN_AMOUNT = 0.01;
  ASSET_FINANCE_OPTIONS_FILTER = ASSET_FINANCE_OPTIONS_FILTER;

  user = getUser();
  isInternalUser = isInternalUser(this.user)
  isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.user);
  isAdminOrCreditUserOrSalesAMOrSalesBDM = isAdminOrCreditUserOrSalesAmOrSalesBDM(this.user);
  isNotAdminOrCreditUser = !isAdminOrCreditUser(this.user);
  isAdmin = isAdmin(this.user);

  isContractPending: boolean = true; // if a contract is pending for this application
  isApplicationStagePostQa = true;
  lastEditedByUser ?: string | null

  createTwoDecimalInputMask = createTwoDecimalInputMask();

  assetSelectionReady = false;

  maxRateDiscount = 2;

  rateCard: RatecardDetails | null = null;
  AmountFinance!: number;
  data: Partial<UpdateApplicationData> | null = null;  // available after chart popups (after onGenerateQuote())
  terms: TermRate | null = null;              // available after chart popups (after onGenerateQuote())
  calculator: AppCalculator | null = null;    // available after chart popups (after onGenerateQuote())
  rst: CalculateAssetFinanceEstmationResult | null = null;                            // calculate estimation result (available after chart popups (after onGenerateQuote())
  brokeragePercentage!: number;
  originatorBusiness!: OriginatorBusiness;

  step2Repayment = 0;
  step2EstimatedDrawdownDate: Moment = moment();
  step2AssetCategoryIndexFilter = ['139'];//['137', '106'];
  step2MinOriginationFee = this.MIN_ORIGINATION_FEE;
  step2MaxOriginationFee = this.MAX_ORIGINATION_FEE;
  step2MinBrokerageAmount: number = 0;
  step2MaxBrokerageAmount: number = 999999;
  step2InvoiceAmountMin: number = this.MIN_LOAN_AMOUNT;
  step2InvoiceAmountMax: number | null = null;
  step2DepositMin: number | null = null;
  step2DepositMax: number | null = null;
  step2MaxBalloon: number  = 0;
  step2ShowHybridField = false;
  // formControlStep2Broker!: FormControl<AggregatorSearchComponentValue>;
  formControlStep2InvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlStep2DepositAmount!: FormControl<CurrencyInputValue>;
  formControlStep2LoanAmount!: FormControl<CurrencyInputValue>;
  formControlStep2Asset!: FormControl<AssetSelectionComponentValue>;
  formControlStep2LoanTerms!: FormControl<LoanTermsSelectionWithInputValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2DocFeeFinanced!: FormControl<YesNoValue>;
  formControlStep2FinanceType!: FormControl<FinanceTypeValue>;
  formControlStep2BalloonPayment!: FormControl<CurrencyInputValue>;
  formControlStep2BrokerOriginationFee!: FormControl<CurrencyInputValue>;
  formControlStep2BrokerageAmount!: FormControl<CurrencyInputValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlStep2AssetCondition!: FormControl<AssetConditionValue>;
  formControlStep2TransactionType!: FormControl<TransactionValue>;
  formControlStep2Hybrid!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formControlCreditRateAdjustment!: FormControl<number | null>;
  formControlStep2PpsrFee!: FormControl<CurrencyInputValue>;
  formControlStep2RateDiscount!: FormControl<SliderComponentValue>;
  formGroupStep2!: FormGroup<{
    // broker: FormControl<AggregatorSearchComponentValue>,
    invoiceAmount: FormControl<CurrencyInputValue>,
    depositAmount: FormControl<CurrencyInputValue>,
    loanAmount: FormControl<CurrencyInputValue>,
    asset: FormControl<AssetSelectionComponentValue>,
    loanTerms: FormControl<LoanTermsSelectionWithInputValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    docFeeFinanced: FormControl<YesNoValue>,
    financeType: FormControl<FinanceTypeValue>,
    balloonPayment: FormControl<CurrencyInputValue>,
    originationFee: FormControl<CurrencyInputValue>,
    brokerageAmount: FormControl<CurrencyInputValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    assetCondition: FormControl<AssetConditionValue>,
    equifaxScoreAboveThreshold: FormControl<YesNoValue>,
    hybrid: FormControl<YesNoValue>,
    transactionType: FormControl<TransactionValue>,
    previousLoan: FormControl<YesNoValue>,
    creditRateAdjustment: FormControl<number | null>,
    rateDiscount: FormControl<SliderComponentValue>
  }>;

  ngOnInit() {
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(0, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Asset = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control( { type: 'Monthly', name: 'Monthly'}, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2FinanceType = this.formBuilder.control(FinanceTypeValueOptions[0]);
    this.formControlStep2BalloonPayment = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerOriginationFee = this.formBuilder.control(295);
    this.formControlStep2BrokerageAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AssetCondition = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2TransactionType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Hybrid = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);
    this.formControlCreditRateAdjustment = this.formBuilder.control(0.0, [Validators.required]);
    this.formControlStep2RateDiscount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PpsrFee = this.formBuilder.control(this.rateCard?.PpsrFee ?? 0);

    this.formGroupStep2 = this.formBuilder.group({
      // broker: this.formControlStep2Broker,
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      asset: this.formControlStep2Asset,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
      financeType: this.formControlStep2FinanceType,
      balloonPayment: this.formControlStep2BalloonPayment,
      originationFee: this.formControlStep2BrokerOriginationFee,
      brokerageAmount: this.formControlStep2BrokerageAmount,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      transactionType: this.formControlStep2TransactionType,
      assetCondition: this.formControlStep2AssetCondition,
      equifaxScoreAboveThreshold: this.formControlStep2EquifaxScoreAboveThreshold,
      hybrid: this.formControlStep2Hybrid,
      previousLoan: this.formControlStep2PreviousLoan,
      creditRateAdjustment: this.formControlCreditRateAdjustment,
      rateDiscount: this.formControlStep2RateDiscount,
    });

    // populate
    this.populate();

    this.createReadonlyTableDataSource();


    if (this.isNotAdminOrCreditUser) { // not credit or admin
      // cannot change risk profile
      this.formControlStep2PropertyOwner.disable();
      this.formControlStep2AdverseOnFile.disable();
      this.formControlStep2EquifaxScoreAboveThreshold.disable();
      this.formControlStep2PreviousLoan.disable();

      // cannot change ABN GST Age
      // this.formControlAbnGstAgeAboveThreshold.disable();

      // restriction on invoice amount
      // const invoiceAmount = applicationToInvoiceAmount(this.application) ?? 0;
      // const adjustmentToInvoiceAmount = (invoiceAmount * 0.1);
      // const maxInvoiceAmount = _.round(invoiceAmount + adjustmentToInvoiceAmount);
      // const originalLoanAmount = applicationToLoanAmount(this.application) ?? 0;
      // const deposit = this.formControlStep2DepositAmount.value ?? 0;
      // // this.step2InvoiceAmountMax = maxInvoiceAmount;
      // this.step2InvoiceAmountMax = _.round(1.1 * originalLoanAmount + deposit);

      // deposit restriction
      this.subscriptions.push(this.formControlStep2InvoiceAmount.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlStep2Asset.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
        })
      ).subscribe());

    } else { // credit or admin
      // no deposit restriction
      this.step2DepositMin = 0;
      this.step2DepositMax = applicationToInvoiceAmount(this.application);
      if (this.isAdmin) {
        this.MIN_CREDIT_RATE = -10000;
        this.MAX_CREDIT_RATE = 10000;
      } else {
        this.MIN_CREDIT_RATE = -5;
        this.MAX_CREDIT_RATE = 5;
      }
    }

    this.subscriptions.push(combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
      this.formControlStep2FinanceType.valueChanges,
    ]).pipe(
      tap(r => {
        const invoiceAmount = r[0] ?? 0;
        const depositAmount = r[1] ?? 0;
        const financeType = r[2];
        const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
        const deposit = financeType?.type === 'rental' ? depositAmountExGST : depositAmount;
        const loanAmount = parseFloat((invoiceAmount - deposit > 0) ? (invoiceAmount - deposit).toFixed(2) : '0');
        this.formControlStep2LoanAmount.setValue(loanAmount);
        this.step2MaxBrokerageAmount = Math.round(loanAmount * 4) / 100;
      })
    ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe());

    this.subscriptions.push(this.getRateCardDetailsFn(this.application.UserId, 'Consumer').pipe(
      tap(rateCardDetails => {
        this.rateCard = rateCardDetails;
        this.formControlStep2PpsrFee.setValue(this.rateCard?.PpsrFee ?? 0);
        this.maxRateDiscount = this.rateCard?.MaxBrokerRateDiscount ?? 2;
      })
    ).subscribe());

    this.subscriptions.push(this.formControlStep2Asset.valueChanges.pipe(
      tap(r => {
        this.step2UpdateHybridField();
        this.step2UpdateAssetCondition();
      })
    ).subscribe());

    // get contract status for application
    this.subscriptions.push(this.getContractStatusFn(this.application.ApplicationId).pipe(
      tap(r => {
        const {contractPending} = r.signers.reduce((acc, signer) => {
          const contractPending = ["created", "sent", "delivered", "signed", "completed", "faxpending"].includes(signer.status);
          acc.contractPending = acc.contractPending || contractPending;
          return acc;
        }, { contractPending: false});
        this.isContractPending = contractPending;
      })
    ).subscribe());

    this.isApplicationStagePostQa = ['QA', 'Settlement', 'Settlement Pending', 'Closed Lost', 'Closed Lost'].includes(this.application.InteflowStatusStageName ?? '');

    this.subscriptions.push(
      this.getOriginatorBusinessByIdFn(this.application.CompanyId).subscribe(originatorBusiness => {
        if (originatorBusiness) {
          this.originatorBusiness = originatorBusiness;
        }
      })
    )

    // balloon payment changes for rental application
    this.subscriptions.push(
      this.formControlStep2InvoiceAmount.valueChanges.pipe(
      tap((i: CurrencyInputValue) => {
        const invoiceAmount = i ?? 0;
        this.step2MaxBalloon = _.round(0.5 * invoiceAmount, 2);
      })
    ).subscribe())
  }

  populate() {
    this.formControlStep2InvoiceAmount.setValue(applicationToInvoiceAmount(this.application));
    this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.application));
    this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.application));
    this.formControlStep2Asset.setValue(applicationToAsset(this.application));
    this.formControlStep2LoanTerms.setValue(applicationToLoanTermsValue(this.application));
    // this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application));
    this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application) ??
    PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
    this.formControlStep2DocFeeFinanced.setValue(applicationToDocFeeFinanced(this.application));
    this.formControlStep2FinanceType.setValue(applicationToFinanceType(this.application));
    this.formControlStep2BalloonPayment.setValue(applicationToBalloonPayment(this.application));
    this.formControlStep2BrokerOriginationFee.setValue(applicationToBrokerOriginationFee(this.application));
    this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.application));
    this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.application));
    this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.application));
    this.formControlStep2TransactionType.setValue(applicationToTransactionValue(this.application));
    this.formControlStep2AssetCondition.setValue(applicationToAssetCondition(this.application));
    this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.application));
    this.formControlCreditRateAdjustment.setValue(applicationToCrediRateAdjustment(this.application));
    this.formControlStep2Hybrid.setValue(applicationToHybrid(this.application));
    this.formControlStep2RateDiscount.setValue(applicationToRateDiscount(this.application));

    const calculator = new AppCalculator();
    const brokerageAmount = applicationToBrokerageAmount(this.application) == null ?
    calculator.calculateBrokerageAmount(applicationToLoanAmount(this.application) ?? 0, getBrokerage(this.application)):
    applicationToBrokerageAmount(this.application);
    this.formControlStep2BrokerageAmount.setValue(brokerageAmount);
    this.brokeragePercentage = getBrokerage(this.application);
  }

  createReadonlyTableDataSource() {
    this.lastEditedByUser = applicationToLastUpdatedByUser(this.application)?.name
    const calculator = new AppCalculator();

    const asset = applicationToAsset(this.application);
    this.dataSource = [
      { name: 'Invoice Amount ($)', type: 'currency', value: applicationToInvoiceAmount(this.application)},
      { name: 'Asset Type', type: 'text', value: asset?.type?.value},
      { name: 'Asset Make', type: 'text', value: asset?.make ?? 'N/A' },
      { name: 'Asset Year', type: 'text', value: asset?.year },
      { name: 'Asset Description', type: 'text',  value: asset?.description},
      { name: 'Asset Condition', type: 'text', value: applicationToAssetCondition(this.application)?.type},
      { name: 'Loan Terms', type: 'text', value: `${applicationToLoanTerms(this.application)?.type ?? ''} months`},
      { name: 'Payment Frequency', type: 'text', value: applicationToPaymentFrequency(this.application)?.type },
      { name: 'Repayment Amount ($)', type: 'currency', value: getRepayment(this.application)},
      { name: 'Property Owner', type: 'text', value: booleanToYesNo(applicationToPropertyOwner(this.application))},
      { name: 'Adverse on file', type: 'text', value: booleanToYesNo(applicationToAdverseOnFile(this.application))},
      { name: 'Director Equifax Score > 600', type: 'text', value: booleanToYesNo(applicationToEquifaxScoreAboveThreshold(this.application))},
      { name: 'Deposit Amount ($)', type: 'currency', value: applicationToDepositAmount(this.application)},
      { name: 'Loan Amount $)', type: 'currency', value: applicationToLoanAmount(this.application)},
      { name: 'Interest Rate (%)', type: 'percentage', value: getApr(this.application)},

      { name: 'Doc Fee Financed', type: 'text', value: booleanToYesNo(applicationToDocFeeFinanced(this.application))},
      { name: 'Doc Fee ($)', type: 'currency', value: applicationToDocFee(this.application)},

      { name: 'Balloon Payment Due at maturity (%)', type: 'percentage', value: applicationToBalloonPaymentPrecentageNumber(this.application)},
      { name: 'Balloon Payment(Residual) ($)', type: 'currency', value: applicationToBalloonPayment(this.application)},

      { name: 'Credit Assistance Fee ($)', type: 'currency', value: applicationToBrokerOriginationFee(this.application)},
      { name: 'Brokerage ($)', type: 'currency', value:
        applicationToBrokerageAmount(this.application) == null ?
        calculator.calculateBrokerageAmount(applicationToLoanAmount(this.application) ?? 0, getBrokerage(this.application)):
        applicationToBrokerageAmount(this.application)
      },

      { name: 'Credit Rate Adjustment', highlight: (v) => Number(v) != 0, type: 'percentage', value: applicationToCrediRateAdjustment(this.application) },
    ]
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['application'];
    if (change) {
      this.createReadonlyTableDataSource();
    }
  }

  ngAfterViewInit() {
    this.subscriptions.push(combineLatest([
      this.formControlStep2Asset.valueChanges,
      this.formControlStep2LoanTerms.valueChanges
    ]).pipe(
      tap((r: [AssetSelectionComponentValue, LoanTermsSelectionWithInputValue]) => {
        const assetSelectionValue = r[0];
        const loanTermValue = r[1];
        this.step2UpdateBalloonPaymentValidation(assetSelectionValue, loanTermValue);
      })
    ).subscribe());
    if (this.application) {
      setTimeout(()=> {
        this.step2UpdateBalloonPaymentValidation(this.formControlStep2Asset.value, this.formControlStep2LoanTerms.value);
      }, 2000);
    }
    this.currentTemplate = this.readModeTemplate;
  }

  get matchBrokerageAmount() {
    return this.brokeragePercentage == _.round((this.formControlStep2BrokerageAmount.value ?? 0) * 100 / (this.formControlStep2LoanAmount.value ?? 0), 2);
  }

  step2UpdateBalloonPaymentValidation(assetSelectionValue: AssetSelectionComponentValue, loanTermValue: LoanTermsSelectionWithInputValue) {
    if (assetSelectionValue && loanTermValue && this.isNotAdminOrCreditUser) {
      const assetCatgory = assetSelectionValue.category.index;
      const assetType = assetSelectionValue.type.index;
      const loanTerm = loanTermValue;
      const invoiceAmount = this.formControlStep2InvoiceAmount.value ?? 0;
      const assetYear = parseInt(assetSelectionValue.year);
      const maxRv = new AppCalculator().getMaxRV(assetCatgory, loanTerm, assetYear, assetType);
      this.step2MaxBalloon = _.round(invoiceAmount * maxRv / 100, 2);
      // this.balloonPaymentComponent.setMaxRv(maxRv);
    }
  }

  step2UpdateHybridField() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    if (asset && asset?.type?.index === '1') {
      this.step2ShowHybridField = true;
    } else {
      this.step2ShowHybridField = false;
    }
  }

  step2UpdateAssetCondition() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const assetYear = parseInt(asset?.year ? asset.year : '0');
    const difference = moment().year() - assetYear - 1;
    if (difference > 0) {
      this.formControlStep2AssetCondition.setValue({type: 'Used', name: 'Used'});
    } else {
      this.formControlStep2AssetCondition.setValue({type: 'New', name: 'New / Demo' });
    }
  }

  switchMode() {
    if (this.currentTemplate == this.editModeTemplate) {
      this.currentTemplate = this.readModeTemplate;
    } else {
      this.populate();
      this.currentTemplate = this.editModeTemplate;
    }
  }


  createAbnGstAge(): number {
    //const abnGstAboveThreashold = this.formControlAbnGstAgeAboveThreshold.value;
    return 999;//abnGstAboveThreashold ? 999 : 1;
  }

  createApplicationData(){
    // NOTE: changes done here needs to be done in asset-finance.page.ts as well
    const asset = this.formControlStep2Asset.value;
    const loanTerm: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const invoiceAmount = this.formControlStep2InvoiceAmount.value;
    const deposit = this.formControlStep2DepositAmount.value;
    // const brokerageAmount = this.formControlStep2BrokerageAmount.value;
    // const brokeragePercentage = Math.round((brokerageAmount ?? 0) * 10000 / loanAmount) / 100;
    // const brokerage: BrokerageSelectionValue = {type: `${brokeragePercentage}` as BrokerageSelectionType, name: `${brokeragePercentage}%`};
    const paymentPeriod: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const docFeeFinanced = this.formControlStep2DocFeeFinanced.value;
    const docFee = this.terms?.docFee ?? undefined;
    const brokerOriginationFee = this.formControlStep2BrokerOriginationFee.value;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    // const rate = this.terms?.totalInterest ?? undefined;
    const balloonPayment = this.terms?.RV ?? 0;
    const balloonPercentage: number = invoiceAmount ? (balloonPayment * 100 / invoiceAmount) : 0;
    const assetCondition: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const repayment = this.calculator?.emiAmt ?? undefined;
    const transactionType: TransactionValue = this.formControlStep2TransactionType.value;
    const hybrid = this.step2ShowHybridField ? !!this.formControlStep2Hybrid.value : false;
    const rateDiscount = this.formControlStep2RateDiscount.value ?? 0;
    const privateSales: PrivateSaleType = transactionType ? (
      transactionType.type === 'Dealer Sale' ? 'No' :
        (transactionType.type === 'Private Sale' ? 'Yes' : transactionType.type)
    ) : 'No';
    const rate = this.terms?.totalInterest ? (this.terms?.totalInterest + rateDiscount - this.maxRateDiscount) : undefined;
    const brokerage = loanAmount ? (this.calculator?.commission ?? 0) * 100 / loanAmount : 0; // portion paid to the broker
    const brokerageAmount = this.calculator?.commission ?? 0;
    const oversCommission = this.calculator?.amountToUs ?? 0; // portion paid to us

    const apiBodyAppInfo: Partial<AssetFinanceApplication['AppInfo']> ={
      ...this.application.AppInfo,
      AssetCategory: asset?.category.index ?? undefined,
      AssetType: asset?.type.index ?? undefined,
    }

    let apiBodyAssetSpec: UpdateApplicationData['AssetSpec'] = {
      make: asset?.make ?? undefined,
      family: asset?.family ?? undefined,
      year: asset?.year ?? undefined,
      vehicle: asset?.model ?? undefined,
      description: asset?.description ?? undefined,
      truckGrossVehicleWeight: asset?.truckGrossVehicleWeight ?? undefined,
      OtherCar: asset?.OtherCar ?? false,
    };

    const newCarAssetType = constants.NewCarsAssetTypeIndex;
      // work out vehicle asset spec.
      let assetLvr = undefined;
      let vehSpec = {};
      if (asset) {
        if (isAssetVehicles(asset.category.index)) {
          if (isAssetNewCars(asset.category.index, asset.type.index)) {
            if(this.terms) {
              assetLvr = this.terms.LVR;
              vehSpec = asset.vehicle ?? {};
            }
          }
        }
      }

    if (newCarAssetType === asset?.type?.index) {
      if(this.terms) {
        assetLvr = this.terms.LVR;
        vehSpec = asset.vehicle ?? {};
      }
      apiBodyAssetSpec = {
        ...apiBodyAssetSpec,
        ...vehSpec,
        LVR: assetLvr ?? undefined,
      };
    }

    const abnGstAge = this.createAbnGstAge();
    const companyDetails = undefined;

    const apiBodyPricingDetails = {
      ...this.application.PricingDetails,
      LoanTerm: loanTerm ?? undefined,
      InvoiceAmount: (invoiceAmount != null ? (invoiceAmount) : undefined),
      Deposit: (deposit != null ? (deposit) : undefined),
      Brokerage: brokerage,
      BrokerageAmount: brokerageAmount,
      LoanAmount: loanAmount,
      AmountFinance: this.AmountFinance,
      PaymentPeriod: paymentPeriod ? paymentPeriod.name : undefined,
      DocFee: (docFee != null ? Number(docFee) : undefined) ,
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      BrokerOriginationFee: brokerOriginationFee ?? undefined,
      PropertyOwner: booleanToYesNo(propertyOwner),
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      Rate: rate,
      InterestRate: rate,
      BalloonPaymentPercentage: balloonPercentage,
      BalloonPayment: balloonPayment,
      Repayment: repayment,
      BankStatementSubmitted: 'No' as const,
      PrivateSale: privateSales,
      AssetCondition: assetCondition?.type ?? undefined,
      TransactionType: transactionType ? transactionType.type : undefined,
      APR: this.rst?.totalPaymentBreakupDialogData.paymentChartData.displayedInterest,
      // Brokerage: this.terms?.brokerage,
      CreditRateAdjustment: Number(this.formControlCreditRateAdjustment.value),
      Hybrid: booleanToYesNo(hybrid),
      RateDiscount: rateDiscount,
      OversCommission: oversCommission,
    }

    const apiBody: Partial<UpdateApplicationData> = {
      UpdateApplicationIgnoreStatus: true,
      AppInfo: apiBodyAppInfo,
      AssetSpec: apiBodyAssetSpec,
      PricingDetails: apiBodyPricingDetails as any,
      CompanyDetails: companyDetails as any,
    }
    return apiBody;
  }

  save() {
    if (this.rst) {
      this.subscriptions.push(this.updateApplicationSfFn(this.application.ApplicationId, this.data as UpdateApplicationData).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`Rate updated`),
        tap(r => {
          if(r.status){
            this.events.emit({
              type: "consumer-pricing-saved",
              rst: this.rst!,
            });
            this.currentTemplate = this.readModeTemplate;
          }
        })
      ).subscribe());
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: `Cannot saved without performing calculation first`,
      });
    }
  }

  /**
   * This is how it works :-
   *  - calculateEstimation() which will
   *     - create terms from app-calculator
   *     - create data for sending to API for application data update
   *  - open up a pricing comparison dialog
   *  - when quotation is accepted in the comparison dialog
   *      - update the doc fee (if there are no changes the doc fee same value is returned
   *      - recreate the application data for submission
   */
  onGenerateQuote() {
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');
    const date = moment();
    this.subscriptions.push(this.applicationDialogService.openConsumerPricingComparisonDialog({
      currentValue: this.currentRstValue,
      newValue: this.calculateEstimation(date)
    }).afterClosed()
      .pipe(
        tap(r => {
          if (r && r.acceptedQuotation) {
            // update the doc fee
            this.terms!.docFee = r.docFee;
            // patch current rst docfee, save will emit events that asset-finance-application-details component subscribe
            this.rst!.totalPaymentBreakupDialogData.paymentChartData.docFee = r.docFee;
            // recreate application data for submission
            this.data = this.createApplicationData();
            console.log('===this/data: ', this.data)
            this.save();
          }
        })
      ).subscribe());
  }

  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const assetConditionValue: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const assetSelectionValue: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const loanTermValue: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const financeTypeValue: FinanceTypeValue = this.formControlStep2FinanceType.value;
    const invoiceAmount: CurrencyInputValue = this.formControlStep2InvoiceAmount.value ?? 0;
    const balloonPaymentValue: CurrencyInputValue = this.formControlStep2BalloonPayment.value ?? 0;    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const brokerageAmount = this.formControlStep2BrokerageAmount.value;
    const brokeragePercentage = Math.round((brokerageAmount ?? 0) * 10000 / loanAmount) / 100;
    const brokerageValue: BrokerageSelectionValue = {type: `${brokeragePercentage}` as BrokerageSelectionType, name: `${brokeragePercentage}%`};
    const balloonPaymentPercentage: number = invoiceAmount ? balloonPaymentValue * 100 / invoiceAmount : 0;
    const creditRateAdjustment = this.formControlCreditRateAdjustment.value;
    const hybrid = this.step2ShowHybridField ? !!this.formControlStep2Hybrid.value : false;
    const rateDiscount: number = this.formControlStep2RateDiscount.value ?? 0;
    const ppsrFee: number = this.formControlStep2PpsrFee.value ?? 0;

    this.rst = calculateConsumerAssetFinanceEstimation(date, {
      type: 'Consumer',
      paymentFrequencyType: paymentFrequencyValue?.type ?? null,
      assetConditionType: assetConditionValue?.type ?? null,
      assetSelectionValue: assetSelectionValue!,
      assetYear: parseFloat(assetSelectionValue?.year ?? '0'),
      loanTermType: (String(loanTermValue) as LoanTermType) ?? null,
      financeType: financeTypeValue?.type ?? null,
      balloonPaymentType: (`${balloonPaymentPercentage}` as BalloonPaymentType) ?? null,
      brokerageType: brokerageValue?.type ?? null,
      loanAmount: numeral(this.formControlStep2LoanAmount.value).value() ?? 0,
      propertyOwnership: this.formControlStep2PropertyOwner.value ?? false,
      docFeeFinanced: this.formControlStep2DocFeeFinanced.value ?? false,
      brokerOriginationFee: this.formControlStep2BrokerOriginationFee.value ?? 0,
      brokerage: Number(brokerageValue?.type ?? 0),
      brokerageAmount: brokerageAmount ?? 0,
      adverseOnFile: this.formControlStep2AdverseOnFile.value ?? false,
      equifaxScoreAbove600: this.formControlStep2EquifaxScoreAboveThreshold.value ?? false,
      privateSaleOrLeaseback: this.formControlStep2TransactionType.value?.type !== 'Dealer Sale',
      balloonPayment: balloonPaymentPercentage,
      rateCard: this.rateCard,
      invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
      deposit: this.formControlStep2DepositAmount.value ?? 0,
      // transactionType: this.formControlStep2TransactionType.value?.type,

      // extra fields that affects estimation
      creditRateAdjustment: creditRateAdjustment ?? 0, // handle creditRateAdjustment
      businessSearchValue: null,
      existingApplicationBureauReport: this.application.CompanyDetails,
      hybrid: hybrid,
      rateDiscount: rateDiscount,
      ppsrFee: ppsrFee,
      percentagePaidToDealerOrBroker: this.originatorBusiness ? this.originatorBusiness.PercentagePaid : 100,
    });

    console.log('=========rst', this.rst)
    this.calculator = this.rst.calculator;
    this.terms = this.rst.terms;
    this.step2Repayment = this.rst.repayment;
    this.AmountFinance = this.rst.calculator.principalAmt;// + this.rst.calculator.brokerageAmount);

    console.log('****** calc result', this.rst);
    this.data = this.createApplicationData();

    return this.rst.totalPaymentBreakupDialogData;
  }

  step2UpdateDepositValidation() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    if (asset) {
      const invoiceAmount = this.formControlStep2InvoiceAmount.value ?? 0;
      const assetCategoryIndex = asset.category.index;
      const isPropetyOwner = this.formControlStep2PropertyOwner.value ?? false;

      const minDeposit = minDepositForConsumerAssetFinance(invoiceAmount, assetCategoryIndex, isPropetyOwner);
      this.step2DepositMin = minDeposit;
      this.step2DepositMax = _.round(invoiceAmount * 0.8, 2); // 80% of invoiceAmount;
      // if (this.application) {
      //   this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.application));
      // }
    }
  }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  onAssetSelectionEvent($event: AssetSelectionComponentEvent) {
    this.assetSelectionReady = ($event.type == 'loaded');
  }

  canGenerateQuote(): boolean {
    const canGenerate =  (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM && this.formGroupStep2.valid  &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
    if (!canGenerate) {
      this.markSubject.next(true);
    }
    return canGenerate;
  }

  canEditPricing(): boolean {
    return (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
  }

  onEditBrokerage() {
    this.subscriptions.push(
      this.applicationDialogService.openEditBrokerageDailog({
        brokerageAmount: this.formControlStep2BrokerageAmount.value ?? 0,
        loanAmount: this.formControlStep2LoanAmount.value ?? 0,
        maxBrokeragePercentage: 4,
      }).afterClosed().subscribe(result => {
        if (result && result?.readyForSubmission) {
          this.formControlStep2BrokerageAmount.setValue(result.brokerageAmount);
          this.brokeragePercentage = result.brokeragePercentage;

          console.log(this.brokeragePercentage, _.round((this.formControlStep2BrokerageAmount.value ?? 0) * 100 / (this.formControlStep2LoanAmount.value ?? 0), 2))
        }
      })
    )
  }

  onEditInvoiceAmount() {
    this.subscriptions.push(
      this.applicationDialogService.openEditInvoiceAmountDailog({
        title: 'Edit Invoice amount (incl GST)',
        invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
        min: this.MIN_LOAN_AMOUNT,
        originalLoanAmount: applicationToLoanAmount(this.application) ?? 0,
        limitMaxInvoiceAmount: this.isNotAdminOrCreditUser,
        depositAmount: this.formControlStep2DepositAmount.value ?? 0,
        minDeposit: this.step2DepositMin,
        maxDeposit: this.step2DepositMax,
        financeType: this.formControlStep2FinanceType.value?.type ?? 'chattel-mortgage',
      }).afterClosed().subscribe(result => {
        if (result && result?.readyForSubmission) {
          this.formControlStep2InvoiceAmount.setValue(result.invoiceAmount);
          this.formControlStep2DepositAmount.setValue(result.depositAmount);
        }
      })
    )
  }
}
