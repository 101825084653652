import {UntilDestroy} from "@ngneat/until-destroy";
import {BehaviorSubject, Subscription} from "rxjs";
import {inject, Injectable, OnDestroy} from "@angular/core";
import {
  DEFAULT_FILTER,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  OriginatorListValue,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PayloadApiResponse,
  SaveWhitelabel,
  SortTypes,
  WhitelabelEntity,
  WhitelabelEntityDetails,
  WhitelabelServiceEvent,
  WhitelabelStyles
} from "@portal-workspace/grow-shared-library";
import {environment} from "../../environments/environment";
import {
  generateMaterialShades,
  getWhiteLabelFromStorage,
  httpOptions,
  paginationUrl, setupUntilDestroy,
  storeWhitelabelIntoStorage, toContrastColor,
  toPaginationInfo
} from "@portal-workspace/grow-ui-library";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";


const URL_GET_ALL_WHITELABELS  = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/whitelabel/all`, paginationInfo);  // GET
const URL_GET_WHITELABEL_BY_ORIGINATOR_BUSINESS_ID = (originatorBusinessId: number) => `${environment.api2Host}/api2/whitelabel/originator/${originatorBusinessId}`; // GET
const URL_GET_WHITELABEL_BY_USER_ID = (userId: number) => `${environment.api2Host}/api2/whitelabel/user/${userId}`; // GET
const URL_SAVE_WHITELABEL = (originatorBusinessId: number) => `${environment.api2Host}/api2/whitelabel/save/${originatorBusinessId}`; // POST
const URL_ORIGINATORS_WITHOUT_WHITELABEL = () => `${environment.api2Host}/api2/whitelabel/available-originators`; // GET


export const DEFAULT_STYLES: WhitelabelStyles = {
  'grow-primary-palette-50': '#EFE9FF',
  'grow-primary-palette-100': '#D5C9F8',
  'grow-primary-palette-200': '#B9A6F0',
  'grow-primary-palette-300': '#9B82E9',
  'grow-primary-palette-400': '#8365E3',
  'grow-primary-palette-500': '#5D40FF',
  'grow-primary-palette-600': '#4E3BF6',
  'grow-primary-palette-700': '#3D37F0',
  'grow-primary-palette-800': '#0028DA',
  'grow-primary-palette-900': '#0014CD',
  'grow-primary-palette-A100': '#83ff83',
  'grow-primary-palette-A200': '#50ff50',
  'grow-primary-palette-A400': '#1dff1d',
  'grow-primary-palette-A700': '#03ff03',
  'grow-primary-palette-contrast-50': '#000000',
  'grow-primary-palette-contrast-100': '#000000',
  'grow-primary-palette-contrast-200': '#000000',
  'grow-primary-palette-contrast-300': '#000000',
  'grow-primary-palette-contrast-400': '#ffffff',
  'grow-primary-palette-contrast-500': '#ffffff',
  'grow-primary-palette-contrast-600': '#ffffff',
  'grow-primary-palette-contrast-700': '#ffffff',
  'grow-primary-palette-contrast-800': '#ffffff',
  'grow-primary-palette-contrast-900': '#ffffff',
  'grow-primary-palette-contrast-A100': '#000000',
  'grow-primary-palette-contrast-A200': '#000000',
  'grow-primary-palette-contrast-A400': '#ffffff',
  'grow-primary-palette-contrast-A700': '#ffffff',
  'grow-accent-palette-50': '#fcfefc',
  'grow-accent-palette-100': '#f8fcf8',
  'grow-accent-palette-200': '#f4faf4',
  'grow-accent-palette-300': '#eff8f0',
  'grow-accent-palette-400': '#ebf7ec',
  'grow-accent-palette-500': '#e8f5e9',
  'grow-accent-palette-600': '#e5f4e6',
  'grow-accent-palette-700': '#e2f2e3',
  'grow-accent-palette-800': '#def0df',
  'grow-accent-palette-900': '#d8eed9',
  'grow-accent-palette-A100': '#ffffff',
  'grow-accent-palette-A200': '#ffffff',
  'grow-accent-palette-A400': '#ffffff',
  'grow-accent-palette-A700': '#ffffff',
  'grow-accent-palette-contrast-50': '#000000',
  'grow-accent-palette-contrast-100': '#000000',
  'grow-accent-palette-contrast-200': '#000000',
  'grow-accent-palette-contrast-300': '#000000',
  'grow-accent-palette-contrast-400': '#000000',
  'grow-accent-palette-contrast-500': '#ffffff',
  'grow-accent-palette-contrast-600': '#ffffff',
  'grow-accent-palette-contrast-700': '#ffffff',
  'grow-accent-palette-contrast-800': '#ffffff',
  'grow-accent-palette-contrast-900': '#ffffff',
  'grow-accent-palette-contrast-A100': '#000000',
  'grow-accent-palette-contrast-A200': '#000000',
  'grow-accent-palette-contrast-A400': '#000000',
  'grow-accent-palette-contrast-A700': '#000000',
  'grow-warn-palette-50': '#fce8e7',
  'grow-warn-palette-100': '#f7c6c2',
  'grow-warn-palette-200': '#f2a19a',
  'grow-warn-palette-300': '#ed7b71',
  'grow-warn-palette-400': '#e95e52',
  'grow-warn-palette-500': '#e54234',
  'grow-warn-palette-600': '#e23c2f',
  'grow-warn-palette-700': '#de3327',
  'grow-warn-palette-800': '#da2b21',
  'grow-warn-palette-900': '#d31d15',
  'grow-warn-palette-A100': '#ffffff',
  'grow-warn-palette-A200': '#ffd2d0',
  'grow-warn-palette-A400': '#ffa19d',
  'grow-warn-palette-A700': '#ff8884',
  'grow-warn-palette-contrast-50': '#000000',
  'grow-warn-palette-contrast-100': '#000000',
  'grow-warn-palette-contrast-200': '#000000',
  'grow-warn-palette-contrast-300': '#000000',
  'grow-warn-palette-contrast-400': '#000000',
  'grow-warn-palette-contrast-500': '#ffffff',
  'grow-warn-palette-contrast-600': '#ffffff',
  'grow-warn-palette-contrast-700': '#ffffff',
  'grow-warn-palette-contrast-800': '#ffffff',
  'grow-warn-palette-contrast-900': '#ffffff',
  'grow-warn-palette-contrast-A100': '#000000',
  'grow-warn-palette-contrast-A200': '#000000',
  'grow-warn-palette-contrast-A400': '#000000',
  'grow-warn-palette-contrast-A700': '#000000',
};

export const GREEN_STYLES: WhitelabelStyles = {
  'grow-primary-palette-50': '#e2ece5',
  'grow-primary-palette-100': '#b6cebe',
  'grow-primary-palette-200': '#85ae93',
  'grow-primary-palette-300': '#548e67',
  'grow-primary-palette-400': '#307547',
  'grow-primary-palette-500': '#0b5d26',
  'grow-primary-palette-600': '#0a5522',
  'grow-primary-palette-700': '#084b1c',
  'grow-primary-palette-800': '#064117',
  'grow-primary-palette-900': '#03300d',
  'grow-primary-palette-A100': '#9aff7a',
  'grow-primary-palette-A200': '#73ff47',
  'grow-primary-palette-A400': '#4cff14',
  'grow-primary-palette-A700': '#3bfa00',
  'grow-primary-palette-contrast-50': '#000000',
  'grow-primary-palette-contrast-100': '#000000',
  'grow-primary-palette-contrast-200': '#000000',
  'grow-primary-palette-contrast-300': '#ffffff',
  'grow-primary-palette-contrast-400': '#ffffff',
  'grow-primary-palette-contrast-500': '#ffffff',
  'grow-primary-palette-contrast-600': '#ffffff',
  'grow-primary-palette-contrast-700': '#ffffff',
  'grow-primary-palette-contrast-800': '#ffffff',
  'grow-primary-palette-contrast-900': '#ffffff',
  'grow-primary-palette-contrast-A100': '#000000',
  'grow-primary-palette-contrast-A200': '#000000',
  'grow-primary-palette-contrast-A400': '#000000',
  'grow-primary-palette-contrast-A700': '#000000',
  'grow-accent-palette-50': '#f4f9fa',
  'grow-accent-palette-100': '#e3f0f3',
  'grow-accent-palette-200': '#d0e7eb',
  'grow-accent-palette-300': '#bddde2',
  'grow-accent-palette-400': '#afd5dc',
  'grow-accent-palette-500': '#a1ced6',
  'grow-accent-palette-600': '#99c9d1',
  'grow-accent-palette-700': '#8fc2cc',
  'grow-accent-palette-800': '#85bcc6',
  'grow-accent-palette-900': '#74b0bc',
  'grow-accent-palette-A100': '#ffffff',
  'grow-accent-palette-A200': '#ffffff',
  'grow-accent-palette-A400': '#ffffff',
  'grow-accent-palette-A700': '#eefeff',
  'grow-accent-palette-contrast-50': '#000000',
  'grow-accent-palette-contrast-100': '#000000',
  'grow-accent-palette-contrast-200': '#000000',
  'grow-accent-palette-contrast-300': '#000000',
  'grow-accent-palette-contrast-400': '#000000',
  'grow-accent-palette-contrast-500': '#000000',
  'grow-accent-palette-contrast-600': '#000000',
  'grow-accent-palette-contrast-700': '#000000',
  'grow-accent-palette-contrast-800': '#000000',
  'grow-accent-palette-contrast-900': '#000000',
  'grow-accent-palette-contrast-A100': '#000000',
  'grow-accent-palette-contrast-A200': '#000000',
  'grow-accent-palette-contrast-A400': '#000000',
  'grow-accent-palette-contrast-A700': '#000000',
  'grow-warn-palette-50': '#fff6f5',
  'grow-warn-palette-100': '#ffe9e6',
  'grow-warn-palette-200': '#ffdad5',
  'grow-warn-palette-300': '#ffcbc4',
  'grow-warn-palette-400': '#ffbfb8',
  'grow-warn-palette-500': '#ffb4ab',
  'grow-warn-palette-600': '#ffada4',
  'grow-warn-palette-700': '#ffa49a',
  'grow-warn-palette-800': '#ff9c91',
  'grow-warn-palette-900': '#ff8c80',
  'grow-warn-palette-A100': '#ffffff',
  'grow-warn-palette-A200': '#ffffff',
  'grow-warn-palette-A400': '#ffffff',
  'grow-warn-palette-A700': '#ffffff',
  'grow-warn-palette-contrast-50': '#000000',
  'grow-warn-palette-contrast-100': '#000000',
  'grow-warn-palette-contrast-200': '#000000',
  'grow-warn-palette-contrast-300': '#000000',
  'grow-warn-palette-contrast-400': '#000000',
  'grow-warn-palette-contrast-500': '#000000',
  'grow-warn-palette-contrast-600': '#000000',
  'grow-warn-palette-contrast-700': '#000000',
  'grow-warn-palette-contrast-800': '#000000',
  'grow-warn-palette-contrast-900': '#000000',
  'grow-warn-palette-contrast-A100': '#000000',
  'grow-warn-palette-contrast-A200': '#000000',
  'grow-warn-palette-contrast-A400': '#000000',
  'grow-warn-palette-contrast-A700': '#000000',
};

export const BROWN_STYLES: WhitelabelStyles = {
  'grow-primary-palette-50': '#fdf0ed',
  'grow-primary-palette-100': '#fad8d3',
  'grow-primary-palette-200': '#f7bfb5',
  'grow-primary-palette-300': '#f4a597',
  'grow-primary-palette-400': '#f19181',
  'grow-primary-palette-500': '#ef7e6b',
  'grow-primary-palette-600': '#ed7663',
  'grow-primary-palette-700': '#eb6b58',
  'grow-primary-palette-800': '#e8614e',
  'grow-primary-palette-900': '#e44e3c',
  'grow-primary-palette-A100': '#ffffff',
  'grow-primary-palette-A200': '#ffffff',
  'grow-primary-palette-A400': '#ffffff',
  'grow-primary-palette-A700': '#ffeded',
  'grow-primary-palette-contrast-50': '#000000',
  'grow-primary-palette-contrast-100': '#000000',
  'grow-primary-palette-contrast-200': '#000000',
  'grow-primary-palette-contrast-300': '#000000',
  'grow-primary-palette-contrast-400': '#ffffff',
  'grow-primary-palette-contrast-500': '#ffffff',
  'grow-primary-palette-contrast-600': '#ffffff',
  'grow-primary-palette-contrast-700': '#ffffff',
  'grow-primary-palette-contrast-800': '#ffffff',
  'grow-primary-palette-contrast-900': '#ffffff',
  'grow-primary-palette-contrast-A100': '#000000',
  'grow-primary-palette-contrast-A200': '#000000',
  'grow-primary-palette-contrast-A400': '#ffffff',
  'grow-primary-palette-contrast-A700': '#ffffff',
  'grow-accent-palette-50': '#eeebe6',
  'grow-accent-palette-100': '#d4cec0',
  'grow-accent-palette-200': '#b7ae97',
  'grow-accent-palette-300': '#9a8d6d',
  'grow-accent-palette-400': '#85744d',
  'grow-accent-palette-500': '#6f5c2e',
  'grow-accent-palette-600': '#675429',
  'grow-accent-palette-700': '#5c4a23',
  'grow-accent-palette-800': '#52411d',
  'grow-accent-palette-900': '#403012',
  'grow-accent-palette-A100': '#ffc19b',
  'grow-accent-palette-A200': '#ffa168',
  'grow-accent-palette-A400': '#ff8135',
  'grow-accent-palette-A700': '#ff711c',
  'grow-accent-palette-contrast-50': '#000000',
  'grow-accent-palette-contrast-100': '#000000',
  'grow-accent-palette-contrast-200': '#000000',
  'grow-accent-palette-contrast-300': '#000000',
  'grow-accent-palette-contrast-400': '#ffffff',
  'grow-accent-palette-contrast-500': '#ffffff',
  'grow-accent-palette-contrast-600': '#ffffff',
  'grow-accent-palette-contrast-700': '#ffffff',
  'grow-accent-palette-contrast-800': '#ffffff',
  'grow-accent-palette-contrast-900': '#ffffff',
  'grow-accent-palette-contrast-A100': '#000000',
  'grow-accent-palette-contrast-A200': '#000000',
  'grow-accent-palette-contrast-A400': '#000000',
  'grow-accent-palette-contrast-A700': '#000000',
  'grow-warn-palette-50': '#f7e4e4',
  'grow-warn-palette-100': '#eababa',
  'grow-warn-palette-200': '#dd8d8d',
  'grow-warn-palette-300': '#cf5f5f',
  'grow-warn-palette-400': '#c43c3c',
  'grow-warn-palette-500': '#ba1a1a',
  'grow-warn-palette-600': '#b31717',
  'grow-warn-palette-700': '#ab1313',
  'grow-warn-palette-800': '#a30f0f',
  'grow-warn-palette-900': '#940808',
  'grow-warn-palette-A100': '#ffd5dd',
  'grow-warn-palette-A200': '#ffa2b4',
  'grow-warn-palette-A400': '#ff6f8a',
  'grow-warn-palette-A700': '#ff5576',
  'grow-warn-palette-contrast-50': '#000000',
  'grow-warn-palette-contrast-100': '#000000',
  'grow-warn-palette-contrast-200': '#000000',
  'grow-warn-palette-contrast-300': '#000000',
  'grow-warn-palette-contrast-400': '#ffffff',
  'grow-warn-palette-contrast-500': '#ffffff',
  'grow-warn-palette-contrast-600': '#ffffff',
  'grow-warn-palette-contrast-700': '#ffffff',
  'grow-warn-palette-contrast-800': '#ffffff',
  'grow-warn-palette-contrast-900': '#ffffff',
  'grow-warn-palette-contrast-A100': '#000000',
  'grow-warn-palette-contrast-A200': '#000000',
  'grow-warn-palette-contrast-A400': '#000000',
  'grow-warn-palette-contrast-A700': '#000000',
}

export const BLUE_STYLES: WhitelabelStyles = {
  'grow-primary-palette-50': '#e6e2f2',
  'grow-primary-palette-100': '#c1b7df',
  'grow-primary-palette-200': '#9787ca',
  'grow-primary-palette-300': '#6d56b5',
  'grow-primary-palette-400': '#4e32a5',
  'grow-primary-palette-500': '#2f0e95',
  'grow-primary-palette-600': '#2a0c8d',
  'grow-primary-palette-700': '#230a82',
  'grow-primary-palette-800': '#1d0878',
  'grow-primary-palette-900': '#120467',
  'grow-primary-palette-A100': '#b0bdff',
  'grow-primary-palette-A200': '#7d93ff',
  'grow-primary-palette-A400': '#4a68ff',
  'grow-primary-palette-A700': '#3153ff',
  'grow-primary-palette-contrast-50': '#000000',
  'grow-primary-palette-contrast-100': '#000000',
  'grow-primary-palette-contrast-200': '#000000',
  'grow-primary-palette-contrast-300': '#ffffff',
  'grow-primary-palette-contrast-400': '#ffffff',
  'grow-primary-palette-contrast-500': '#ffffff',
  'grow-primary-palette-contrast-600': '#ffffff',
  'grow-primary-palette-contrast-700': '#ffffff',
  'grow-primary-palette-contrast-800': '#ffffff',
  'grow-primary-palette-contrast-900': '#ffffff',
  'grow-primary-palette-contrast-A100': '#000000',
  'grow-primary-palette-contrast-A200': '#000000',
  'grow-primary-palette-contrast-A400': '#ffffff',
  'grow-primary-palette-contrast-A700': '#ffffff',
  'grow-accent-palette-50': '#fdf6f9',
  'grow-accent-palette-100': '#f9eaf0',
  'grow-accent-palette-200': '#f6dce6',
  'grow-accent-palette-300': '#f2cddc',
  'grow-accent-palette-400': '#efc3d5',
  'grow-accent-palette-500': '#ecb8cd',
  'grow-accent-palette-600': '#eab1c8',
  'grow-accent-palette-700': '#e7a8c1',
  'grow-accent-palette-800': '#e4a0ba',
  'grow-accent-palette-900': '#df91ae',
  'grow-accent-palette-A100': '#ffffff',
  'grow-accent-palette-A200': '#ffffff',
  'grow-accent-palette-A400': '#ffffff',
  'grow-accent-palette-A700': '#ffffff',
  'grow-accent-palette-contrast-50': '#000000',
  'grow-accent-palette-contrast-100': '#000000',
  'grow-accent-palette-contrast-200': '#000000',
  'grow-accent-palette-contrast-300': '#000000',
  'grow-accent-palette-contrast-400': '#000000',
  'grow-accent-palette-contrast-500': '#000000',
  'grow-accent-palette-contrast-600': '#000000',
  'grow-accent-palette-contrast-700': '#000000',
  'grow-accent-palette-contrast-800': '#000000',
  'grow-accent-palette-contrast-900': '#000000',
  'grow-accent-palette-contrast-A100': '#000000',
  'grow-accent-palette-contrast-A200': '#000000',
  'grow-accent-palette-contrast-A400': '#000000',
  'grow-accent-palette-contrast-A700': '#000000',
  'grow-warn-palette-50': '#fff6f5',
  'grow-warn-palette-100': '#ffe9e6',
  'grow-warn-palette-200': '#ffdad5',
  'grow-warn-palette-300': '#ffdad5',
  'grow-warn-palette-400': '#ffbfb8',
  'grow-warn-palette-500': '#ffb4ab',
  'grow-warn-palette-600': '#ffada4',
  'grow-warn-palette-700': '#ffa49a',
  'grow-warn-palette-800': '#ff9c91',
  'grow-warn-palette-900': '#ff8c80',
  'grow-warn-palette-A100': '#ffffff',
  'grow-warn-palette-A200': '#ffffff',
  'grow-warn-palette-A400': '#ffffff',
  'grow-warn-palette-A700': '#ffffff',
  'grow-warn-palette-contrast-50': '#000000',
  'grow-warn-palette-contrast-100': '#000000',
  'grow-warn-palette-contrast-200': '#000000',
  'grow-warn-palette-contrast-300': '#000000',
  'grow-warn-palette-contrast-400': '#000000',
  'grow-warn-palette-contrast-500': '#000000',
  'grow-warn-palette-contrast-600': '#000000',
  'grow-warn-palette-contrast-700': '#000000',
  'grow-warn-palette-contrast-800': '#000000',
  'grow-warn-palette-contrast-900': '#000000',
  'grow-warn-palette-contrast-A100': '#000000',
  'grow-warn-palette-contrast-A200': '#000000',
  'grow-warn-palette-contrast-A400': '#000000',
  'grow-warn-palette-contrast-A700': '#000000',
}


export const PINK_STYLES: WhitelabelStyles = {
  'grow-primary-palette-50': '#f3e2e7',
  'grow-primary-palette-100': '#e1b7c3',
  'grow-primary-palette-200': '#cd889b',
  'grow-primary-palette-300': '#b95873',
  'grow-primary-palette-400': '#aa3455',
  'grow-primary-palette-500': '#9b1037',
  'grow-primary-palette-600': '#930e31',
  'grow-primary-palette-700': '#890c2a',
  'grow-primary-palette-800': '#7f0923',
  'grow-primary-palette-900': '#6d0516',
  'grow-primary-palette-A100': '#ffa7c6',
  'grow-primary-palette-A200': '#ff74a5',
  'grow-primary-palette-A400': '#ff4183',
  'grow-primary-palette-A700': '#ff2773',
  'grow-primary-palette-contrast-50': '#000000',
  'grow-primary-palette-contrast-100': '#000000',
  'grow-primary-palette-contrast-200': '#000000',
  'grow-primary-palette-contrast-300': '#ffffff',
  'grow-primary-palette-contrast-400': '#ffffff',
  'grow-primary-palette-contrast-500': '#ffffff',
  'grow-primary-palette-contrast-600': '#ffffff',
  'grow-primary-palette-contrast-700': '#ffffff',
  'grow-primary-palette-contrast-800': '#ffffff',
  'grow-primary-palette-contrast-900': '#ffffff',
  'grow-primary-palette-contrast-A100': '#000000',
  'grow-primary-palette-contrast-A200': '#000000',
  'grow-primary-palette-contrast-A400': '#000000',
  'grow-primary-palette-contrast-A700': '#ffffff',
  'grow-accent-palette-50': '#efebe6',
  'grow-accent-palette-100': '#d7cdc1',
  'grow-accent-palette-200': '#bcac98',
  'grow-accent-palette-300': '#a18b6e',
  'grow-accent-palette-400': '#8c724f',
  'grow-accent-palette-500': '#785930',
  'grow-accent-palette-600': '#70512b',
  'grow-accent-palette-700': '#654824',
  'grow-accent-palette-800': '#5b3e1e',
  'grow-accent-palette-900': '#482e13',
  'grow-accent-palette-A100': '#ffbaa7',
  'grow-accent-palette-A200': '#ff9274',
  'grow-accent-palette-A400': '#ff6941',
  'grow-accent-palette-A700': '#ff5527',
  'grow-accent-palette-contrast-50': '#000000',
  'grow-accent-palette-contrast-100': '#000000',
  'grow-accent-palette-contrast-200': '#000000',
  'grow-accent-palette-contrast-300': '#000000',
  'grow-accent-palette-contrast-400': '#ffffff',
  'grow-accent-palette-contrast-500': '#ffffff',
  'grow-accent-palette-contrast-600': '#ffffff',
  'grow-accent-palette-contrast-700': '#ffffff',
  'grow-accent-palette-contrast-800': '#ffffff',
  'grow-accent-palette-contrast-900': '#ffffff',
  'grow-accent-palette-contrast-A100': '#000000',
  'grow-accent-palette-contrast-A200': '#000000',
  'grow-accent-palette-contrast-A400': '#000000',
  'grow-accent-palette-contrast-A700': '#000000',
  'grow-warn-palette-50': '#f7e4e4',
  'grow-warn-palette-100': '#eababa',
  'grow-warn-palette-200': '#dd8d8d',
  'grow-warn-palette-300': '#cf5f5f',
  'grow-warn-palette-400': '#c43c3c',
  'grow-warn-palette-500': '#ba1a1a',
  'grow-warn-palette-600': '#b31717',
  'grow-warn-palette-700': '#ab1313',
  'grow-warn-palette-800': '#a30f0f',
  'grow-warn-palette-900': '#940808',
  'grow-warn-palette-A100': '#ffd5dd',
  'grow-warn-palette-A200': '#ffa2b4',
  'grow-warn-palette-A400': '#ff6f8a',
  'grow-warn-palette-A700': '#ff5576',
  'grow-warn-palette-contrast-50': '#000000',
  'grow-warn-palette-contrast-100': '#000000',
  'grow-warn-palette-contrast-200': '#000000',
  'grow-warn-palette-contrast-300': '#000000',
  'grow-warn-palette-contrast-400': '#ffffff',
  'grow-warn-palette-contrast-500': '#ffffff',
  'grow-warn-palette-contrast-600': '#ffffff',
  'grow-warn-palette-contrast-700': '#ffffff',
  'grow-warn-palette-contrast-800': '#ffffff',
  'grow-warn-palette-contrast-900': '#ffffff',
  'grow-warn-palette-contrast-A100': '#000000',
  'grow-warn-palette-contrast-A200': '#000000',
  'grow-warn-palette-contrast-A400': '#000000',
  'grow-warn-palette-contrast-A700': '#000000',
}


export const ALL_STYLES =  [
  {name: 'Purple Pumpkin', style: DEFAULT_STYLES},
  {name: 'Green Guava', style: GREEN_STYLES},
  {name: 'Brown Banana', style: BROWN_STYLES},
  {name: 'Blue Bael', style: BLUE_STYLES},
  {name: 'Pink Pear', style: PINK_STYLES}
].sort((a, b) => a.name.localeCompare(b.name));



@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class WhitelabelService implements OnDestroy {

  subject = new BehaviorSubject<WhitelabelServiceEvent>(null);

  subscriptions: Subscription[] = [];
  httpClient = inject(HttpClient);

  constructor() {
    setupUntilDestroy(this);
  }

  ngOnDestroy() {
    this.subject.complete();
  }

  getOriginatorsWithoutWhitelabelFn = () => {
    return this.getOriginatorsWithoutWhitelabel().pipe(map(r => r.payload));
  }

  getOriginatorsWithoutWhitelabel() {
    return this.httpClient.get<PayloadApiResponse<Exclude<OriginatorListValue, null>[]>>(URL_ORIGINATORS_WITHOUT_WHITELABEL(), httpOptions());
  }

  getAllWhitelabels(
    options: {
      page: {offset: number, limit: number},
      filter: string,
      sorts?: SortTypes,
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT}, filter: DEFAULT_FILTER}
  ) {
    const paginationInfo = toPaginationInfo(options);
    return this.httpClient.get<PaginablePayloadApiResponse<WhitelabelEntity>>(URL_GET_ALL_WHITELABELS(paginationInfo), httpOptions());
  }

  saveWhitelabel(originatorBusinessId: number, data: SaveWhitelabel) {
    return this.httpClient.post(URL_SAVE_WHITELABEL(originatorBusinessId), data, httpOptions());
  }

  getWhitelabelByOriginatorBusinessId(originatorBusinessId: number) {
    return this.httpClient.get<PayloadApiResponse<WhitelabelEntityDetails>>(URL_GET_WHITELABEL_BY_ORIGINATOR_BUSINESS_ID(originatorBusinessId), httpOptions());
  }

  // when user login
  loadFromRemoteAndTriggerWhitelabelEvent(userId: number) {
    return this.httpClient
      .get<PayloadApiResponse<WhitelabelEntityDetails>>(URL_GET_WHITELABEL_BY_USER_ID(userId), httpOptions())
      .pipe(
        tap(r => {
          if (r.payload) {
            storeWhitelabelIntoStorage(r.payload);
            this.triggerUserWhitelabelChange({
              type: 'whitelabel-change',
              payload: {
                logoLink: r.payload.logoLink,
                styles: r.payload.styles,
              },
            });
          } else {
            storeWhitelabelIntoStorage(null);
            this.triggerUserWhitelabelChange({
              type: 'whitelabel-change',
              payload: {
                logoLink: null,
                styles: null,
              },
            });
          }
        })
      );
  }

  // when application loads, or when admin-whitelabel try to reset whitelabel
  loadFromLocalStorageAndTriggerWhitelabelEvent() {
    const whitelabel = getWhiteLabelFromStorage();
    this.triggerUserWhitelabelChange({
      type: 'whitelabel-change',
      payload: {
        logoLink: whitelabel?.logoLink ?? null,
        styles: whitelabel?.styles ?? null,
      },
    });
  }

  // from admin-whitelabel page
  triggerApplyWhitelabelEvent(we: { logoLink: string, styles: WhitelabelStyles }) {
    this.triggerUserWhitelabelChange({
      type: 'apply-whitelabel',
      payload: we,
    });
  }

  private triggerUserWhitelabelChange(event: WhitelabelServiceEvent) {
    this.subject.next(event);
  }

  // updateStylesFromStorage() {
  //   const whiteLabelEntityDetails = getWhiteLabelFromStorage();
  //   if (whiteLabelEntityDetails && whiteLabelEntityDetails.styles) {
  //     this.updateStyles(whiteLabelEntityDetails.styles);
  //   } else {
  //     this.clearStyles();
  //   }
  // }

  updateStyles(obj: WhitelabelStyles) {
    window.requestAnimationFrame(() => {
      for (const key in obj) {
        document.documentElement.style.setProperty(`--${key}`, `${(obj as any)[key]}`);
      }
      document.documentElement.style.setProperty(`--portal2-sg-dynamoney-blue`, `var(--grow-primary-palette-500) `);
    });
  }

  clearStyles() {
    window.requestAnimationFrame(() => {
      for (const key in DEFAULT_STYLES) {
        document.documentElement.style.removeProperty(`--${key}`);
      }
      document.documentElement.style.removeProperty(`--portal2-sg-dynamoney-blue`);
    })
  }

}


