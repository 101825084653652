import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {TopMenuService} from './top-menu.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable, Subscription} from 'rxjs';
import {
  getUser, getWhiteLabelFromStorage,
  hasAccessBrokerDashboard, hasAccessOverdraftDashboard,
  hasMultipleRole,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ChangePortalThemeFn,
  PortalTheme,
  PortalThemeServiceEvent,
  WhitelabelServiceEvent
} from "@portal-workspace/grow-shared-library";
import {PortalThemeComponent} from "../portal-theme-component/portal-theme.component";
import {PortalThemeService} from "../../../../../../apps/portal2/src/app/service/portal-theme.service";


export type ToolbarComponentEvent = ToolbarComponentLogoutEvent;


export interface ToolbarComponentLogoutEvent {
  type: 'logout'; //  | 'switch-broker-portal' | 'switch-trade-portal' | 'switch-floorplan-portal' | 'switch-overdraft-portal';
}


const DEFAULT_LOGO = 'https://growpublicaccess.blob.core.windows.net/logos/SVG/DYNA_Logo_103023_Master-Rev.svg';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'topmenu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
    standalone: true,
  imports: [
    MatToolbarModule,
    FlexModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    NgClass,
    ExtendedModule,
    PortalThemeComponent,
  ]
})
export class TopMenuComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: false}) gitTagOrVersion: string = '';
  @Input({required: false}) whitelabelServiceEventObservable?: Observable<WhitelabelServiceEvent>;
  @Output() events: EventEmitter<ToolbarComponentEvent> = new EventEmitter<ToolbarComponentEvent>();

  isProgressBarVisible: boolean = false;

  formControlSearch: FormControl<string | null>;

  hasMultipleRole: boolean = false;
  // NOTE: not used anymore
  // hasAccessTradeDashboard = false;
  hasAccessOverdraftDashboard = false;
  hasAccessBrokerDashboard = false;

  logo = DEFAULT_LOGO;

  constructor(private formBuilder: FormBuilder,
              private topMenuService: TopMenuService) {
    this.formControlSearch = this.formBuilder.control('');
  }

  onToolbarItemClick(type: ToolbarComponentEvent['type']) {
    const evt = {
      type: type,
    };
    this.events.emit(evt);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const user = getUser()
    if (user) {
      this.hasMultipleRole = hasMultipleRole(user)
      this.hasAccessBrokerDashboard = hasAccessBrokerDashboard(user);
      this.hasAccessOverdraftDashboard = hasAccessOverdraftDashboard(user);
    }
    this.subscriptions.push(this.topMenuService.subject.pipe(
      tap(r => {
        const v = !!r;
        this.isProgressBarVisible = v;
      })
    ).subscribe());
    if (this.whitelabelServiceEventObservable) {
      this.subscriptions.push(this.whitelabelServiceEventObservable.pipe(
        tap(r => {
          if (r && r.payload.logoLink) {
            this.logo = r.payload.logoLink;
          } else {
            this.logo = DEFAULT_LOGO;
          }
        })
      ).subscribe());
    }
  }
}
