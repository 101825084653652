import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy , formControlErrorKeys, formControlErrorMessage, noSpaceValidator } from '@portal-workspace/grow-ui-library';
import { tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {compareMatch, getIdTypes, IdentificationComponentValue, IdTypes, removeUnprintableChar} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'identification',
    templateUrl: './identification.component.html',
    styleUrls: ['./identification.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IdentificationComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => IdentificationComponent) },
    ],
    standalone: true,
    imports: [FormsModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective]
})
export class IdentificationComponent extends AbstractControlValueAccessor<IdentificationComponentValue> implements OnInit, AfterContentChecked, Mark {

  @Input() mandatory = true;
  
  formGroup!: FormGroup<{
    idType: FormControl<string|null>,
    idNumber: FormControl<string|null>
  }>;
  formControlIdType!: FormControl<string|null>;
  formControlIdNumber!: FormControl<string|null>;
  
  idTypes: IdTypes[] = [];
  idNumberLabel: string = 'ID Number'
  
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  
  subscriptions: Subscription[] = [];
  
  constructor(private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.formControlIdType = this.formBuilder.control('', Validators.required);
    this.formControlIdNumber = this.formBuilder.control('', [Validators.required, Validators.minLength(5)]);

    this.formGroup = this.formBuilder.group({
      idType: this.formControlIdType,
      idNumber: this.formControlIdNumber
    });

    this.idTypes = getIdTypes();
    console.log('id types', this.idTypes)

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(
        tap(value => {
          this.updateIdNumberLabel();
          if (this.formGroup.valid && this.formControlIdNumber.value && this.formControlIdType.value) {
            this.propagateChange({
              idNumber: this.formControlIdNumber.value,
              idType: this.formControlIdType.value
            });
          } else {
            this.propagateChange(null);
          }
        })
      ).subscribe()
    )
  }

  updateIdNumberLabel() {
    switch (this.formControlIdType.value) {
      case 'drivers_licence':
        this.idNumberLabel = 'Licence Number';
        break;
      case 'passport':
        this.idNumberLabel = 'Passport Number';
        break;
      default:
        this.idNumberLabel = 'ID Number';
    }
  }


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  doWriteValue(v: IdentificationComponentValue) {
    if(v) {
      this.formControlIdNumber.setValue(v.idNumber)
      this.formControlIdType.setValue(v.idType);
    }
  }

  mark() {
    this.formGroup.markAllAsTouched();
  }

}
