import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { DEFAULT_LIMIT, DEFAULT_OFFSET, TransactionFilter, compare, BankStatementTransactionRow } from '@portal-workspace/grow-shared-library';
import { Subscription, debounceTime, tap } from 'rxjs';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { Sort, MatSortModule } from '@angular/material/sort';
import { FormArray, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatDividerModule } from '@angular/material/divider';
import { setupUntilDestroy } from '../component-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import moment from 'moment';

export interface BankStatementSelectTransactionsDialogData {
  transactions: BankStatementTransactionRow[];
}

export interface BankStatementSelectTransactionsDialogResult {
  readyForSubmission: boolean;
  selectedTransactions: BankStatementTransactionRow[];
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './bank-statement-select-transactions.dialog.html',
  styleUrls: ['./bank-statement-select-transactions.dialog.scss'],
  standalone: true,
  imports: [
    MatDialogModule, FlexModule, MatButtonModule, MatDividerModule, CustomPaginatorComponent, ExtendedModule, MatTableModule, MatCheckboxModule, MatOptionModule, MatSelectModule, MatInputModule, MatFormFieldModule, NgClass, LooseCurrencyPipe, MatSortModule, FormsModule, ReactiveFormsModule
  ]
})
export class BankStatementSelectTransactionsDialog implements OnInit {
  columnsToDisplay: string[] = ['selector', 'date', 'category', 'party', 'transaction', 'credit', 'debit'];
  dataSource: BankStatementTransactionRow[] = [];
  filteredDataSource: BankStatementTransactionRow[] = [];
  displayedData: BankStatementTransactionRow[] = [];

  toString = toString;
  limit!: number;
  offset!: number;
  total!: number;
  filter: string[] = [];
  subscriptions: Subscription[] = [];
  formControlSearch!: FormControl<string | null>;
  formControlCategory!: FormControl<string | null>;
  categoryOptions: string[] = [];
  transactionFilter: TransactionFilter = {};

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: BankStatementSelectTransactionsDialogData,
    private dialogRef: MatDialogRef<BankStatementSelectTransactionsDialog, BankStatementSelectTransactionsDialogResult>,
  ) {
    this.formControlSearch = this.formBuilder.control(null);
    this.formControlCategory = this.formBuilder.control(null);
    this.dataSource = data.transactions.sort((a, b) => a.date > b.date && -1 || 1);;
    this.filteredDataSource = this.dataSource;
    this.initPagination();
    this.initTransactionFilter();
    this.initCategories();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(
      this.formControlSearch.valueChanges.pipe(
        debounceTime(1000),
        tap((r: string | null) => {
          this.onSearch();
        })
      ).subscribe()
    )

    this.subscriptions.push(
      this.formControlCategory.valueChanges.pipe(
        tap((r: string | null) => {
          this.onFilterCategory();
        })
      ).subscribe()
    )
  }

  initTransactionFilter() {
    this.dataSource.forEach(transaction => {
      this.transactionFilter[String(transaction.id)] = new FormControl<boolean>(false);
    })
  }

  displayColumnValue(element: BankStatementTransactionRow, column: string): string {
    if (column == 'date') {
      return moment(element.date).format('DD/MM/YYYY')
    }
    else if (column == 'transaction') {
      return element.transaction
    }
    else if (column == 'credit') {
      return element.credit ? element.credit.toString() : ''
    }
    else if (column == 'debit') {
      return element.debit ? element.debit.toString() : ''
    }
    else if (column == 'category') {
      return element.category;
    }
    else if (column == 'party') {
      return element.party ?? '';
    }
    else {
      return 'N/A';
    }
  }

  initPagination() {
    this.total = this.filteredDataSource.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  initCategories() {
    this.categoryOptions = ['All', ...new Set(this.dataSource.map(t => t.category ?? ''))].sort((a, b) => a < b ? -1 : 1);
  }

  updateDisplayedData() {
    this.displayedData = this.filteredDataSource.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onSearch() {
    const value = this.formControlSearch.value ? this.formControlSearch.value.trim().toLowerCase() : '';
    if (value) {
      this.filteredDataSource = this.dataSource.filter(data => {
        const s = (data.date + data.transaction + data.credit.toString() +
          data.debit.toString() + data.party + data.category + data.otherInfo).toLowerCase();
        return s.includes(value);
      })
    } else {
      this.filteredDataSource = this.dataSource;
    }
    this.initPagination();
  }

  onFilterCategory() {
    if (this.formControlCategory.value === 'All') {
      this.filteredDataSource = this.dataSource;
    } else {
      this.filteredDataSource = this.dataSource.filter(data => data.category === this.formControlCategory.value);
    }
    this.initPagination();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'selector': return '';
      case 'date': return 'Date';
      case 'transaction': return 'Transaction';
      case 'debit': return '$ Debit';
      case 'credit': return '$ Credit';
      case 'party': return 'Party';
      case 'category': return 'Category';
      case 'otherInfo': return 'Other Info';
      default: return column;
    }
  }
  
  onSort(sort: Sort) {
    const data = this.filteredDataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredDataSource = data;
      return;
    }

    this.filteredDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'category':
          return compare(a.category ?? '', b.category ?? '', isAsc);
        case 'party':
          return compare(a.party ?? '', b.party ?? '', isAsc);
        case 'transaction':
          return compare(a.transaction, b.transaction, isAsc);
        case 'credit':
          return compare(a.credit, b.credit, isAsc);
        case 'debit':
          return compare(a.debit, b.debit, isAsc);
        default:
          return 0;
      }
    });

    this.updateDisplayedData();
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    this.offset = $event.pageIndex;
    this.updateDisplayedData();
  }

  needCurrencyPipe(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  needAlignRight(column: string) {
    return ['debit', 'credit'].includes(column);
  }

  onCancel() {
    this.dialogRef.close()
  }

  onSubmit() {
    const selectedTransactions = this.dataSource.filter(transaction => this.transactionFilter[String(transaction.id)].value);
    this.dialogRef.close({
      readyForSubmission: true,
      selectedTransactions,
    })
  }

  toClass(category: string) {
    // || categories.includes('')
    const categories = category ? category.split(',').map(c => _.toLower(c.trim().replace(/\s/g, '-'))) : [];
    if (categories.includes('wages') || categories.includes('wages-paid-out') || categories.includes('eftpos-terminals')) {
      return 'type-1';
    } else if (categories.includes('loans') || categories.includes('non-sacc-loans')) {
      return 'type-2';
    } else if (categories.includes('other-debits') || categories.includes('uncategorised-debits') || categories.includes('third-party-payment-providers')) {
      return 'type-3';
    } else if (categories.includes('tax') || categories.includes('atm') || categories.includes('refunds-and-returns') || categories.includes('other-income') || categories.includes('government-and-council-services') || categories.includes('subscription-tv') || categories.includes('travel')) {
      return 'type-4';
    } else if (categories.includes('online-retail-and-subscription-services')) {
      return 'type-5';
    } else if (categories.includes('external-transfers')) {
      return 'type-6';
    } else if (categories.includes('superannuation')) {
      return 'type-7';
    } else if (categories.includes('fees')) {
      return 'type-8';
    } else if (categories.includes('utilities')) {
      return 'type-9';
    } else if (categories.includes('groceries')) {
      return 'type-10';
    } else if (categories.includes('dining-out') || categories.includes('education') || categories.includes('automotive') || categories.includes('health') || categories.includes('department-stores') || categories.includes('retail') || categories.includes('home-improvement') || categories.includes('entertainment') || categories.includes('internal-transfer') || categories.includes('pet-care')) {
      return 'type-11';
    } else if (categories.includes('insurance')) {
      return 'type-12';
    } else if (categories.includes('telecommunications')) {
      return 'type-13';
    } else if (categories.includes('transport')) {
      return 'type-14';
    } else if (categories.includes('all-other-credits')) {
      return 'type-15';
    } else {
      return ''
    }
  }

  toggleAllSelection() {
    this.filteredDataSource.forEach(transaction => {
        this.transactionFilter[String(transaction.id)].setValue(true);
    })
  }
}
