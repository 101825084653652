import {Injectable, inject, DestroyRef} from "@angular/core";
import {ChangePortalThemeFn, PortalTheme, PortalThemeServiceEvent} from "@portal-workspace/grow-shared-library";
import {getPortalThemeFromStorage, setupUntilDestroy, storePortalTheme} from "@portal-workspace/grow-ui-library";
import {BehaviorSubject, Subscription} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";


@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class PortalThemeService {

  subject = new BehaviorSubject<PortalThemeServiceEvent>({
    type: 'themeChange',
    portalTheme: this.getPortalTheme(),
  });

  subscriptions: Subscription[] = [];

  constructor() {
    setupUntilDestroy(this);
    this.subscriptions.push(this.subject.asObservable().pipe(
      tap((evt) => {
        if (evt) {
          switch(evt.type) {
            case 'themeChange': {
              const theme = evt.portalTheme;
              if (!window.document.body.classList.contains(`${theme}-theme`)) {
                window.document.body.classList.remove(`light-theme`, `dark-theme`);
                window.document.body.classList.add(`${theme}-theme`);
              }
              break;
            }
          }
        }
      })
    ).subscribe());
  }

  changePortalThemeFn: ChangePortalThemeFn = (theme: PortalTheme) => {
    this.changePortalTheme(theme);
  }

  changePortalTheme(theme: PortalTheme) {
    storePortalTheme(theme);
    this.subject.next({
      type: 'themeChange',
      portalTheme: theme,
    });
  }

  getPortalTheme(): PortalTheme {
    const theme =  getPortalThemeFromStorage();
    return theme;
  }

  loadFromLocalStorageAndTriggerThemeEvent() {
    const theme =  getPortalThemeFromStorage();
    this.subject.next({
      type: 'themeChange',
      portalTheme: theme,
    });
  }

}
