<div class="upload-file-with-tag-dialog">
<!--   <button class='custom-button' (click)="onUploadDoc()">
    <span class="mdi mdi-tray-arrow-up icon" matPrefix></span>
    <span class="text">{{buttonText}}</span>
  </button>
  <div class="uploaded-files">
    @for (doc of docs; track doc; let i = $index) {
      <div class="uploaded-file row">
        <span class="mdi mdi-attachment"></span>
        <div class="file-name">
          <span class="ellipsis">{{doc.name}}</span>
          <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row wrap">
            @for (tag of getFileTags(doc); track tag; let j = $index) {
              <span class="date-text">{{tag}}</span>
              @if (j !== getFileTags(doc).length - 1) {
                <span class="date-text">&#183;</span>
              }
            }
          </div>
        </div>
        <div fxLayoutAlign="end center">
          <span class="mdi mdi-close cursor-pointer" (click)="onDeleteDoc(i)"></span>
        </div>
      </div>
    }
  </div> -->

  <div class="upload-file-with-tags-dialog">
      <div class="row">
        <div class="col">
          <upload-file
            title="Upload other supporting documents"
            [allTags]="defaultTags"
            [showMaxTagErrorMessage]="showMaxTagErrorMessage"
            [maxFilesAllowed]="100"
            [subMessage]="''"
            [isShowFlieList]="false"
            [formControl]="formControl"
            [allowMultiple]="true"
            
            ></upload-file>

            <div class="uploaded-files">
                @for (file of files; track file) {
                  <mat-card appearance="outlined" class="card" [ngClass]="{invalid: !hasTagsFile(file)}">
                    <mat-card-content>
                      <div class="uploaded-file" fxLayout="row" fxLayoutAlign="start center">
                        <div class="col-8" fxLayoutAlign="start center">
                          <span class="mdi mdi-attachment"></span>
                          <span class="ellipsis"  matTooltip="{{file.name }}">{{file.name}}  </span>
                        </div>
              
                          <div class="col-4" fxLayoutAlign="end center">
                            <button mat-flat-button color="primary" class="add-tag-btn"
                              (click)="editFile($event, file)">Add Tag</button>
                            <span class="mdi mdi-close cursor-pointer" (click)="deleteFile($event, file)"></span>
                          </div> 
                      </div>
                      
                        <div class="col selected-chips uploaded-file" fxLayoutAlign="start center">
                            <mat-chip-listbox #chipList aria-label="Tag selection">
                              @for (tag of getTagsByFileName(file); track tag) {
                                <mat-chip-option
                                  (removed)="removeSingleTagFromFile(file,tag)">
                                  {{tag.type}}
                                  <button matChipRemove>
                                    <span class="mdi mdi-close-circle-outline"></span>
                                  </button>
                                </mat-chip-option>
                              }
                            </mat-chip-listbox>
                          </div>
                    </mat-card-content>
                  </mat-card>
                  @if (!hasTagsFile(file)) {
                  
                      <div class="mt-2 mb-2">
                        <mat-error>Tag is required</mat-error>
                      </div>
                   
                  }
                }
                
            </div>
        </div>
      </div>

      <!-- <div class="row separator-top">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
          @if (tags) {
            <button mat-flat-button color="primary" [disabled]="formGroup.invalid || !(tags.length)" (click)="onSubmit($event)">Save Documents</button>
          }
        </div>
      </div> -->
     
  </div>
  
</div>
