<div class="bank-statement-overview">
  <div class="row mb-4">
    <div class="col-12">
      <div fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="10px"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button color="primary"
          (click)="onConfirmAccountSelection()"
          class="w-lt-md-100">
          Confirm Account Selection
        </button>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{
            'column-width-3': column === 'BankName' || column === 'BSBAccNumber',
            'column-width-2': column === 'AccHolder' || column === 'AccName' || column === 'currentBalance' || column === 'TotalCredit',
            'column-width-1': column === 'LoanCreditFlag',
            'column-width-icon': column === 'icon' || column === 'selector',
            'text-align-right': needAlignRight(column)
          }">     
              <div class="cell">{{getColumnTitles(column)}}</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'text-align-right': needAlignRight(column)}" (click)="onClickRow(element)">
              @if (column === 'icon') {
                <div class="cell">
                  @if (expandedElement === element) {
                    <span class="mdi mdi-chevron-up"></span>
                  }
                  @if (expandedElement !== element) {
                    <span class="mdi mdi-chevron-down"></span>
                  }
                </div>
              }
              @if (column === 'selector') {
                <div class="cell">
                  <mat-checkbox color="primary" [formControl]="accountFilter[element.id]"></mat-checkbox>
                </div>
              }
              @if (column !== 'icon' && column !== 'selector') {
                <div class="cell" (click)="onClickRow(element)">
                    @if (needCurrencyPipe(column)) {
                        {{getColumnValues(element, column) | looseCurrency}}
                    }
                    @if (!needCurrencyPipe(column)) {
                      {{getColumnValues(element, column)}}
                    }
                </div>
              }
            </td>
          </ng-container>
        }

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element; let i = dataIndex" class="expanded-row" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row mt-3">
                <div class="col">
                  <div class="mat-h4-custom">Transaction Summary</div>
                  <transaction-summary [data]="element"></transaction-summary>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <div class="mat-h4-custom">Decision Metrics</div>
                  <decision-metrics [data]="element"></decision-metrics>
                </div>
              </div>
              <!-- <div class="row mt-3">
                <div class="col">
                  <div class="mat-h4-custom">Day End Balance Chart</div>
                  <day-end-balance-chart [data]="element.dayEndBalances" [index]="i"></day-end-balance-chart>
                </div>
              </div> -->
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (dblclick)="viewAccountTransactions(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>

  <!-- <div class="mt-4">
    <div class="mat-h4-custom">Monthly Revenue</div>
    @if (data && data.revenue) {
      <monthly-revenue-table [data]="data.revenue"></monthly-revenue-table>
      <div class="mt-4">
        <monthly-revenue-chart [data]="data.revenue"></monthly-revenue-chart>
      </div>
    }
  </div> -->
  @if (data && data.Dishonours) {
  <div class="mt-4">
    <div class="mat-h4-custom">Dishonours</div>
      @if (!isEmpty(data.Dishonours, 'dishonours')) {
        <dishonours-table [data]="data.Dishonours.summary"></dishonours-table>
        <div class="mat-h4-custom sub-title">Dishonour Transactions</div>
        <transaction-table [data]="data.Dishonours.transactions" [type]="'dishonour'"></transaction-table>
      }
      @if (isEmpty(data.Dishonours, 'dishonours')) {
        <span class="notes">No dishonour transactions.</span>
      }
    </div>
  }
  @if (data && data.Overdrawn) {
  <div class="mt-4">
    <div class="mat-h4-custom">Overdrawn</div>
      @if (!isEmpty(data.Overdrawn, 'overdrawn')) {
        <overdrawn-table [data]="data.Overdrawn.summary"></overdrawn-table>
        <div class="mat-h4-custom sub-title">Overdrawn Transactions</div>
        <transaction-table [data]="data.Overdrawn.transactions" [type]="'overdrawn'"></transaction-table>
      }
    @if (isEmpty(data.Overdrawn, 'overdrawn')) {
      <span class="notes">No overdrawn transactions.</span>
    }
  </div>
}
@if (data && data.LenderList) {
  <div class="mt-4">
    <div class="mat-h4-custom">Non Preferred Lender Summary</div>
      @if (!isEmpty(data.LenderList, 'non-preferred-lender')) {
        <non-preferred-lender-summary [data]="data.LenderList.summary"></non-preferred-lender-summary>
        <div class="mat-h4-custom sub-title">Non Preferred Lender Transactions</div>
        <transaction-table [data]="data.LenderList.transactions" [type]="'non-preferred-lender'"></transaction-table>
      }
    @if (isEmpty(data.LenderList, 'non-preferred-lender')) {
      <span class="notes">No relationship with non-preferred lenders.</span>
    }
  </div>
}
  <!-- <div class="mt-4">
    <div class="mat-h4-custom">Total Monthly Debits / Credits</div>
    @if (data && data.accounts[0].monthlyBalance) {
      <monthly-debits-credits-table [data]="data.accounts[0].monthlyBalance"></monthly-debits-credits-table>
      <div class="mt-4">
        <monthly-debits-credits-chart [data]="data.accounts[0].monthlyBalance"></monthly-debits-credits-chart>
      </div>
    }
  </div> -->

  <div class="mt-4">
    <div class="mat-h4-custom">Categories</div>
    @if (data && data.transactionsByCategory) {
      <categories-table [data]="data.transactionsByCategory"
        [accounts]="data.accounts"
        [selectedAccounts]="transactionListSelectedAccounts">
      </categories-table>
    }
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="mat-h4-custom">Full Transaction List</div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12" id="transaction-list">
      @if (data && data.transactionsByCategory) {
        <full-transaction-list 
          [transactionsByCategory]="data.transactionsByCategory" 
          [accounts]="data.accounts"
          [selectedAccounts]="transactionListSelectedAccounts"
        ></full-transaction-list>
      }
    </div>
  </div>
</div>
