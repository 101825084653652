import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  OnDestroy,
  forwardRef,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";
import {CdkStep, CdkStepper, CdkStepperModule, StepperSelectionEvent} from "@angular/cdk/stepper";
import {MatButtonModule} from "@angular/material/button";
import {NgTemplateOutlet} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FlexModule} from "@angular/flex-layout/flex";
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  ApplicationDialogService, createCurrencyInputMask, doMarkAll as componentUtilDoMarkAll,
  doMarkAll,
  fieldErrorMessages,
  markTriggerSubject,
  setupUntilDestroy
} from '@portal-workspace/grow-ui-library';
import {Subscription} from "rxjs";
import {Directionality} from "@angular/cdk/bidi";
import {tap} from "rxjs/operators";
import {AbstractControl, FormGroup} from "@angular/forms";
import _ from 'lodash';

export interface Stepper2StepConfig {
  // when "this step" is entered, this will be triggered
  stepSelectionEventFn?: (e: StepperSelectionEvent) => void;
  // when previous step button of "this step" is clicked, this will be triggered
  previousStepClickedFn?: (e: CdkStepper) => void;
  // when next step button  of "this step" is clicked, this will be triggered
  nextStepClickedFn?: (e: CdkStepper) => void;
  // to show or hide the previous button of "this step", default to true
  showPreviousStepButton?: boolean,
  // to show or hide the next button of "this step", default to true
  showNextStepButton?: boolean,
  // the next step button text of "this step", default to "Next"
  nextStepButtonText?: string,
  // the previous step button text of " this step", default to "Back"
  previousStepButtonText?: string,
  // if exists will popup this confirmation text on a dialog before going to next step
  nextStepConfirmationText?: string,
  // if  exists will popup this confirmation text on a dialog before going to previous step
  previousStepConfirmationText?: string,

  // element selector, if present, result in next and prev step to scroll to
  scrollToParentElementSelector?: string,

  // to show or hide the Secondary button of "this step", default to true
  showSecondarySubmitButton?: boolean,
  // the secondary button text of "this step", default to "Next"
  secondaryButtonText?:string,
  // when previous step button of "this step" is clicked, this will be triggered
  secondaryButtonClickedFn?: (e: CdkStepper) => void;
}

export const setStepper2StepConfig = (stepFormGroup: FormGroup, stepConfig: Stepper2StepConfig) => {
  (stepFormGroup as any)['currentStepConfig'] = stepConfig;
}

const getCurrentStepConfig = (step: CdkStep): Stepper2StepConfig | null => {
  if (step &&
    (step.stepControl as any)['currentStepConfig'] &&
    (typeof (step.stepControl as any)['currentStepConfig'] === 'object')) {
    return (step.stepControl as any)['currentStepConfig'];
  }
  return null;
}


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: `application-stepper2`,
  templateUrl: './application-stepper2.component.html',
  styleUrls: ['./application-stepper2.component.scss'],
  standalone: true,
  exportAs: 'applicationStepper2',
  providers: [
    { provide: CdkStepper, useExisting: forwardRef(() => ApplicationStepper2Component) },
  ],
  imports: [
    FlexModule,
    MatProgressBarModule,
    MatCardModule,
    NgTemplateOutlet,
    MatButtonModule,
    CdkStepperModule,
  ]
})
export class ApplicationStepper2Component extends CdkStepper implements OnInit, AfterViewInit, OnDestroy {

  subscriptions: Subscription[] = [];

  markTriggerSubject = markTriggerSubject;

  progress = 0;

  currentStepNumber: number = 0;

  @Input({required: false}) showErrorDetailsInPopup = true;
  @Input({required: false}) showHeader = true;



  constructor(_dir: Directionality,
              _changeDetectorRef: ChangeDetectorRef,
              _elementRef: ElementRef<HTMLElement>,
              private dialogService: ApplicationDialogService) {
    super(_dir, _changeDetectorRef, _elementRef);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.selectionChange.pipe(
      tap((e: StepperSelectionEvent) => {
        const currentStepConfig = getCurrentStepConfig(e.selectedStep);
        if (currentStepConfig && currentStepConfig.stepSelectionEventFn) {
          const currentStepFn = currentStepConfig.stepSelectionEventFn;
          if (currentStepFn && typeof currentStepFn === 'function') {
            currentStepFn(e);
          }
        }
        const currentStep = e.selectedIndex + 1;
        this.updateProgress(currentStep);
      })
    ).subscribe());
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateProgress(1);
  }

  getPreviousButtonText() {
    if (!this.selected) {
      console.log(`cannot invoke getPreviousButtonText() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    if (currentStepConfig && currentStepConfig.previousStepButtonText) {
      return currentStepConfig.previousStepButtonText;
    } else {
      // if no text is configured, show "Back"
      return "Back";
    }
  }

  getSecondaryButtonText() {
    if (!this.selected) {
      console.log(`cannot invoke getPreviousButtonText() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    if (currentStepConfig && currentStepConfig.secondaryButtonText) {
      return currentStepConfig.secondaryButtonText;
    } else {
      // if no text is configured, show "Back"
      return "Back";
    }
  }

  getNextButtonText() {
    if (!this.selected) {
      console.log(`cannot invoke getNextButtonText() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    if (currentStepConfig && currentStepConfig.nextStepButtonText) {
      return currentStepConfig.nextStepButtonText;
    } else {
      // if no text is configured, show "Next"
      return "Next";
    }
  }


  isPreviousButtonVisible() {
    if (!this.selected) {
      console.log(`cannot invoke isPreviousButtonVisible() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig:Stepper2StepConfig | null  = getCurrentStepConfig(this.selected);

    if (currentStepConfig && !(_.isNil(currentStepConfig.showPreviousStepButton))) {
      return currentStepConfig.showPreviousStepButton;
    } else {
      // if none is configured for this step, just show it
      return true;
    }
  }

  isSecondarySubmitButtonVisible() {
    if (!this.selected) {
      console.log(`cannot invoke isSecondarySubmitButtonVisible() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);

    if (currentStepConfig && currentStepConfig.showSecondarySubmitButton) {
      return currentStepConfig.showSecondarySubmitButton;
    } else {
      // if none is configured for this step, just show it
      return false;
    }
  }

  isNextButtonVisible() {
    if (!this.selected) {
      console.log(`cannot invoke isNextButtonVisible() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    if (currentStepConfig && currentStepConfig.showNextStepButton) {
      return currentStepConfig.showNextStepButton;
    } else {
      // if none is configured for this step, just show the next step
      return true;
    }
  }

  updateProgress(currentStep: number) {
    setTimeout(() => {
      this.currentStepNumber = currentStep;
      const total = this.steps.length;
      this.progress = (currentStep/total * 100);
    });
  }


  invokeSecondaryButtonFn() {
    if (!this.selected) {
      console.log(`cannot invoke invokePrevStepFn() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    const fn = currentStepConfig?.secondaryButtonClickedFn;
    if (fn) { // we have a secondaryButtonClickedFn
      const formGroup = this.selected?.stepControl as FormGroup;
      const confirmationText = currentStepConfig?.previousStepConfirmationText
      if (confirmationText) {
        this.subscriptions.push(this.dialogService.openConfirmationDialog({
          message: `Confirm`,
          subMessage: confirmationText,
        }).afterClosed().pipe(
          tap(r => {
            if (r && r.readyForSubmission) {
              fn(this);
              this.scrollToTop();
            }
          })
        ).subscribe());
      } else {
        fn(this);
        this.scrollToTop();
      }
    }
  }

  invokePreviousStepFn() {
    if (!this.selected) {
      console.log(`cannot invoke invokePrevStepFn() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    const fn = currentStepConfig?.previousStepClickedFn;
    if (fn) { // we have a previousStepClickedFn
      const formGroup = this.selected?.stepControl as FormGroup;
      const confirmationText = currentStepConfig?.previousStepConfirmationText
      if (confirmationText) {
        this.subscriptions.push(this.dialogService.openConfirmationDialog({
          message: `Confirm`,
          subMessage: confirmationText,
        }).afterClosed().pipe(
          tap(r => {
            if (r && r.readyForSubmission) {
              fn(this);
              this.scrollToTop();
            }
          })
        ).subscribe());
      } else {
        fn(this);
        this.scrollToTop();
      }
    } else { // we do not have a previousStepClickedFn
      const confirmationText = currentStepConfig?.nextStepConfirmationText
      if (confirmationText) {
        this.subscriptions.push(this.dialogService.openConfirmationDialog({
          message: `Confirm`,
          subMessage: confirmationText,
        }).afterClosed().pipe(
          tap(r => {
            if (r && r.readyForSubmission) {
              this.previous();
              this.scrollToTop();
            }
          })
        ).subscribe());
      } else {
        this.previous();
        this.scrollToTop();
      }
    }
  }

  invokeNextStepFn() {
    if (!this.selected) {
      console.log(`cannot invoke invokeNextStepFn() on stepper2 no step selected`);
      return;
    }
    const currentStepConfig = getCurrentStepConfig(this.selected);
    const fn = currentStepConfig?.nextStepClickedFn;
    if (fn) { // we have a nextStepClickedFn
      const formGroup = this.selected?.stepControl as FormGroup;
      this.markTriggerSubject(formGroup).next(true);
      const errors = this.doMarkAll(formGroup);
      if (formGroup.valid) { // step form is valid
        const confirmationText = currentStepConfig?.nextStepConfirmationText
        if (confirmationText) {
          this.subscriptions.push(this.dialogService.openConfirmationDialog({
            message: `Confirm`,
            subMessage: confirmationText,
          }).afterClosed().pipe(
            tap(r => {
              if (r && r.readyForSubmission) {
                fn(this);
                this.scrollToTop();
              }
            })
          ).subscribe());
        } else {
          fn(this);
          this.scrollToTop();
        }
      } else {  // step form is invalid
        let subMessage = ''
        let message = 'Validation Error'
        if (formGroup.errors) {
          const errorType: string = Object.keys(formGroup.errors)[0]
          if (Object.keys(fieldErrorMessages).includes(errorType)) {
            message = 'Error'
            subMessage = (fieldErrorMessages as any)[errorType]
          }
        } else {
          console.log('stepper validation errors', errors);
          subMessage = this.showErrorDetailsInPopup ?
            `Please check the following validation issue: ${errors.join(', ')}` :
            `Please insert missing fields highlighted in red`;
        }
        this.dialogService.openAlertDialog({
          message: message,
          subMessage,
        });
      }
    } else { // we don't have a nextStepClickedFn
      const formGroup = this.selected?.stepControl as FormGroup;
      this.markTriggerSubject(formGroup).next(true);
      const errors = this.doMarkAll(formGroup);
      if (formGroup.valid) {  // step form is valid
        const confirmationText = currentStepConfig?.nextStepConfirmationText
        if (confirmationText) {
          this.subscriptions.push(this.dialogService.openConfirmationDialog({
            message: `Confirm`,
            subMessage: confirmationText,
          }).afterClosed().pipe(
            tap(r => {
              if (r && r.readyForSubmission) {
                this.next();
                this.scrollToTop();
              }
            })
          ).subscribe());
        } else {
          this.next();
          this.scrollToTop();
        }
      } else { // step form is invalid
        console.log("Form Group", formGroup)
        console.log('stepper validation errors', errors);
        const subMessage = this.showErrorDetailsInPopup ?
          `Please check the following validation issue: ${errors.join(', ')}`:
          `Please insert missing fields highlighted in red`;
        this.dialogService.openAlertDialog({
          message: 'Validation Error',
          subMessage,
        });
      }
    }
  }

  doMarkAll(a: AbstractControl, errors: string[] = [], k: string | null = null): string[] {
    return componentUtilDoMarkAll(a, errors, k);
  }

  scrollToTop() {
    if (this.selected) {
      const config = getCurrentStepConfig(this.selected);
      if (config && config.scrollToParentElementSelector) {
        const element = document.querySelector(config.scrollToParentElementSelector);
        if (element) {
          element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'instant' });
        }
      }
    }
  }

}
