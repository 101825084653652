import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../service/registration.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { clearAllStorage, getUser } from '@portal-workspace/grow-ui-library';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { tap } from 'rxjs/operators';
import { PortalHotToastService,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { MinimalLayoutService } from '../../layout/minimal-layout/minimal-layout.service';
import { navigationUrlForLogin, navigationUrlForNewApplication, navigationUrlForProfile } from '../../service/navigation-urls';
import { loadingFor } from '@ngneat/loadoff';
import { AuthService } from '../../service/auth.service';
import { TwoFactorAuthService } from '../../service/2fa.service';
import { User, isInternalUser } from '@portal-workspace/grow-shared-library';

import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { QRCodeModule } from 'angularx-qrcode';
import { AsyncPipe } from '@angular/common';
import {FlexLayoutModule} from "@angular/flex-layout";


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './reset-2fa-qr-code.page.html',
    styleUrls: ['./reset-2fa-qr-code.page.scss'],
    standalone: true,
    imports: [
    QRCodeModule,
    MatButtonModule,
    MatDividerModule,
    CustomContentLoaderComponent,
    AsyncPipe,
    FlexLayoutModule
]
})
export class ResetTwoFAQrCodePage implements OnInit {
  public verificationCode: string = "";
  code?: string;
  user !: User;
  verificationStatus: 'pending' | 'success' | 'failed' | 'verified' = 'pending';
  message = '';

  subscriptions: Subscription[] = [];
  loggedInUser = getUser();
  isInternalUser = isInternalUser(this.loggedInUser)
  inProgressLoader = loadingFor('InProgressUser');

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private TwoFA: TwoFactorAuthService,
    private toastService: PortalHotToastService,
    private minimalLayoutService: MinimalLayoutService,
    private registrationService: RegistrationService) {
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.user = getUser()!;
    if(this.loggedInUser){
      this.subscriptions.push(this.TwoFA.get2faTotpVerificationCodeUri().pipe(
        this.toastService.spinnerObservable(),
        tap(r => {
          if(r && r.payload){
              this.verificationCode = r.payload;
          }
        })
      ).subscribe());
    }
    

    this.code = this.route.snapshot.params.code as string;
    if (!!!this.code) {
      this.verificationStatus = 'failed';
    } else {
      const userId = this.getUserId(this.code);
      this.ifUserVerified(userId);
    }
    this.minimalLayoutService.settings({
      showPrev: false,
      showContact: true,
      showAccreditationInContact: false,
      showCross: false,
    });
  }

  getUserId(code: string) {
    const decodedString = atob(code);
    const decodedArray = decodedString.split('/');
    if (decodedArray && decodedArray.length) {
      return Number(decodedArray[decodedArray.length - 1]);
    } else {
      return 0;
    }
  }

  ifUserVerified(userId: number) {
    const sub = this.registrationService.getUserVerificationStatus(userId).pipe(
      this.inProgressLoader.InProgressUser.track()
    ).subscribe(response => {
      if (response && response?.payload && response?.payload?.verified) {
        this.verificationStatus = 'verified';
      }
    })
    this.subscriptions.push(sub);
  }

  onDone() {
    this.router.navigate(navigationUrlForNewApplication());
  }
  loginpageredirect(){
    this.router.navigate(navigationUrlForLogin());
  }

  // disable2FA() {

  //   const email = this.user?.Email;
  //   this.TwoFA.enableDisable2FA(email, 0).pipe(
  //     this.toastService.spinnerObservable(),
  //     this.toastService.snackBarObservable(`2FA disabled`),
  //     tap(r => {
  //       this.router.navigateByUrl(navigationUrlForProfile());
  //     })
  //   ).subscribe();


  // }
}
