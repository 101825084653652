<div class="business-finance-summary-component">
  @if (summary) {
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summary.repayment || summary.estimatedDrawDownDate) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>{{summary.paymentFrequency?.type ?? 'Monthly'}} repayments</mat-label>
              <input matInput class="ml-4" type="input" readonly name="monthlyRepayment" [ngModel]="summary.repayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Estimated draw down date</mat-label>
              <input matInput type="input" readonly name="estimatedDrawDownDate"
                [ngModel]="displayableDate(summary.estimatedDrawDownDate)">
              </mat-form-field>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Application ID</div>
              <div body>{{summary.applicationId}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Organisation</div>
              <div body>{{summary.organisation?.organisationName}}</div>
            </cell>
          </div>
          @if (summary.organisationType) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Organisation type</div>
                <div body>{{summary.organisationType.name}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Broker Contact</div>
              <div body>{{summary.brokerContact?.FirstName}} {{summary.brokerContact?.LastName}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>ABN</div>
              <div body>{{summary.abn}}</div>
            </cell>
          </div>
          @if (summary.organisationType?.name=='Company') {
            <div class="col-lg-3 col-md-12" >
              <cell>
                <div label>ACN</div>
                <div body>{{summary.acn}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Commercial premises</div>
              <div body>{{booleanToYesNo(summary.operatesInCommercialPremises)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summary.primaryIndustry) {
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Primary industry</div>
                <div body>{{summary.primaryIndustry.name}}</div>
              </cell>
            </div>
          }
          @if (summary.industrySector) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Industry sector</div>
                <div body>{{summary.industrySector.name}}</div>
              </cell>
            </div>
          }
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summary.primaryBusinessAddress) {
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Primary business address</div>
                <div body>{{summary.primaryBusinessAddress.address}}</div>
              </cell>
            </div>
          }
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Business landline</div>
              <div body>{{summary.businessLandline}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          @if (summary.loanPurpose) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Invoice amount</div>
                <div body>{{summary.loanPurpose.name}}</div>
              </cell>
            </div>
          }
          @if (summary.loanTerms) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Loan term (in months)</div>
                <div body>{{summary.loanTerms}}</div>
              </cell>
            </div>
          }
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Doc Fee Financed</div>
              <div body>{{booleanToYesNo(summary.docFeeFinanced)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Equifax Score above 600</div>
              <div body>{{booleanToYesNo(summary.equifaxScoreAboveThreshold)}}</div>
            </cell>
          </div>
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Adverse on file</div>
              <div body>{{booleanToYesNo(summary.adverseOnFile)}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <!-- DIRECTOR -->
        <div class="mt-3">
          <directors-summary [directors]="_directors"></directors-summary>
        </div>
        <!-- TRUSTEE -->
        <div class="mt-3">
          <trustees-summary [trustees]="_trustee"></trustees-summary>
        </div>
        <!-- PARTNER -->
        <div class="mt-3">
          <partners-summary [partners]="_partners"></partners-summary>
        </div>
        <!-- MEMBER -->
        <div class="mt-3">
          <members-summary [members]="_members"></members-summary>
        </div>
        <!-- SOLE TRADER -->
        <div class="mt-3">
          <soletraders-summary [soletraders]="_soleTraders"></soletraders-summary>
        </div>
        <!-- GUARANTOR -->
        <div class="mt-3">
          <guarantors-summary [guarantors]="summary.guarantors"></guarantors-summary>
        </div>
        <div class="mt-3">
          <primary-contact-summary [primaryContact]="summary.primaryContact"></primary-contact-summary>
        </div>
        @if (summary.applicationNotes) {
          <div class="row">
            <div class="col-12 mt-3">
              <div class="mat-h3">Application notes</div>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-lg-6 col-md-12">
            {{summary.applicationNotes}}
          </div>
        </div>
      </div>
    }
  </div>
