<div class="consumer-dscr-calculator-component">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Monthly Repayment"
            [disableControl]="true"
            [formControl]="formControlMonthlyRepayment"
          />
        </div>
        <div class="col-lg-3 col-md-12">
          <postcode
            [disableControl]="true"
            [formControl]="formControlPostcode"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <maritial-status-selection
            [formControl]="formControlMaritialStatus" />
        </div>
        <div class="col-lg-3 col-md-12">
          <number-input
            title="Number of dependents"
            [formControl]="formControlNumberOfDependents" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <employment-status-selection
            [formControl]="formControlEmploymentStatus"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <currency-with-period
            title=""
            titleCurrency ="Regular Income"
            [formControl]="formControlRegularIncome"/>
        </div>
      </div>

      <!-- Spouse or Partner -->
      <div class="row separator-bottom">
        <div class="col-12">
          <span class="mat-subtitle-2">Spouse / Partner</span>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-3 col-md-12">
          <mat-checkbox
            color="primary"
            [formControl]="formControlHasSpouseOrPartner">
            Spouse or partner
          </mat-checkbox>
        </div>
      </div>
      @if (formControlHasSpouseOrPartner.valueChanges | async) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <currency-with-period
              title=""
              titleCurrency="Spouse or Partner income"
              [formControl]="formControlSpouseRegularIncome"/>
          </div>
        </div>
      }

      <!-- Monthly Living Expanses -->
      <div class="row separator-bottom">
        <div class="col-12">
          <span class="mat-subtitle-2">Living Expanses</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Home Loans"
            [formControl]="formControlHomeLoans"/>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Car Loans"
            [formControl]="formControlCarLoans"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Total Credit Card Limits"
            [formControl]="formControlTotalCreditCardLimits"/>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Electricity and Gas"
            [formControl]="formControlElectricityAndGas"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Other Utilities"
            [formControl]="formControlOtherUtilities"/>
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Education"
            [formControl]="formControlEducation"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Personal Loan"
            [formControl]="formControlPersonalLoan"
            />
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="groceries"
            [formControl]="formControlGroceries"
            />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Other Loan"
            [formControl]="formControlOtherLoan"
            />
        </div>
        <div class="col-lg-3 col-md-12">
          <currency-input
            title="Insurance"
            [formControl]="formControlInsurance"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <percentage-input
            title="Share of Monthly Living Expenses"
            [formControl]="formControlShareOfMonthlyLivingExpenses"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <button
            class="w-lt-md-100"
            mat-stroked-button
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="calculate($event)"
            matTooltip="Perform Consumer DSCR calculation">
            Calculate
          </button>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="dscrResult; context: {$implicit: dscrTableDataSource}"></ng-container>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #dscrResult let-o="$implicit">
  <div class="separator-top">
    @if ((loader.dscrCalcuation.inProgress$ | async)) {
      <list-content-loader></list-content-loader>
    } @else if (dscrTableDataSource) {
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <table mat-table [dataSource]="dscrTableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                @if(element.name === 'DSCR'){
                <span class="dscr-color">{{element.name}}</span>
                }@else {
                  <span>{{element.name}}</span>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Value</th>
              <td mat-cell *matCellDef="let element">
                @if(element.name === 'DSCR'){
                  <span class="dscr-color">{{element.value}}</span>
                  }@else {
                    <span>{{element.value}}</span>
                  }</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
          </table>
        </div>
      </div>
    }
  </div>
</ng-template>
