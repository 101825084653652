export type TransactionType = 'Dealer Sale' | 'Private Sale' | 'Sales and Lease Back' | 'Capital Raise' | 'Refinance' | 'Balloon Refinance' | 'Internal Re-write';
export type TransactionValue = {internalAccess: boolean, type: TransactionType , name: string} | null;
export const TransactionValueOptions: Exclude<TransactionValue, null>[] = [
  {internalAccess: true, type: 'Internal Re-write', name: 'Internal Re-write'},
  {internalAccess: false, type: 'Dealer Sale', name: 'Dealer Sale' },
  {internalAccess: false, type: 'Private Sale', name: 'Private Sale'},
  {internalAccess: false, type: 'Sales and Lease Back', name: 'Sales and Lease Back'},
  {internalAccess: false, type: 'Capital Raise', name: 'Capital Raise'},
  {internalAccess: false, type: 'Refinance', name: 'Refinance'},
  {internalAccess: false, type: 'Balloon Refinance', name: 'Balloon Refinance'},
];

export type PrivateSaleType = 'Yes' | 'No' | 'Sales and Lease Back' | 'Capital Raise' | 'Refinance' | 'Balloon Refinance' | 'Internal Re-write';

export const TransactionValueOptionsForConsumer: Exclude<TransactionValue, null>[] = [
  {internalAccess: false, type: 'Dealer Sale', name: 'Dealer Sale' },
  {internalAccess: false, type: 'Private Sale', name: 'Private Sale'},
  {internalAccess: false, type: 'Capital Raise', name: 'Capital Raise'},
  {internalAccess: false, type: 'Refinance', name: 'Refinance'},
  {internalAccess: false, type: 'Balloon Refinance', name: 'Balloon Refinance'},
];
