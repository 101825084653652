import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ConsumerFinanceApplication,
  CreateApplicationNoteFn,
  InsurancePremiumApplication,
  isInternalUser,
  User,
  DigitalIdGetApplicationIndividualsFn,
  RemoveApplicationNoteByNoteIdFn,
  GetRateCardDetailsFn,
  RatecardDetails,
  getLoanTerms,
  AppCalculator,
  getLoanAmount,
  getBrokerageAmount,
  InsurancePremiumCalculatorData,
  getTotalAmount,
  RefreshBankStatementFn,
  GetUserFn,
  DownloadDocumentFromAzureFn,
  GetBankStatementsAnalysisFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  SendPrivacyConsentEmailFn,
  UpdateApplicationStageInSfFn,
  GetApplicationByIdFn,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  GetApplicationOwnerFn, AssetSettlementGetApplicationAssetFn, GetDisbursementByApplicationIdFn, SettleLoanFn, GetBsaLenderListFn, GetBsaExcludedLenderListFn, GetDscrCalculatorValueFn, UpdateDscrCalculatorValueFn, SaveBsaCalculatorFn, GetBsaCalculatorFn, GetDscrCalculatorHistoryFn, GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, GetBankStatementAndBasiqDataStatusFn,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import {ApplicationSelectionType, primaryCommercialEntity, getApplicationStage} from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn, DeclineApplicationDocumentFn, DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn, DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn, DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn, DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn, DownloadAllApplicationDocumentUrlFn, DownloadApplicationDocumentUrlFn,
  GetOriginatorByIdFn, ListApplicationDocumentFn,
  BusinessSearchFn,
  SendApplicationDocumentNotificationFn, UpdateApplicationDocumentTagsFn, UpdateApplicationRequiredDocumentsFn,
  CreateDocumentWorklistFn, CompleteDocumentWorklistFn, GetNotesByApplicationIdFn,
  BusinessNumberSearchFn,
  UndoApplicationDocumentFn, AssetDocumentsComponent, CorporateLoansDocumentsComponent,
} from '@portal-workspace/grow-ui-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {GetUsersFunc, UserSelectionComponentEvent} from '@portal-workspace/grow-ui-library';
import {getUser } from '@portal-workspace/grow-ui-library';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import _ from 'lodash';
import { InsuranceDocumentsComponent } from './insurance-documents.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { InsuranceAppComponent } from './insurance-app.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { BankComponent } from '../bank.component';
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';


export type InsuranceTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'insurance-premium-application-details',
  templateUrl: './insurance-premium-application-details.component.html',
  styleUrls: ['./insurance-premium-application-details.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    InsuranceAppComponent,
    KycVerificationComponent,
    InsuranceDocumentsComponent,
    BankComponent,
    AssetDocumentsComponent,
    CorporateLoansDocumentsComponent,
    ApplicationTypeIconComponent,
  ]
})
export class InsurancePremiumApplicationDetailsComponent {

  tabs: InsuranceTab[] = [];

  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'quote', 'bank-statement', 'documents', 'kyc-aml'];

  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input({required: true}) sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) application!: InsurancePremiumApplication;

  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: false}) initialSelection: ApplicationSelectionType | null = null;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Output() selectionEvent: EventEmitter<ApplicationSelectionType> = new EventEmitter<ApplicationSelectionType>();

  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;


  breadcrumbTrails: string[] = ['Applications'];
  loggedInUser: User | null = getUser();
  subscriptions: Subscription[] = [];
  data!: InsurancePremiumCalculatorData;


  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};

  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex: number = 0;

  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;

  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();

  isInternalUser = isInternalUser;
  getApplicationStage = getApplicationStage;

  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index:(this.tabs.length)});
    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
  }

  // getApplicationTabIndex(tab: ApplicationSelectionType) {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  //   // switch (tab) {
  //   //   case 'app':
  //   //     return 0;
  //   //   case 'bank-statement':
  //   //     return 1;
  //   //   case 'kyc-aml':
  //   //     return 2
  //   //   case 'documents':
  //   //     return 3
  //   //   // case 'asset':
  //   //   // case 'add-asset':
  //   //   //   return 4
  //   //   // case 'lvr-calculator':
  //   //   //   return 5;
  //   //   default:
  //   //     return 0;
  //   // }
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    // const isInternalUser = this.isInternalUser(this.loggedInUser);
    // let selection: ApplicationSelectionType = 'app';
    // switch (tabIndex) {
    //   case 0:
    //     selection = 'app';
    //     break;
    //   case 1:
    //     selection = 'bank-statement';
    //     break;
    //   case 2:
    //     selection = 'kyc-aml';
    //     break;
    //   case 3:
    //     selection = 'documents';
    //     break;
    // }
    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit(this.currentSection);
  }

  ngOnInit() {
    this.initTabs();
    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }

    this.subscriptions.push(
      this.getRatecardDetailsFn(this.application.UserId, 'InsurancePremium').pipe(
        tap((r: RatecardDetails) => {
          const rateCard = r;
          const loanTerms = getLoanTerms(this.application);
          const totalAmount = Number(getTotalAmount(this.application));
          const brokerageAmountSf = getBrokerageAmount(this.application);

          const calculator = new AppCalculator();
          const terms = calculator.getTotalInterestRateForInsurancePremiumFinance(rateCard!, {totalPremiumAmount: totalAmount});

          const interestRate = terms.totalInterest / 100;
          const interestAmount = interestRate * totalAmount;
          const docFee = terms.docFee;
          const brokerageAmount = brokerageAmountSf !== undefined ? brokerageAmountSf : _.round((interestAmount * 20 / 100 ), 2);
          const repayment = _.round(totalAmount * (1  + interestRate) / loanTerms, 2);
          this.data = {
            monthlyInstallment: repayment,
            applicationFee: docFee,
            brokerage: brokerageAmount,
          };
          console.log(this.data)
        })
      ).subscribe()
    )
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }
}
