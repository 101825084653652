import { Component, Input, OnInit } from '@angular/core';
import { Application, BankStatement, BankStatementsAnalysisData, CreateApplicationNoteFn, GetBankStatementsAnalysisFn, GetBsaCalculatorFn, GetBsaExcludedLenderListFn, GetBsaLenderListFn, GetDscrCalculatorHistoryFn, GetDscrCalculatorValueFn, PayloadApiResponse, RefreshBankStatementFn, RemoveApplicationNoteByNoteIdFn, SaveBsaCalculatorFn, UpdateDscrCalculatorValueFn, User, isAdmin, isAnalyst, isInternalUser, GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, BasiqCompanyDetailsBody, getCompanyName, getAcn, getPrimaryBusinessAddress, primaryCommercialEntity, BasiqUserCustomerMapping, BasiqAccount, BasiqTransaction, RefreshBasiqConnectionsFn, ApiResponse } from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { ApplicationDialogService, GetNotesByApplicationIdFn, getAccessToken, getUser, applicationToPrimaryContact } from '@portal-workspace/grow-ui-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { getAbn } from '@portal-workspace/grow-shared-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomerAnalysisComponent } from '../bank-statement-component/customer-analysis.component';
import { AccountSummaryComponent } from '../bank-statement-component/account-summary.component';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageBoxComponent } from '../message-box/message-box.component';

import { MatCardModule } from '@angular/material/card';
import { Response } from 'request';
import { DscrCalculatorComponent } from '../application-details-component/dscr-calculator.component';
import { FlexModule } from "@angular/flex-layout";
import moment from 'moment';
import { BasiqFullTransactionListComponent } from './basiq-full-transaction-list.component';
import { BasiqStatementOverviewComponent } from './basiq-statement-overview.component';
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'basiq-statement',
  templateUrl: './basiq-statement.component.html',
  styleUrls: ['./basiq-statement.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatButtonModule,
    AccountSummaryComponent,
    CustomerAnalysisComponent,
    DscrCalculatorComponent,
    FlexModule,
    BasiqFullTransactionListComponent,
    BasiqStatementOverviewComponent,
    CustomContentLoaderComponent
  ]
})
export class BasiqStatementComponent implements OnInit {

  subscriptions: Subscription[] = [];

  @Input() apiUrl!: string;
  @Input() application!: Application;

  // ** Not needed for now
  @Input() getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input() refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({ required: true }) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({ required: true }) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({ required: true }) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({ required: true }) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({ required: true }) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({ required: true }) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({ required: true }) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  // ** //

  @Input() getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input() getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input() refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input() bankStatementsUrl!: string;
  @Input() reference!: string | null;

  selectedAccounts: string[] = [];

  salesforceId?: string | null;
  formControlBankStatementLink: FormControl<string | null>;
  getAbn = getAbn
  bankStatementAnalysisData!: BankStatementsAnalysisData
  hasBankStatement: boolean = false;
  isAdmin = isAdmin;
  isAnalyst = isAnalyst;
  isInternalUser = isInternalUser;
  loggedInUser: User | null = getUser();
  showCalculator: boolean = false;
  bankStatementMessage!: string;





  toggleCalculator() {
    this.showCalculator = !this.showCalculator;
  }

  // TODO: refactoring move all services out, replace with functions passed in as @Input()
  constructor(private formBuilder: FormBuilder,
    private applicationDialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService,) {
    this.formControlBankStatementLink = formBuilder.control(null);
  }

  ngOnInit(): void {
    console.log('basiq-statements application', this.application)
    // this.loadData();
  }

  onSelectAccounts(selectedAccounts: string[]) {
    const ref = this.applicationDialogService.openProgressSpinnerDialog();
    setTimeout(() => {
      ref.close();
    }, 3000);
    console.log(selectedAccounts);
    this.selectedAccounts = selectedAccounts;

    // this.subscriptions.push(
    //   this.saveBsaCalculatorFn({
    //     applicationId: this.application.ApplicationId,
    //     data: {
    //       selectedAccounts: this.selectedAccounts
    //     },
    //     saveDscrCalculator: false,
    //   }).pipe(
    //     this.toastService.spinnerObservable()
    //   ).subscribe(() => {
    //     this.tabGroup.selectedIndex = 0;
    //   })
    // )
  }

  // loadData() {
  //   this.isLoading = true;


  // }

  refreshBankStatement() {

  }

  convertToInternationalFormat(mobileNumber: string): string {
    // Check if the mobile number starts with '0'
    if (mobileNumber.startsWith('0')) {
      // Replace the leading '0' with '+61'
      return '+61' + mobileNumber.substring(1);
    }
    // If the mobile number does not start with '0', return it as is
    return mobileNumber;
  }
}
