import { Component, inject, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CurrencyInputValue, DocumentReviewDialogData, DocumentReviewDialogResult, GetAllPismoAccountStatusFn, GetPismoAccountStatusReasonsFn, GroupedDocument, PismoEditAccountDialogData, PismoEditAccountDialogResult, PismoGetAccountStatusReasonsResponse, PismoGetAccountStatusResponse, PismoRollbackAccountStatusFn, PismoUpdateAccountLimitFn, PismoUpdateAccountStatusFn, SlideToggleValue } from '@portal-workspace/grow-shared-library';
import { Subscription, forkJoin, of, tap } from 'rxjs';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleChange, MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { ApplicationDialogService } from './application-dialog.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import _ from 'lodash';
@Component({
  templateUrl: './document-review.dialog.html',
  styleUrls: ['./document-review.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatSlideToggleModule,
    NgClass,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule
]
})
export class DocumentReviewDialog implements OnInit {

  errorMessage: string = '';
  subscriptions: Subscription[] = [];
  displayedColumns = ['name', 'action'];

  data: DocumentReviewDialogData = inject(MAT_DIALOG_DATA);
  alltagStatus:string[] = [];
  filtertagStatus:string[] = [];
  dialogRef = inject(MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>);
  selectedGroupName = new SelectionModel<GroupedDocument>(true, []);

  title: string = 'Review Documents/Conditions';
  notesPlaceholder:string = `Provide background information to support your request to review these conditions.\n\nOur team will respond to you as soon as possible.`
  allTags: GroupedDocument[] = [];
  filterTag: GroupedDocument[] = [];
  removedTag: GroupedDocument[] = [];
  selectableTags: GroupedDocument[] = [];
  formControlApplicationNotes!: FormControl<string | null>;

  // constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentReviewDialogData,
  //   private dialogRef: MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>,
  //   private formBuilder: FormBuilder,
  //   private toastService: PortalHotToastService) {
  //     groupNames

  //   // this.formControlEnabled.valueChanges.pipe(
  //   //   tap(r => {
  //   //     if (r === true) {
  //   //       this.formControlStatusReason.setValue(null)
  //   //       this.formControlStatusReason.clearValidators()
  //   //       this.formControlStatusReason.updateValueAndValidity()
  //   //       this.showStatusReason = false;
  //   //     } else {
  //   //       this.formControlStatusReason.setValidators([Validators.required])
  //   //       this.formControlStatusReason.updateValueAndValidity();
  //   //       this.showStatusReason = true;
  //   //     }
  //   //   })
  //   // ).subscribe();
  // }

  constructor(
    private dialogService: ApplicationDialogService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DocumentReviewDialogData,
    private matDialogRef: MatDialogRef<DocumentReviewDialog, DocumentReviewDialogResult>,
    private formBuilder: FormBuilder,
  ) {
    this.formControlApplicationNotes = this.formBuilder.control(null,)
    this.allTags = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted');
    this.filterTag = this.dialogData?.groupNames.sort((a, b) => a.groupName.localeCompare(b.groupName)).filter(item => item.status !== 'Accepted');
    this.title = dialogData.title
  }

  ngOnInit() {
    this.alltagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted');
    this.filtertagStatus = _.compact(_.uniq(_.map(this.data.groupNames, 'status'))).filter(status => status !== 'Accepted');
    console.log("this.alltagStatus",this.alltagStatus)
    this.data.groupNames.forEach(element => {
      this.selectedGroupName.select(element)
    });
  }

  remove(tag: GroupedDocument): void {
    _.pull(this.filterTag, tag);
    this.removedTag.push(tag)
    // const index = this.filterTag.indexOf(tag);
    // console.log("index",index)
    // console.log("this.filterTag",this.filterTag)
    // if (index >= 0) {
    //   this.filterTag.splice(index, 1);
    // }
    // this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
  }
 
  onSelectionChanged($event: MatCheckboxChange, element: GroupedDocument) {
   
    if($event.checked) {
      this.selectedGroupName.select(element)
    } else {
      this.selectedGroupName.deselect(element)
    }
  }
  onStatusSelectionChanged($event: MatCheckboxChange, element: string) {
    console.log("before this.filterTag",this.filterTag)

    if($event.checked) {
      this.filtertagStatus.push(element)
    } else {
      _.pull(this.filtertagStatus, element);
       
    }
    
 
    this.filterTag = this.allTags.filter(item => this.filtertagStatus.includes(item.status) && !this.removedTag.includes(item));
    console.log("this.alltagStatus",this.alltagStatus)
    console.log("this.filtertagStatus",this.filtertagStatus)
    console.log("this.filterTag",this.filterTag)
  }
  
  // toggleApplicantPrivacyConsent($event: MatSlideToggleChange, element: GroupedDocument) {
     
  //   if($event.checked) {
  //     this.selectedGroupName.select(element)
  //   } else {
  //     this.selectedGroupName.deselect(element)
  //   }
  // }
  refresh(){
    this.filterTag = this.allTags
    this.removedTag = []
    this.filtertagStatus = this.alltagStatus
  }
  onSave() {
    this.dialogRef.close({
      valid: true,
      groupNames:this.filterTag,
      //groupNames:this.selectedGroupName.selected,
      documentNotes: this.formControlApplicationNotes.value
    });
  }

  onCancel(event: Event) {
    this.dialogRef.close({type: 'cancel'});
  }
}
