import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PortalThemeComponent} from "./portal-theme.component";


@NgModule({
  imports: [
    CommonModule,
    PortalThemeComponent,
  ],
  exports: [
    CommonModule,
    PortalThemeComponent,
  ]
})
export class PortalThemeModule {

}
