import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AccountSummary, BankStatementAccount, DecisionMetricsData } from '@portal-workspace/grow-shared-library';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'decision-metrics',
  templateUrl: './decision-metrics.component.html',
  styleUrls: ['./decision-metrics.component.scss'],
  standalone: true,
  imports: [MatTableModule, NgClass, ExtendedModule, LooseCurrencyPipe]
})
export class DecisionMetricsComponent implements OnInit, OnChanges {
  @Input({ required: true }) data!: BankStatementAccount;
  columnsToDisplay = ['label', 'allTime', 'last180Days', 'last90Days'];
  dataSource: DecisionMetricsData[] = [];
  constructor() { }

  ngOnInit(): void {
    this.loadData()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'label': return 'Description ';
      case 'allTime': return 'All';
      case 'last180Days': return 'Last 180 days';
      case 'last90Days': return 'Last 90 days';
      default: return column;
    }
  }

  loadData() {
    this.dataSource = [];
    this.dataSource = this.data.decisionMetrics;
  }

  needCurrencyPipe(row: DecisionMetricsData) {
    return row.label.includes('$');
  }

  needAlignRight(column: string) {
    return ['all', 'last180days', 'last90days'].includes(column);
  }
}
