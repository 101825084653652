import {Component, inject, Input, OnDestroy, OnInit} from "@angular/core";
import {AsyncPipe, JsonPipe, NgIf} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {Observable, Subscription} from "rxjs";
import {ChangePortalThemeFn, compareMatch, PortalThemeServiceEvent} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {pxToRem, setupUntilDestroy} from "@portal-workspace/grow-ui-library";
import {delay, distinctUntilChanged, tap} from "rxjs/operators";
import _ from 'lodash';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltip, MatTooltipModule} from "@angular/material/tooltip";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'portal-theme',
  templateUrl: './portal-theme.component.html',
  styleUrls: ['./portal-theme.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatSlideToggleModule,
    NgIf,
    MatToolbarModule,
    MatTooltipModule,
  ]
})
export class PortalThemeComponent implements OnInit {

  pxToRem = pxToRem;

  subscriptions: Subscription[] = [];

  formBuilder: FormBuilder = inject(FormBuilder);
  formControl: FormControl<boolean | null> = this.formBuilder.control(null, [Validators.required]);

  @Input({required: true}) eventObservable!: Observable<PortalThemeServiceEvent>;
  @Input({required: true}) changePortalThemeFn!: ChangePortalThemeFn;

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.subscriptions.push(this.eventObservable.pipe(
      tap(evt => {
        if (evt) {
          switch(evt.type) {
            case 'themeChange': {
              this.formControl.setValue(evt.portalTheme == 'dark', {emitEvent: false, onlySelf: true,});
            }
          }
        }
      }),
    ).subscribe());
    this.subscriptions.push(this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v) => {
        if (!_.isNil(v)) {
          this.changePortalThemeFn(v ? 'dark' : 'light');
        }
      })
    ).subscribe());
  }
}
