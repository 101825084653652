import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { GetBsaLenderListFn, GetBsaExcludedLenderListFn, UpdateBsaLenderFn, UpdateBsaExcludedLenderFn, DeleteBsaLenderFn, DeleteBsaExcludedLenderFn, BsaLender, AddBsaLenderFn, AddBsaExcludedLenderFn, DscrCalculatorHistory} from '@portal-workspace/grow-shared-library';
import { FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { loadingFor } from '@ngneat/loadoff';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CustomPaginatorComponent } from '../custom-paginator-component/custom-paginator/custom-paginator.component';
import { MatTableModule } from '@angular/material/table';
import { FlexModule } from '@angular/flex-layout/flex';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccessLevelPipe } from "../../pipes/access-level.pipe";
import { CustomContentLoaderComponent } from "../custom-content-loader-component/custom-content-loader.component";
import { ApplicationDialogService } from "../application-dialog-component/application-dialog.service";
import moment from "moment";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'dscr-history-table',
  templateUrl: './dscr-history-table.component.html',
  styleUrls: ['./dscr-history-table.component.scss'],
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, MatTooltipModule, FlexModule, ExtendedModule, FormsModule, ReactiveFormsModule, CustomContentLoaderComponent, NgClass, MatTableModule, MatSortModule, CustomPaginatorComponent, AsyncPipe, DatePipe, LooseCurrencyPipe, AccessLevelPipe, MatSlideToggleModule],
  exportAs: 'dscrHistoryTableComponent',
})
export class DscrHistoryTableComponent implements OnInit, OnChanges {
  subscriptions: Subscription[] = [];
  moment = moment;
  constructor(
      private formBuilder: FormBuilder,
      private toastService: PortalHotToastService,
      private dialogService: ApplicationDialogService
      ) {
  }

  @Input({required: true}) data: DscrCalculatorHistory[] = [];
 
  dataSource: DscrCalculatorHistory[] = [];
  columnsToDisplay: string[] = ['lastUpdatedTime', 'updatedBy', 'data'];

  ngOnInit() {
    this.dataSource = this.data
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = this.data
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'lastUpdatedTime': return 'Last Updated Time';
      case 'updatedBy': return 'Updated By';
      case 'data': return 'Data';
      default: return column;
    }
  }
}
