import {Component, OnInit, inject} from "@angular/core";
import _ from 'lodash';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout/flex";
import {
  AbnComponent,
  CurrencyInputComponent,
  DisableControlDirective,
  MarkDirective, MessageBoxComponent, minMaxValidator, minValidator, NumberInputComponent, RangeSliderComponent,
  setupUntilDestroy,
  YesNoComponent
} from "@portal-workspace/grow-ui-library";
import {MatButtonModule} from "@angular/material/button";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {
  AbnComponentValue,
  BusinessPreliminaryBorrowerEstimationResult,
  calcBusinessPreliminaryBorrowingEstimation,
  calcOverdraftPreliminaryBorrowingEstimation,
  CurrencyInputValue,
  NumberInputValue,
  OverdraftPreliminaryBorrowingEstimationResult,
  RangeSliderValue,
  YesNoValue
} from "@portal-workspace/grow-shared-library";
import {MatFormField, MatFormFieldModule, MatSuffix} from "@angular/material/form-field";
import {MatSliderModule} from "@angular/material/slider";
import {startWith, tap} from "rxjs/operators";
import {AsyncPipe, JsonPipe, NgTemplateOutlet} from "@angular/common";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {navigationUrlForBusinessFinance, navigationUrlForBusinessOverdraft} from "../../service/navigation-urls";
import {constants} from '@portal-workspace/grow-shared-library';
import {MatInput, MatInputModule} from "@angular/material/input";
import {BrokerflowService} from "../../service/brokerflow.service";
import {loadingFor} from "@ngneat/loadoff";
import {ContentLoaderModule} from "@ngneat/content-loader";


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './business-and-overdraft-borrowing-estimator.page.html',
  styleUrls: ['./business-and-overdraft-borrowing-estimator.page.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MarkDirective,
    MatButtonModule,
    YesNoComponent,
    DisableControlDirective,
    MessageBoxComponent,
    CurrencyInputComponent,
    NumberInputComponent,
    MatSuffix,
    MatSliderModule,
    RangeSliderComponent,
    NgTemplateOutlet,
    AsyncPipe,
    JsonPipe,
    MatInputModule,
    MatFormFieldModule,
    AbnComponent,
    ContentLoaderModule,
  ],
})
export class BusinessAndOverdraftBorrowingEstimatorPage implements OnInit {

    loader = loadingFor('brokerflowQuery');

    BUSINESS_SLIDER_BORROWING_MIN = 0;
    BUSINESS_SLIDER_BORROWING_MAX = constants.products.business.maxBorrowing;

    OVERDRAFT_SLIDER_BORROWING_MIN = 0;
    OVERDRAFT_SLIDER_BORROWING_MAX = constants.products.overdraft.maxBorrowing;

    subscriptions: Subscription[] = [];

    overdraftPreliminaryBorrowingEstimationResult: OverdraftPreliminaryBorrowingEstimationResult | null = null;
    businessPreliminaryBorrowingEstimationResult: BusinessPreliminaryBorrowerEstimationResult | null = null;

    router = inject(Router);
    brokerflowService: BrokerflowService = inject(BrokerflowService);
    formBuilder = inject(FormBuilder);
    // NOTE: true -> Bankstatement, false -> Manual
    formControlMonthlyTurnoverRetrieval: FormControl<YesNoValue> = this.formBuilder.control(false, [Validators.required]);
    // NOTE: true -> Brokerflow, false -> Basiq
    formControlBankstatementSource: FormControl<YesNoValue> = this.formBuilder.control(true, [Validators.required]);

    // fields related to bankstatement - brokerflow
    brokerflowShowForm: boolean = false;
    brokerflowFormStatus:
      'init' |
      'prepopulate' |
      'failed-prepopulate' |
      'failed-fetch-from-brokerflow'
      = 'init';
    formControlBrokerflowId: FormControl<string | null> = this.formBuilder.control(null, [Validators.required]);
    formControlBrokerflowAbn: FormControl<AbnComponentValue> = this.formBuilder.control(null, [Validators.required]);

    // fields related to manual
    formControlOverdraftBorrowingRange: FormControl<RangeSliderValue> = this.formBuilder.control({start: 0, end: 0}, [Validators.required]);
    formControlBusinessBorrowingRange: FormControl<RangeSliderValue> = this.formBuilder.control({start: 0, end: 0}, [Validators.required]);
    formControlAverageMonthlyTurnover: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
    formControlHasOverdueAtoDebts: FormControl<YesNoValue> = this.formBuilder.control(null, [Validators.required]);
    formControlOverdueAtoDebtsAmount: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
    formControlHasBorrowFromNonBankLenders: FormControl<YesNoValue> = this.formBuilder.control(null, [Validators.required]);
    formControlHowManyNonBankLenders: FormControl<NumberInputValue> = this.formBuilder.control(null, [Validators.required]);
    formControlNonBankLendersTotalAmount: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
    formControlHasDishonouredPayments: FormControl<YesNoValue> = this.formBuilder.control(null, [Validators.required]);
    formControlHowManyDishonouredPayments: FormControl<NumberInputValue> = this.formBuilder.control(null, [Validators.required]);
    formControlDishonouredPaymentsTotalAmount: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);

    formGroup: FormGroup<{
      bankstatementSource: FormControl<YesNoValue>,
      monthlyTurnoverRetrieval: FormControl<YesNoValue>,
      averageMonthlyTurnover: FormControl<CurrencyInputValue>,
      hasOverdueAtoDebts: FormControl<YesNoValue>,
      overdueAtoDebtsAmount: FormControl<CurrencyInputValue>,
      hasBorrowFromNonBankLenders: FormControl<YesNoValue>,
      howManyNonBankLenders: FormControl<NumberInputValue>,
      nonBankLendersTotalAmount: FormControl<CurrencyInputValue>,
      hasDishonouredPayments: FormControl<YesNoValue>,
      howManyDishonouredPayments: FormControl<NumberInputValue>,
      dishonouredPaymentsTotalAmount: FormControl<CurrencyInputValue>,
    }> = this.formBuilder.group({
      bankstatementSource: this.formControlBankstatementSource,
      monthlyTurnoverRetrieval: this.formControlMonthlyTurnoverRetrieval,
      averageMonthlyTurnover: this.formControlAverageMonthlyTurnover,
      hasOverdueAtoDebts: this.formControlHasOverdueAtoDebts,
      overdueAtoDebtsAmount: this.formControlOverdueAtoDebtsAmount,
      hasBorrowFromNonBankLenders: this.formControlHasBorrowFromNonBankLenders,
      howManyNonBankLenders: this.formControlHowManyNonBankLenders,
      nonBankLendersTotalAmount: this.formControlNonBankLendersTotalAmount,
      hasDishonouredPayments: this.formControlHasDishonouredPayments,
      howManyDishonouredPayments: this.formControlHowManyDishonouredPayments,
      dishonouredPaymentsTotalAmount: this.formControlDishonouredPaymentsTotalAmount,
    });

    formGroupBrokerflowQuery: FormGroup<{
      brokerflowId: FormControl<string | null>,
      brokerflowAbn: FormControl<AbnComponentValue>,
    }> = this.formBuilder.group({
      brokerflowId: this.formControlBrokerflowId,
      brokerflowAbn: this.formControlBrokerflowAbn,
    });


    ngOnInit(): void {
      setupUntilDestroy(this);
      this.subscriptions.push(this.formControlMonthlyTurnoverRetrieval.valueChanges.pipe(
        tap(r => {
          this.overdraftPreliminaryBorrowingEstimationResult = null;
          this.businessPreliminaryBorrowingEstimationResult = null;
        }),
      ).subscribe());
      this.subscriptions.push(this.formControlBankstatementSource.valueChanges.pipe(
        tap(r => {
          this.overdraftPreliminaryBorrowingEstimationResult = null;
          this.businessPreliminaryBorrowingEstimationResult = null;
          this.brokerflowShowForm = false;
        }),
      ).subscribe());
      this.subscriptions.push(this.formControlHasOverdueAtoDebts.valueChanges.pipe(
        tap(r => {
          if (r == true) { // has ato debts
            this.formControlOverdueAtoDebtsAmount.setValue(null); // force them to enter
            this.formControlOverdueAtoDebtsAmount.setValidators([Validators.required]);
          } else {
            this.formControlOverdueAtoDebtsAmount.setValue(0);
            this.formControlOverdueAtoDebtsAmount.clearValidators();
          }
          this.formControlOverdueAtoDebtsAmount.updateValueAndValidity();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlHasBorrowFromNonBankLenders.valueChanges.pipe(
        tap(r => {
          if (r == true) { // has borrowed from non bank lenders
            this.formControlHowManyNonBankLenders.setValue(null);
            this.formControlNonBankLendersTotalAmount.setValue(null);
            this.formControlHowManyNonBankLenders.setValidators([Validators.required]);
            this.formControlNonBankLendersTotalAmount.setValidators([Validators.required]);
          } else {
            this.formControlHowManyNonBankLenders.setValue(0);
            this.formControlNonBankLendersTotalAmount.setValue(0);
            this.formControlHowManyNonBankLenders.clearValidators();
            this.formControlNonBankLendersTotalAmount.clearValidators();
          }
          this.formControlHowManyNonBankLenders.updateValueAndValidity();
          this.formControlNonBankLendersTotalAmount.updateValueAndValidity();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlHasDishonouredPayments.valueChanges.pipe(
        tap(r => {
          if (r == true) {
            this.formControlHowManyDishonouredPayments.setValue(null);
            this.formControlDishonouredPaymentsTotalAmount.setValue(null);
            this.formControlHowManyDishonouredPayments.setValidators([Validators.required]);
            this.formControlDishonouredPaymentsTotalAmount.setValidators([Validators.required]);
          } else {
            this.formControlHowManyDishonouredPayments.setValue(0);
            this.formControlDishonouredPaymentsTotalAmount.setValue(0);
            this.formControlHowManyDishonouredPayments.clearValidators()
            this.formControlDishonouredPaymentsTotalAmount.clearValidators();
          }
          this.formControlHowManyDishonouredPayments.updateValueAndValidity();
          this.formControlDishonouredPaymentsTotalAmount.updateValueAndValidity();
        })
      ).subscribe());
      this.formGroup.valueChanges.pipe(
        tap(() => {
          this.overdraftPreliminaryBorrowingEstimationResult = null;
          this.businessPreliminaryBorrowingEstimationResult = null;
        })
      ).subscribe();
      setTimeout(() => {
        this.formControlMonthlyTurnoverRetrieval.setValue(false);
        this.formControlHasOverdueAtoDebts.setValue(false);
        this.formControlHasDishonouredPayments.setValue(false);
        this.formControlHasBorrowFromNonBankLenders.setValue(false);
      });
    }

    onCalculateEstimation(event: Event) {
      this.businessPreliminaryBorrowingEstimationResult = calcBusinessPreliminaryBorrowingEstimation({
        monthlyTurnover: this.formControlAverageMonthlyTurnover.value!,
        overdueAtoDebt: this.formControlOverdueAtoDebtsAmount.value!,
        numOfMissedPayments: this.formControlHowManyDishonouredPayments.value!,
        numOfNonBankCashflowLenders: this.formControlHowManyNonBankLenders.value!,
      });
      this.formControlBusinessBorrowingRange.setValue({
        start: this.businessPreliminaryBorrowingEstimationResult.type == 'success' ? this.businessPreliminaryBorrowingEstimationResult.min : 0,
        end: this.businessPreliminaryBorrowingEstimationResult.type == 'success' ? this.businessPreliminaryBorrowingEstimationResult.max : 0,
      });
      this.overdraftPreliminaryBorrowingEstimationResult = calcOverdraftPreliminaryBorrowingEstimation({
        monthlyTurnover: this.formControlAverageMonthlyTurnover.value!,
        overdueAtoDebt: this.formControlOverdueAtoDebtsAmount.value!,
        numOfMissedPayments: this.formControlHowManyDishonouredPayments.value!,
        numOfNonBankCashflowLenders: this.formControlHowManyNonBankLenders.value!,
      });
      this.formControlOverdraftBorrowingRange.setValue({
        start: this.overdraftPreliminaryBorrowingEstimationResult.type == 'success' ? this.overdraftPreliminaryBorrowingEstimationResult.min : 0,
        end: this.overdraftPreliminaryBorrowingEstimationResult.type == 'success' ? this.overdraftPreliminaryBorrowingEstimationResult.max : 0,
      });
    }

  onSubmitBusinessApplication($event: Event) {
      this.router.navigate(navigationUrlForBusinessFinance());
  }

  onSubmitOverdraftApplication($event: Event) {
      this.router.navigate(navigationUrlForBusinessOverdraft());
  }

  onContactUsClick($event: Event) {
    const contactUsUrl = environment.contactUsUrl;
    window.open(contactUsUrl, '_blank');
  }

  queryBrokerFlow() {
    this.brokerflowShowForm = false;
    const brokerflowId = this.formControlBrokerflowId.value ?? '';
    const abn = this.formControlBrokerflowAbn.value ?? '';
    this.subscriptions.push(this.brokerflowService.brokerflowSubmission(brokerflowId, abn).pipe(
      this.loader.brokerflowQuery.track(),
      tap(r => {
        this.brokerflowShowForm = true;
        if (r.payload) {

          // todo: prepopulate, if cannot prepopulate set brokerflowFormStatus to 'failed-prepopulate'
          const brokerflowBankstatement = r.payload;
          if (brokerflowBankstatement.estimation) {
            // ato debts
            const atoDebts = brokerflowBankstatement.estimation.atoDebts;
            const atoDebtsAmount = atoDebts?.total ?? 0;
            if (atoDebts && atoDebtsAmount > 0) {
              this.formControlHasOverdueAtoDebts.setValue(true);
              this.formControlOverdueAtoDebtsAmount.setValue(atoDebtsAmount);
            } else {
              this.formControlHasOverdueAtoDebts.setValue(false);
              this.formControlOverdueAtoDebtsAmount.setValue(null);
            }

            // non bank lenders
            const nonBankLenders = brokerflowBankstatement.estimation.nonBankLenders;
            const nonBankLendersAmount = nonBankLenders?.total ?? 0;
            if (nonBankLenders && nonBankLendersAmount > 0) {
              this.formControlHasBorrowFromNonBankLenders.setValue(true);
              this.formControlHowManyNonBankLenders.setValue(nonBankLenders.keywordsFound.length);
              this.formControlNonBankLendersTotalAmount.setValue(nonBankLendersAmount);
            } else {
              this.formControlHasBorrowFromNonBankLenders.setValue(false);
              this.formControlHowManyNonBankLenders.setValue(null);
              this.formControlNonBankLendersTotalAmount.setValue(null);
            }

            // dishonoured payments
            const dishonouredPayments = brokerflowBankstatement.estimation.dishonourPayments;
            const dishonouredPaymentsAmount = ((_.isNil(dishonouredPayments?.metricsDishonouredCount) || dishonouredPayments?.metricsDishonouredCount == 0) ? dishonouredPayments?.total : dishonouredPayments?.metricsDishonouredCount) ?? 0;
            if (dishonouredPayments && dishonouredPaymentsAmount > 0) {
              this.formControlHasDishonouredPayments.setValue(true);
              this.formControlHowManyDishonouredPayments.setValue(dishonouredPayments.entries.length);
              this.formControlDishonouredPaymentsTotalAmount.setValue(dishonouredPaymentsAmount);
            } else {
              this.formControlHasDishonouredPayments.setValue(false);
              this.formControlHowManyDishonouredPayments.setValue(null);
              this.formControlDishonouredPaymentsTotalAmount.setValue(null);
            }

            // average monthly income
            const averageMonthlyTurnover = brokerflowBankstatement.estimation.averageMonthlyTurnover;
            const averageMonthlyTurnoverAmount =  averageMonthlyTurnover?.averageMonthlyIncome ?? 0;
            if (averageMonthlyTurnover && averageMonthlyTurnoverAmount > 0) {
              this.formControlAverageMonthlyTurnover.setValue(averageMonthlyTurnoverAmount);
            } else {
              this.formControlAverageMonthlyTurnover.setValue(null);
            }
          }
        } else {
          this.brokerflowFormStatus = 'failed-fetch-from-brokerflow';
        }
      })
    ).subscribe());
  }
}
