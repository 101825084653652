<div class="maintenance-page container-fluid">
  <div class="row">
    <div class="col" fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-h3">Maintenance</div>
      <div class="mat-h4-custom">Sorry, the portal is currently in maintenance mode, please come back later</div>
      <div class="width-100 separator-bottom" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
        <div class="mat-body bottom-text icon-hover"><a class="cursor-pointer" (click)="reLogin($event)">Back to Portal<span class="mdi mdi-arrow-right"></span></a></div>
      </div>
    </div>
  </div>
</div>
