import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AssetFinanceApplication,
  ApplicationSelectionType,
  PpsrAssetValue,
  PayloadApiResponse,
  PpsrAsset,
  VehicleDetails,
  RequestDoc,
  calculateAssetFinanceEstimation,
  PaymentFrequencyType,
  AssetConditionType,
  AssetCategorySelectionValue,
  AssetCategoryTypeSelectionValue,
  AssetSelectionComponentValue,
  SendContractEmailsFn,
  GetContractStatusFn,
  GenerateContractFn,
  DeleteContractFileFn,
  GetActiveContractForApplicationFn,
  ContractDetails,
  DeclineContractFn,
  ContractStatusDialogResult,
  getApplicationAssetCategory,
  LoanTermType,
  FinanceType,
  BalloonPaymentType,
  BrokerageSelectionType,
  RatecardDetails,
  constants,
  AssetSpec,
  CreateApplicationNoteFn,
  AssetSettlementGetApplicationAssetFn,
  DigitalIdGetApplicationIndividualsFn,
  GetRateCardDetailsFn,
  getApplicationStage,
  applicationStageAllowGenerateContract,
  CreateNewDisbursementFn,
  UpdateDisbursementFn,
  GetDisbursementByApplicationIdFn,
  SyncDisbursementToSfFn,
  SendAssetToSfRecord,
  getBrokerageAmount,
  SyncBankDetailsToSfFn,
  SyncContractDetailsToSfFn,
  getBalloonPayment,
  getPaymentType,
  RemoveApplicationNoteByNoteIdFn,
  getAssetGoodRetail,
  GetBillerNameFn,
  ValidateBPAYFn,
  UpdateApplicationStageInSfFn,
  AzureStorageDocument,
  GroupedDocument,
  ApiResponse,
  GetApplicationByIdFn,
  Application,
  applicationStageAllowSettleLoan,
  SettleLoanFn,
  AssetDisbursement,
  Disbursement,
  validateDisbursementData,
  SyncPrivateSellerBankDetailsToSfFn,
  GetInstitutionNameFn,
  SyncDepositPaidToSfFn,
  getApplicationStage2,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  CopyApplicationFn,
  GeoLocation,
  GetApplicationOwnerFn,
  GetGeoLocationFn,
  RefreshBankStatementFn,
  GetUserFn,
  GetAccountDetailsFromSfFn,
  GetBankDetailsFromOpportunitySfFn,
  LocalBankAccountDetailValue,
  ExtractTaxInvoiceFn,
  UpdateApplicationFn,
  getCreditRateAdjustment,
  CreateAssetInspectionFn,
  GetAssetInspectionsForApplicationFn,
  DownloadVerimotoReportFn,
  VerimotoInspectionTableDataWithInspectionDetails,
  VerimotoLenderType,
  UpdateApplicationSfFn,
  isAdminOrCreditUserOrSalesAM,
  TotalPaymentBreakupDialogData,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  VerimotoInspectionTypeSelection,
  getTransactionType,
  DownloadDocumentFromAzureFn,
  GetBankStatementsAnalysisFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  getDocFee,
  validateAssetDeposits, validateAssetDepositAgainstApplicationDeposit, SendPrivacyConsentEmailFn,
  UpdateLvrCalculatorValueFn,
  GetLvrCalculatorValueFn,
  GetBsaLenderListFn,
  GetBsaExcludedLenderListFn,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  GetDscrCalculatorHistoryFn,
  GetBasiqCustomerMappingFn,
  GetBasiqStatementDataForCompanyFn,
  RefreshBasiqConnectionsFn,
  GetBankStatementAndBasiqDataStatusFn,
  GetOriginatorBusinessByIdFn,
  AddAuditLogFn,
  // validateAssetDepositAgainstApplicationDeposit, validateAssetDeposits,
} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  AssetSettlementAddPpsrDetailFn,
  AssetSettlementGetApplicationPpsrInfoFn,
  AssetSettlementGetPpsrDetailsFn,
  AssetSettlementGetPpsrDocumentsFn,
  AssetSettlementRemoveApplicationAssetFn,
  // AssetSettlementSavePpsrDocumentFn,
  AssetSettlementSearchAssetsFn,
  AssetSettlementSearchGrantorsFn,
  AssetSettlementSendAssetToSfFn,
  AssetSettlementSubmitApplicationAssetsFn,
  AssetSettlementSubmitPpsrFn,
  AssetSettlementUpdateApplicationAssetsFn,
  AssetSettlementUpdatePpsrDetailFn,
  BusinessSearchFn,
  CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  GetOriginatorByIdFn,
  getUser,
  PortalHotToastService,
  ListApplicationDocumentFn,
  DownloadAllApplicationDocumentUrlFn, DownloadApplicationDocumentUrlFn,
  SaveSupplierFn,
  SearchSupplierFn,
  SendApplicationDocumentNotificationFn,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  BusinessNumberSearchFn,
  checkContractIndividuals,
  applicationDefaultDocuments,
  UndoApplicationDocumentFn, AssetSelectionComponentSearchFn, AssetAppComponentActionEvent, applicationToTransactionValue,

  DscrCalculatorComponent,
} from '@portal-workspace/grow-ui-library';
import { LvrCalculatorComponent } from '../lvr-calculator.component';
import {
  booleanToYesNo,
  filesToBase64Files,
  yesNoToBoolean,
  isInternalUser,
  DocumentTag,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import moment from 'moment';
import {AppCalculator, RepaymentEstimationFormData, RepaymentFrequencyType} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable, Subscription, combineLatest, of, catchError} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes, getAssetAvgRetail,
  getAssetCategory, getAssetCategoryIndex,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetCategoryObj,
  getAssetSpec,
  getAssetTypeObj,
  getAssetMake, getAssetNewPrice,
  getAssetType, getAssetTypeIndex, getAssetVehicle,
  getAssetYear, getBalloonPaymentPercentage,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName, getBrokerOriginationFee,
  getBrokerSalesforceId,
  getAppSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType, getPrimaryBusinessAddress,
  getPrimaryIndustry,
  getPrivateSales,
  primaryCommercialEntity,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  toGrantors,
  formGroupedDocumentData,
  unclassifiedDocuments,
  groupStandardCondition,
} from '@portal-workspace/grow-shared-library';
import { User } from '@portal-workspace/grow-shared-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {GetUsersFunc, UserSelectionComponentEvent} from '@portal-workspace/grow-ui-library';
import {GetApplicationAuditLogsFn} from '@portal-workspace/grow-ui-library';
import {GetNotesByApplicationIdFn} from '@portal-workspace/grow-ui-library';
import {UpdateDocumentMetadataFn} from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { AssetDocumentsComponent } from './asset-documents.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { AddAssetComponent } from '../../asset-listing-component/add-asset.component';
import { AssetSettlementComponent } from './asset-settlement.component';
import { AssetAppComponent } from './asset-app.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import {AssetPricingComponent, AssetPricingComponentEvent} from "./asset-pricing.component";
import {AssetCreditComponent} from './asset-credit.component';
import { MatExpansionModule } from "@angular/material/expansion";
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';
import { BankStatementComponent } from '../../bank-statement-component/bank-statement.component';
import { BasiqStatementComponent } from '../../basiq-statement-component/basiq-statement.component';
import { BankComponent } from '../bank.component';

export type RequestDocFn = (data: RequestDoc) => Observable<PayloadApiResponse<any>>;

export type AssetTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'asset-finance-application-details',
    templateUrl: './asset-finance-application-details.component.html',
    styleUrls: ['./asset-finance-application-details.component.scss'],
    standalone: true,

  imports: [
    AssetCreditComponent,
    FlexModule,
    DscrCalculatorComponent,
    MatExpansionModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    AssetAppComponent,
    MessageBoxComponent,
    AssetSettlementComponent,
    AddAssetComponent,
    KycVerificationComponent,
    AssetDocumentsComponent,
    AssetPricingComponent,
    BankStatementComponent,
    LvrCalculatorComponent,
    ApplicationTypeIconComponent,
    BasiqStatementComponent,
    BankComponent
  ]
})
export class AssetFinanceApplicationDetailsComponent implements OnInit {

  tabs: AssetTab[] = [];
  ratecard: RatecardDetails | null = null;

  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'quote', 'bank-statement', 'asset', 'documents','credit' ,'add-asset', 'kyc-aml', 'pricing'];

  breadcrumbTrails: string[] = ['Applications'];

  @Input() getApplicationPpsrInfoFn!: AssetSettlementGetApplicationPpsrInfoFn;
  @Input() removeApplicationAssetFn!: AssetSettlementRemoveApplicationAssetFn;
  @Input() searchAssetFn!: AssetSettlementSearchAssetsFn;
  @Input() submitPpsrFn!: AssetSettlementSubmitPpsrFn;
  @Input() getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input() sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  @Input() getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input() addPpsrDetailFn!: AssetSettlementAddPpsrDetailFn;
  @Input() updatePpsrDetailFn!: AssetSettlementUpdatePpsrDetailFn;
  @Input() updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  @Input() submitApplicationAssetsFn!: AssetSettlementSubmitApplicationAssetsFn;
  @Input() searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input() getPpsrDocumentsFn!: AssetSettlementGetPpsrDocumentsFn;
  @Input() assetSearchFn!: AssetSelectionComponentSearchFn;
  @Input() getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input() addIndividualFn!: DigitalIdAddIndividualFn;
  @Input() verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input() verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input() sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input() getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input() updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input() updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input() updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input() printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input() setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input() searchSupplierFn!: SearchSupplierFn;
  @Input() saveSupplierFn!: SaveSupplierFn;
  @Input() businessSearchFn!: BusinessSearchFn;
  @Input() apiUrl!: string;
  @Input() onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input() listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input() downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input() approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input() declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input() deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input() downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input() updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input() updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input() sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input() createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input() completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input() getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;

  @Input() createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input() removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input() getClientFn!: DigitalIdGetClientIdFn;
  @Input() authenticateFn!: DigitalIdAuthenticateFn;
  @Input() getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input() getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input() requestDocFn!: RequestDocFn;
  @Input() assetSettlementSearchAssetsFn!: AssetSettlementSearchAssetsFn;
  @Input() sendContractEmailsFn!: SendContractEmailsFn;
  @Input() updateDocumentMetadataFn!: UpdateDocumentMetadataFn;
  @Input() assetSettlementSearchGrantorsFn!:AssetSettlementSearchGrantorsFn;
  @Input() getContractStatusFn!: GetContractStatusFn;
  @Input() generateContractFn!: GenerateContractFn;
  @Input() deleteContractFileFn!: DeleteContractFileFn;
  @Input() createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input() updateDisbursementFn!: UpdateDisbursementFn;
  @Input() getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input() syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input() syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input() syncPrivateSellerBankDetailsToSfFn!: SyncPrivateSellerBankDetailsToSfFn;
  @Input() syncDepositPaidToSfFn!: SyncDepositPaidToSfFn;
  @Input() syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  @Input() businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input() updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input() getApplicationByIdFn!: GetApplicationByIdFn;
  @Input() settleLoanFn!: SettleLoanFn;
  @Input() allowContractGeneration!: boolean;
  @Input() generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input() sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input() deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input() copyApplicationFn!: CopyApplicationFn;
  @Input() getGeoLocationFn!: GetGeoLocationFn;
  @Input() getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input() undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input() refreshBankStatementFn!: RefreshBankStatementFn;
  @Input() getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input() getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;
  @Input() extractTaxInvoiceFn!: ExtractTaxInvoiceFn;
  @Input() updateApplicationFn!: UpdateApplicationFn;
  @Input() updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input() createAssetInspectionFn!: CreateAssetInspectionFn;
  @Input() getAssetInspectionsForApplicationFn!: GetAssetInspectionsForApplicationFn;
  @Input() downloadVerimotoReportFn!: DownloadVerimotoReportFn;
  @Input() verimotoLender!: VerimotoLenderType;
  @Input() verimotoAssetInspectionTypes!: VerimotoInspectionTypeSelection[];
  @Input() downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input() bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input() deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;
  @Input({required: true}) getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;


  @Output() navigateToApplications = new EventEmitter();
  @Output() assetPricingEvents = new EventEmitter<AssetPricingComponentEvent>();

  repaymentEstimationData: RepaymentEstimationEntry[] = [];
  amortizationScheduleData: AmortisationScheduleEntry[] = [];
  rst!: TotalPaymentBreakupDialogData
  isAssetEmpty: boolean = false;
  paymentChartData: PaymentChartData = {
    amountFinanced: 0,
    totalInterest: 0,
    emiAmt: 0,
    paymentFrequency: 'Monthly',
    principalAmt: 0,
    interestAmt: 0,
    totalAmt: 0,
    loanTerm: 0,
    lvr: 0,
    rv: 0,
    brokerageAmount: 0,
    docFee: 0,
    brokerOriginationFee: 0,
    applicationType: 'AssetFinance'
  };
  amortizationChartData: AmortizationChartData = {
    estimatedDrawdownDate: moment(),
    annualData: [],
    quarterlyData: [],
  };


  isInternalUser = isInternalUser;
  isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM;
  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getAppSalesforceId = getAppSalesforceId;
  getBrokerName = getBrokerName;
  getOrganisationType = getOrganisationType;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getApplicationAssetCategory = getApplicationAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getPrimaryIndustry = getPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  toGrantors = toGrantors;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  getApplicationStage = getApplicationStage;
  applicationStageAllowGenerateContract = applicationStageAllowGenerateContract;
  getPaymentType = getPaymentType;
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  groupStandardCondition = groupStandardCondition;
  @Input() uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input() getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input() getUsersInCompanyFn!: GetUsersFunc;
  @Input() getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  @Input() declineContractFn!: DeclineContractFn;
  @Input() getBillerNameFn!: GetBillerNameFn;
  @Input() getInstitutionNameFn!: GetInstitutionNameFn;
  @Input() validateBpayFn!: ValidateBPAYFn;
  @Input() sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input() bankStatementsUrl!: string;
  @Input() idVerifyUrl!: string;
  @Input() getUserFn!: GetUserFn;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;

  sendToSF: boolean = false;

  editAssetValue!: PpsrAssetValue
  registered: boolean = false;
  ppsrChecked!: boolean;
  ppsrRegistered!: boolean;
  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex = 0;
  assetStatus:PpsrAsset[]=[];
  subscriptions: Subscription[] = [];
  loggedInUser: User | null = getUser();
  disableAddAsset: boolean = false
  notAllAssetAccepted: boolean = false;
  activeContract: ContractDetails | null = null;
  missingBankDetails: null | 'customer' | 'broker' = null;
  assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];
  @Input() application!: AssetFinanceApplication;
  @Input() initialSelection: ApplicationSelectionType = 'app';
  @Input() ip: string = '';
  @Output() selectionEvent: EventEmitter<ApplicationSelectionType> = new EventEmitter<ApplicationSelectionType>();


  constructor(private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,
    private applicationDialogService: ApplicationDialogService,
  ) {}

  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({ selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length) });
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index:(this.tabs.length)});
    if (isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['credit'], iconClass: 'mdi-calculator', name: 'CREDIT', index: (this.tabs.length)});
    }
    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['asset', 'add-asset'], iconClass: 'mdi-handshake-outline', name: 'CONTRACT', index: (this.tabs.length)});
    if (isAdminOrCreditUserOrSalesAM(this.loggedInUser) || isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['pricing'], iconClass: 'mdi-currency-usd', name: 'PRICING', index: (this.tabs.length)});
    }
  }




  // getApplicationTabIndex = (tab: ApplicationSelectionType) => {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  //   // switch (tab) {
  //   //   case 'app':
  //   //     return 0;
  //   //   case 'bank-statement':
  //   //     return 1;
  //   //   case 'kyc-aml':
  //   //     return 2
  //   //   case 'documents':
  //   //     return 3
  //   //   case 'asset':
  //   //   case 'add-asset':
  //   //     return 4
  //   //   case 'pricing':
  //   //     return 5;
  //   //   default:
  //   //     return 0;
  //   // }
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    // let selection: ApplicationSelectionType = 'app';
    // switch (tabIndex) {
    //   case 0:
    //     selection = 'app';
    //     break;
    //   case 1:
    //     selection = 'bank-statement';
    //     break;
    //   case 2:
    //     selection = 'kyc-aml';
    //     break;
    //   case 3:
    //     selection = 'documents';
    //     break;
    //   case 4:
    //     selection = 'asset';
    //     break;
    //   case 5:
    //     selection =  'pricing';
    //     break;
    // }

    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit(this.currentSection);
  }

  showAddAsset() {
    this.currentSection = 'add-asset';
    this.selectionEvent.emit(this.currentSection);
  }

  async ngOnInit() {
    this.initTabs();

    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    setupUntilDestroy(this);
    const user = this.loggedInUser;
    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }

    // const ac = getAssetCategoryObj(this.application);
    // const at = getAssetTypeObj(this.application);
    // const assetCategory = getApplicationAssetCategory(this.application); // as string
    // const assetType = getAssetType(this.application); // as string
    // const assetSpec = getAssetSpec(this.application);
    // const vehicle = getAssetVehicle(this.application);
    // const assetGoodRetail = getAssetGoodRetail(this.application);
    // const assetNewPrice = getAssetNewPrice(this.application);
    // const brokerOriginationFeeSf = getBrokerOriginationFeeSf(this.application);
    // const docFeeSf = getDocFeeSf(this.application);
    // const rateSf = getRateSf(this.application);
    // const assetCategoryObj = constants.assetCat.find(obj => obj.cat.value === assetCategory);
    // const assetCategoryIndexObj = assetCategoryObj?.type ? assetCategoryObj.type.find(obj => obj.value === assetType) : null;

    const sub = this.getRatecardDetailsFn(this.application.UserId, 'AssetFinance').pipe(
      tap((r: RatecardDetails) => {
        this.ratecard = r;
        this.recalculateEstimation();
        // const date = moment().add(1, 'day');

        // const financialType = (getFinanceType(this.application) as string).toLowerCase().split(' ').join('-') as FinanceType;
        // const rst = calculateAssetFinanceEstimation(date, {
        //   paymentFrequencyType: getRepaymentFrequency(this.application) ? getRepaymentFrequency(this.application) as PaymentFrequencyType : 'Monthly',
        //   assetConditionType: getAssetCondition(this.application) as AssetConditionType ?? null,
        //   assetSelectionValue: {
        //     // ...getAssetSpec(this.application) as AssetSpec, //  as { year: string, description: string },
        //     ...{
        //       make: assetSpec?.make,
        //       family: assetSpec?.family,
        //       year: assetSpec?.year ? String(assetSpec.year) : '',
        //       vehicle:  {
        //         goodretail: assetGoodRetail,
        //         newprice: assetNewPrice,
        //       },
        //       LVR: assetSpec?.LVR,
        //       description: assetSpec?.description ?? '',
        //       avgRetail: assetSpec?.avgretail,
        //       newPrice: assetSpec?.newPrice,
        //       OtherCar: assetSpec?.OtherCar ?? false,
        //       truckGrossVehicleWeight:  assetSpec?.truckGrossVehicleWeight,
        //     },
        //     category: ac ?? {index: assetCategory, value: assetCategory},
        //     type: at ?? { index: assetType, value: assetType }
        //   }, // as AssetSelectionComponentValue,
        //   assetYear: parseFloat(getAssetYear(this.application) != '' ? getAssetYear(this.application) as any :  "0"),
        //   loanTermType: String(getLoanTerms(this.application)) as LoanTermType,
        //   financeType: financialType,
        //   balloonPaymentType: String(getBalloonPaymentPercentage(this.application)) as BalloonPaymentType,
        //   brokerageType: String(getBrokerage(this.application)) as BrokerageSelectionType,
        //   loanAmount: getLoanAmount(this.application),
        //   businessSearchValue: null,
        //   existingApplicationBureauReport: this.application.CompanyDetails,
        //   propertyOwnership: yesNoToBoolean(getPropertyOwner(this.application)),
        //   docFeeFinanced: yesNoToBoolean(getDocFeeFinanced(this.application)),
        //   brokerOriginationFee: getBrokerOriginationFee(this.application),
        //   brokerage: getBrokerage(this.application),
        //   adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
        //   equifaxScoreAbove600: yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
        //   privateSaleOrLeaseback: getPrivateSales(this.application) !== 'No',
        //   balloonPayment: getBalloonPaymentPercentage(this.application),
        //   balloonPaymentAmount: getBalloonPayment(this.application),
        //   rateCard: r,
        //   invoiceAmount: Number(getLoanAmount(this.application)) + Number(getDeposit(this.application)),
        //   deposit: Number(getDeposit(this.application)),
        //   brokerageAmount: this.application.PricingDetails?.BrokerageAmount ? getBrokerageAmount(this.application) : undefined,
        //   brokerOriginationFeeSf,
        //   docFeeSf,
        //   // rateSf,
        //   paymentType: String(getPaymentType(this.application)),
        //   creditRateAdjustment: getCreditRateAdjustment(this.application),
        // })

        // this.rst = rst.totalPaymentBreakupDialogData
        // this.repaymentEstimationData = rst.totalPaymentBreakupDialogData.repaymentEstimationData;
        // this.amortizationScheduleData = rst.totalPaymentBreakupDialogData.amortizationScheduleData;
        // this.paymentChartData = rst.totalPaymentBreakupDialogData.paymentChartData;
        // this.amortizationChartData = rst.totalPaymentBreakupDialogData.amortizationChartData;
      })
    ).subscribe();

    this.subscriptions.push(this.assetPricingEvents.subscribe((evt) => {
      switch(evt.type) {
        case "asset-pricing-saved": {
          this.rst = evt.rst.totalPaymentBreakupDialogData;
          this.repaymentEstimationData = evt.rst.totalPaymentBreakupDialogData.repaymentEstimationData;
          this.amortizationChartData = evt.rst.totalPaymentBreakupDialogData.amortizationChartData;
          this.paymentChartData = evt.rst.totalPaymentBreakupDialogData.paymentChartData;
          this.amortizationChartData = evt.rst.totalPaymentBreakupDialogData.amortizationChartData;
          break;
        }
      }
    }));

    this.subscriptions.push(sub);
    this.getApplicationAsset();
    this.getActiveContract();
    this.getBankDetails();
    this.loadAssetInspections();
  }

  recalculateEstimation() {
    if (this.ratecard) {
      const r = this.ratecard;
      const assetSpec = getAssetSpec(this.application);
      const assetGoodRetail = getAssetGoodRetail(this.application);
      const assetNewPrice = getAssetNewPrice(this.application);
      const assetCategory = getApplicationAssetCategory(this.application); // as string
      const assetType = getAssetType(this.application); // as string
      const ac = getAssetCategoryObj(this.application);
      const at = getAssetTypeObj(this.application);

      const date = moment().add(1, 'day');

      const financialType = (getFinanceType(this.application) as string).toLowerCase().split(' ').join('-') as FinanceType;
      const rst = calculateAssetFinanceEstimation(date, {
        type: 'AssetFinance',
        paymentFrequencyType: getRepaymentFrequency(this.application) ? getRepaymentFrequency(this.application) as PaymentFrequencyType : 'Monthly',
        assetConditionType: getAssetCondition(this.application) as AssetConditionType ?? null,
        assetSelectionValue: {
          // ...getAssetSpec(this.application) as AssetSpec, //  as { year: string, description: string },
          ...{
            make: assetSpec?.make,
            family: assetSpec?.family,
            year: assetSpec?.year ? String(assetSpec.year) : '',
            vehicle:  {
              goodretail: assetGoodRetail,
              newprice: assetNewPrice,
            },
            LVR: assetSpec?.LVR,
            description: assetSpec?.description ?? '',
            avgRetail: assetSpec?.avgretail,
            newPrice: assetSpec?.newPrice,
            OtherCar: assetSpec?.OtherCar ?? false,
            truckGrossVehicleWeight:  assetSpec?.truckGrossVehicleWeight,
          },
          category: ac ?? {index: assetCategory, value: assetCategory},
          type: at ?? { index: assetType, value: assetType }
        }, // as AssetSelectionComponentValue,
        assetYear: parseFloat(getAssetYear(this.application) != '' ? getAssetYear(this.application) as any :  "0"),
        loanTermType: String(getLoanTerms(this.application)) as LoanTermType,
        financeType: financialType,
        balloonPaymentType: String(getBalloonPaymentPercentage(this.application)) as BalloonPaymentType,
        brokerageType: String(getBrokerage(this.application)) as BrokerageSelectionType,
        loanAmount: getLoanAmount(this.application),
        businessSearchValue: null,
        existingApplicationBureauReport: this.application.CompanyDetails,
        propertyOwnership: yesNoToBoolean(getPropertyOwner(this.application)),
        docFeeFinanced: yesNoToBoolean(getDocFeeFinanced(this.application)),
        brokerOriginationFee: getBrokerOriginationFee(this.application),
        brokerage: getBrokerage(this.application),
        adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
        equifaxScoreAbove600: yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
        privateSaleOrLeaseback: getPrivateSales(this.application) !== 'No',
        balloonPayment: getBalloonPaymentPercentage(this.application),
        balloonPaymentAmount: getBalloonPayment(this.application),
        docFee: getDocFee(this.application) as number,
        rateCard: r,
        invoiceAmount: Number(getInvoiceAmount(this.application)),
        deposit: Number(getDeposit(this.application)),
        brokerageAmount: this.application.PricingDetails?.BrokerageAmount ? getBrokerageAmount(this.application) : undefined,
        paymentType: String(getPaymentType(this.application)),
        creditRateAdjustment: getCreditRateAdjustment(this.application),
        transactionType: applicationToTransactionValue(this.application)?.type,
      });

      this.rst = rst.totalPaymentBreakupDialogData
      this.repaymentEstimationData = rst.totalPaymentBreakupDialogData.repaymentEstimationData;
      this.amortizationScheduleData = rst.totalPaymentBreakupDialogData.amortizationScheduleData;
      this.paymentChartData = rst.totalPaymentBreakupDialogData.paymentChartData;
      this.amortizationChartData = rst.totalPaymentBreakupDialogData.amortizationChartData;
    }

  }

  loadAssetInspections() {

    console.log('============on inscpection created')
    this.subscriptions.push(
      this.getAssetInspectionsForApplicationFn(this.application.ApplicationId).pipe(
      ).subscribe((data: VerimotoInspectionTableDataWithInspectionDetails[]) => {
        console.log('===asset inspections: ', data);
        this.assetInspectionTableData = data;
      })
    )
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  getApplicationAsset() {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((res: PpsrAsset[]) => {
        const stageName = this.application.AppInfoStageName
        if (res.length) {
          // if (res[0].AssetSFRes !== null && res[0].AssetSFRes !== undefined) {
          //   this.disableAddAsset = true
          // }
          if(stageName == 'Closed Won' || stageName == 'Settlement') {
            this.disableAddAsset = false
          }
          this.assetStatus = res.filter(r => r.status === 'accepted');
          if (res.length !== this.assetStatus.length) {
            this.notAllAssetAccepted = false
          }
          else {
            this.notAllAssetAccepted = true
          }
        } else {
          this.disableAddAsset = false;
        }
      })
    )
  }

  getEvent() {
    this.currentSection = "asset"
    this.editAssetValue = null;
    this.selectionEvent.emit(this.currentSection);
  }

  getAssetSave() {
    this.currentSection = "asset"
    this.editAssetValue = null;
    this.isAssetEmpty = false;
    // if current stage is credit approved, change the stage to deal prep
    // if (getApplicationStage2(this.application.AppInfoStageName) === 'Credit Approved') {
    //   this.doUpdateApplicationStage('Deal Preparation', false);
    // }
    this.selectionEvent.emit(this.currentSection);
  }

  getBankDetails() {
    this.subscriptions.push(
      combineLatest([
        this.getAccountDetailsFromSfFn(this.application.AppInfo.BrokerSalesforceID ?? "0"),
        this.getAccountDetailsFromSfFn(this.application.AppInfo.CustomerId ?? "0"),
      ]).pipe(
        switchMap(([brokerBankDetails, customerBankDetails]: [LocalBankAccountDetailValue, LocalBankAccountDetailValue]) => {
          console.log('brokerBankDetails, customerBankDetails: ', brokerBankDetails, customerBankDetails)
          this.missingBankDetails = null;
          if (!brokerBankDetails?.AccountNumber || !brokerBankDetails?.BSB || !brokerBankDetails.AccountName) {
            this.missingBankDetails = 'broker';
            return of(null);
          } else if (!customerBankDetails?.AccountNumber || !customerBankDetails.BSB || !customerBankDetails.AccountName) {
            return this.getBankDetailsFromOpportunitySfFn(this.application.AppInfoSalesforceID ?? "");
          } else {
            return of(customerBankDetails);
          }
        })
      ).subscribe((result: LocalBankAccountDetailValue) => {
        if ((!result?.AccountNumber || !result?.BSB || !result?.AccountName) && this.missingBankDetails !== 'broker') {
          this.missingBankDetails = 'customer';
        }
        console.log('customer bank details on oppo level: ', result);
      })
    )
  }

  onEditAssetEvent($event: PpsrAssetValue) {
    this.editAssetValue = $event;
    this.currentSection = 'add-asset'
  }
  onRequestDoc() {
    let salesforceId = getAppSalesforceId(this.application)
    let data: any = []
    data = { "Data": { "StageName": "Documentation" }, "Object": "Opportunity", "ID": `${salesforceId}` }
    this.requestDocFn(data).pipe(
      this.toastService.spinnerObservable(),
      tap((r: PayloadApiResponse<any>) => {
        if( r && r.status ){
          this.dialogService.successDialog({
            message: `Success`,
            subMessage: r.payload.res
          });
        }
      })
    ).subscribe();
  }

  onPPSRCheckAll(element: any) {
    let ppsrAsset: PpsrAsset[] = [];
    let ppsrError = false;

    this.applicationDialogService.openPPSRCheckDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.getApplicationAssetFn(this.application.ApplicationId)
              .pipe(
                switchMap((res: PpsrAsset[]) => {
                  const subs = [];
                  for (let i = 0; i < res.length; i++) {
                    this.ppsrChecked = false;
                    if (res[i].SettlementAssetDetails?.serialNumber !== null && res[i].SettlementAssetDetails?.serialNumberType !== null && (res[i].PPSRStatus === 'unchecked' || res[i].PPSRStatus === 'issue') && res[i].SettlementAssetDetails?.assetCategory !== '141') {
                      ppsrAsset.push(res[i]);
                      subs.push(
                        this.assetSettlementSearchAssetsFn(this.application.ApplicationId, {
                          SearchNumber: res[i].SettlementAssetDetails?.serialNumber ,
                          NumberType: (res[i].SettlementAssetDetails?.serialNumberType as string).replace(/ +/g, ''),
                        })
                      )
                    }
                  }
                  if (!subs.length) {
                    return of([]);
                  }
                  return combineLatest(subs);
                }),
                this.toastService.spinnerObservable(),
                tap((results: (VehicleDetails | { Error: any } | null)[]) => {
                  if (!results || !results?.length) {
                    this.dialogService.openAlertDialog({
                      message: 'Warn',
                      subMessage: 'PPSR check is already done',
                    });
                  } else {
                    for (let i = 0; i < results.length; i++) {
                      const data = results[i];
                      if (data && (data as { Error: any })?.Error) {
                        let asset: any = {};
                        asset.AssetId = ppsrAsset[i].AssetId;
                        asset.PPSRStatus = 'issue';
                        this.updateAssetsToDatabase(asset, 'save')
                        ppsrError = true;
                      } else if (data && !(data as { Error: any })?.Error) {
                        let asset: any = {};
                        asset.AssetId = ppsrAsset[i].AssetId;
                        asset.SettlementAssetDetails = ({
                          ...ppsrAsset[i].SettlementAssetDetails,
                          VehicleDetails: data,
                        })
                        asset.PPSRStatus = 'confirmed';
                        this.updateAssetsToDatabase(asset, 'save')
                      }
                    }

                    if (ppsrError) {
                      this.dialogService.openAlertDialog({
                        message: 'Error',
                        subMessage: 'Please try after sometime',
                      });
                    } else {
                      this.dialogService.successDialog({
                        message: 'Success',
                        subMessage: 'PPSR check completed',
                      });
                    }
                  }
                })
              ).subscribe(() => { this.ppsrChecked = true; }) // refresh the table
          }
        })
      ).subscribe();
  }

  async updateAssetsToDatabase(assetUpdateData: PpsrAsset, key: string) {
    this.updateApplicationAssetsFn(assetUpdateData).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      tap(r => {
      }
      )).subscribe();
  }

  // onPPSRRegister() {
  //   this.applicationDialogService.openPPSRRegisterDialog()
  //     .afterClosed().pipe(
  //       tap(async (r) => {
  //         if (r) {
  //           // grantors
  //           const grantors = toGrantors(this.application);

  //           // assets
  //           const assets: any = [];
  //           let addDays = 1 + this.application.PricingDetails.LoanTerm
  //           this.getApplicationAssetFn(this.application.ApplicationId).pipe(
  //           ).subscribe((res: PpsrAsset[]) => {
  //             let asset: any = {};
  //             let r: any = res;
  //             for (const abstractControl of res) {
  //               this.ppsrRegistered = false;
  //               const formControl = abstractControl;
  //               const v: any = formControl;
  //               if (v && v.SettlementAssetDetails.assetCategory && v.PPSRStatus === 'confirmed') {
  //                 if (isAssetVehicles(v.SettlementAssetDetails.assetCategory.index)) {
  //                   const serialNumberType = v.SettlementAssetDetails.serialNumberType?.replace(/ +/g, '');
  //                   const serialNumber = v.SettlementAssetDetails.serialNumber;
  //                   if (!serialNumberType || !serialNumber) {
  //                     this.dialogService.openAlertDialog({
  //                       message: `Error`,
  //                       subMessage: `Please ensure Serial number and serial number type are valid for all vehicles`,
  //                     });
  //                     return;
  //                   }
  //                   assets.push({
  //                     CollateralClassType: 'MotorVehicle' as const,
  //                     SerialNumberType: serialNumberType,
  //                     SerialNumber: serialNumber,
  //                     RegistrationEndTime: moment().add(addDays, 'month').format('YYYY-MM-DDT23:59:59'),
  //                     CollateralDescription: v.SettlementAssetDetails.description,
  //                   });
  //                 } else {
  //                   assets.push({
  //                     CollateralClassType: 'OtherGoods' as const,
  //                     RegistrationEndTime: moment().add(addDays, 'month').format('YYYY-MM-DDT23:59:59'),
  //                   });
  //                 }
  //               }
  //             }
  //             const sub = this.submitPpsrFn(this.application.ApplicationId, {
  //               ID: this.application.BrokerAppId,
  //               Type: 'Commercial',
  //               Assets: assets,
  //               Grantors: grantors,
  //             }).pipe(
  //               this.toastService.spinnerObservable(),
  //               tap(r => {
  //                 const data: any = r
  //                 if (!data || data.Error) {
  //                   this.dialogService.openAlertDialog({
  //                     message: 'Error',
  //                     subMessage: 'Please try after sometime',
  //                   });
  //                 }
  //                 else {
  //                   this.dialogService.successDialog({
  //                     message: 'Success',
  //                     subMessage: 'PPSR registration done',
  //                   });
  //                   console.log('***** submitPpsr response', r);
  //                 }
  //               }),
  //               catchError(err => {
  //                 console.log(`error submitPpsr`, err);
  //                 this.dialogService.openAlertDialog({
  //                   message: 'Error',
  //                   subMessage: 'Please try after sometime',
  //                 });
  //                 return of(null);
  //               })
  //             ).subscribe(() => {
  //               for (let i = 0; i < res.length; i++) {

  //                 if (r[i].PPSRStatus === 'confirmed') {
  //                   asset.AssetId = r[i].AssetId;
  //                   asset.PPSRStatus = 'registered';
  //                   this.registered = true;
  //                   this.updateAssetsToDatabase(asset, 'save')
  //                 }
  //               }
  //                this.ppsrRegistered = true; });
  //             this.subscriptions.push(sub);
  //           });
  //         }
  //       })
  //     ).subscribe();
  // }
  onPPSRRegister() {
    this.applicationDialogService.openPPSRRegisterDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.submitPpsrFn(this.application.ApplicationId).pipe(
              this.toastService.spinnerObservable(),
              tap(r => {
                const data: any = r
                if (!data || data.Error) {
                  this.dialogService.openAlertDialog({
                    message: 'Error',
                    subMessage: 'Please try after sometime',
                  });
                }
                else {
                  this.dialogService.successDialog({
                    message: 'Success',
                    subMessage: 'PPSR registration done',
                  });
                  console.log('***** submitPpsr response', r);
                }
              }),
              catchError(err => {
                console.log(`error submitPpsr`, err);
                this.dialogService.openAlertDialog({
                  message: 'Error',
                  subMessage: 'Please try after sometime',
                });
                return of(null);
              })
            ).subscribe();
          }
        })
      ).subscribe();
  }

  applicationDocumentTypes(application: Application) {
    const tags = application?.DocumentTypes?.length ? application?.DocumentTypes.map(t => {
      return {
        ...t,
        value: t.value.toLowerCase()
      }
    }) : [];
    const documentTypes: DocumentTag[] = [];
    for (const tag of tags) {
      if (!documentTypes.map(t => t.value).includes(tag.value)) {
        documentTypes.push(tag)
      }
    }
    return documentTypes;
  }

  onSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.applicationDialogService.openSettleLoanDialog(true)
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendAssetAndDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      this.applicationDialogService.openSettleLoanDialog(false).afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            // check documents first
            this.subscriptions.push(
              combineLatest([
                this.listApplicationDocumentFn(this.application.ApplicationId),
                this.getApplicationByIdFn(this.application.ApplicationId)
              ]).pipe(
                  this.toastService.spinnerObservable()
                ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
                if (r) {
                  let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
                    app?.DocumentTypes?.length ? app?.DocumentTypes
                    : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
                    '',
                  );
                  docs = this.groupStandardCondition(docs, this.application);
                  docs = this.displayRequiredDocumentsOnly(docs);
                  const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
                  const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
                  const standardConditionDocs = docs.filter(d => d?.standardCondition);
                  const pendingGroups = [
                    ...standardDocs.filter(group => group.status === 'Pending'),
                    ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
                    ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
                  ];
                  console.log(pendingGroups);
                  if (pendingGroups.length) {
                    // do not allow settle loan
                    this.applicationDialogService.openAlertDialog({
                      message: 'Error',
                      subMessage: 'You cannot request loan settlement when you have pending items on the documents tab.'
                    }).afterClosed().subscribe();
                  } else {
                    this.doUpdateApplicationStage('QA');
                  }
                }
              })
            )
          }
        })
      ).subscribe()

    }
  }

  doUpdateApplicationStage(stageName: string, notification = true) {
    if (stageName) {
      this.subscriptions.push(
        this.updateApplicationStageFn({
          salesforceId: this.application.AppInfoSalesforceID ?? "",
          stageName: stageName
        }).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((response: ApiResponse) => {
          if (response.status) {
            if (notification) {
              this.applicationDialogService.successDialog({
                message: 'Success',
                subMessage: `Application sent to ${stageName} queue`,
              }).afterClosed().subscribe();
            }

            // Application stage needs to refresh on the page
            // This is the best I can do - as updateApplicationStageFn changes the stage in SF, and it takes some time
            // to sync the SF changes to the database. That's why I need to put the setTimeout here
            setTimeout(() => {
              this.getApplicationByIdFn(this.application.ApplicationId).subscribe((application: Application) => {
                this.application = application as AssetFinanceApplication;
              })
            }, 3000);
          } else {
            this.applicationDialogService.openAlertDialog({
              message: 'Error',
              subMessage: response.message,
            }).afterClosed().subscribe();
          }
        })
      )
    }

  }

  doSendAssetAndDisbursementToSf() {
    this.subscriptions.push(
      combineLatest([
        this.getApplicationAssetFn(this.application.ApplicationId),
        this.getDisbursementByApplicationIdFn(this.application.ApplicationId)
      ]).pipe(
        switchMap(([assets, disbursements]: [PpsrAsset[], Disbursement[]]) => {
          // WEB-3702
          // validate asset(s) individual deposit
          const {valid: assetDepositValid, messages} = validateAssetDeposits(assets);
          if (!assetDepositValid) {
            return of ({
              status: false,
              message: messages.join('<br/>')
            })
          }

          // WEB-3702
          // validate asset(s) deposit against application's deposit
          const appDeposit = getDeposit(this.application);
          const {totalDeposit, applicationDeposit, valid: applicationDepositValid} = validateAssetDepositAgainstApplicationDeposit(Number(appDeposit), assets);
          if (!applicationDepositValid) {
            return of ({
              status: false,
              message: `Application deposit $${applicationDeposit} doesn't match with total asset deposit $${totalDeposit}`,
            });
          }

          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
          console.log(assets, disbursements, this.application.AppInfoSalesforceID ?? "")
          return this.settleLoanFn({
            assets: assets,
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
        // reload asset table
        this.getApplicationAsset();
      })
    )
  }

  // doSendAssetToSalesforce() {
  //   this.subscriptions.push(
  //     this.getApplicationAssetFn(this.application.ApplicationId).pipe(
  //       this.toastService.spinnerObservable(),
  //       this.toastService.snackBarObservable('Asset Sent to Salesforce'),
  //       switchMap((assets: PpsrAsset[]) => {
  //         const allSfData: SendAssetToSfRecord[] = [];
  //         for (const asset of assets) {
  //           let sfData: SendAssetToSfRecord = {
  //             attributes: {
  //               type: "Asset",
  //               referenceId: String(asset?.AssetId ?? ""),
  //             },
  //             Name: (asset.SettlementAssetDetails?.description ?? "").substring(0, 255),
  //             Asset_Category__c: asset.SettlementAssetDetails?.assetCategory ?? "",
  //             Asset_Class__c: `${asset.SettlementAssetDetails?.assetCategory ?? ""}-${asset.SettlementAssetDetails?.assetType ?? ""}`,
  //             Opportunity__c: this.application.AppInfo.SalesforceId ?? "",
  //             Description: asset.SettlementAssetDetails?.description ?? "",
  //             Invoice_Price_incl_GST__c: asset.SettlementAssetDetails?.InvoicePrice ?? 0,
  //             GST__c: asset.SettlementAssetDetails?.GST ?? 0,
  //             SerialNumber: asset.SettlementAssetDetails?.serialNumber ?? "",
  //             Invoice_Number__c: asset.invoiceNumber ?? "",
  //             Manufacture_Year__c: String(asset.SettlementAssetDetails?.year ?? "").slice(0, 4),
  //             Supplier__c: "",
  //           }
  //           // supplier
  //           if (asset.SettlementAssetDetails?.supplier) {
  //             sfData.Supplier__c = (asset.SettlementAssetDetails?.supplier as any)?.SalesForceId
  //           } else { // private seller
  //           }
  //
  //           // vehicle details
  //           if (asset.SettlementAssetDetails?.VehicleDetails) {
  //             sfData.Make__c = asset.SettlementAssetDetails?.VehicleDetails?.Make ?? "";
  //             sfData.Model__c = asset.SettlementAssetDetails?.VehicleDetails?.Model ?? "";
  //           }
  //
  //           allSfData.push(sfData);
  //         }
  //         console.log("salesforce data: ", allSfData);
  //         return this.sendAssetToSfFn({
  //           records: allSfData
  //         }).pipe(
  //           tap(r=> {
  //             const data: any = r;
  //             if (data.payload[0].errors) {
  //               this.applicationDialogService.openAlertDialog({
  //                 message: 'Error',
  //                 subMessage: 'Please try after sometime.',
  //               });
  //             }
  //           })
  //         )
  //       }),
  //     ).subscribe()
  //   )
  // }

  emptyAssetFn($event: boolean) {
    this.isAssetEmpty = $event;
  }

  // NOTE: not used
  // assetAcceptedFn($event: boolean) {
  //   this.disableAddAsset = $event;
  // }

  disableSettleLoan($event: boolean){
    this.disableAddAsset = $event
    this.notAllAssetAccepted = $event
  }

  disableOnPPSRStatus($event: boolean){
    this.notAllAssetAccepted = $event
  }

  getActiveContract() {
    this.subscriptions.push(
      this.getActiveContractForApplicationFn(this.application.ApplicationId)
        .subscribe((r: ContractDetails | null) => {
          this.activeContract = r;
        })
    )
  }

  openGenerateContractDialog() {
    this.subscriptions.push(
      this.dialogService.openGenerateContractDialog({
        sendContractEmailsFn: this.sendContractEmailsFn,
        getRatecardDetailsFn: this.getRatecardDetailsFn,
        getApplicationIndividualsFn: this.getApplicationIndividualsFn,
        generateContractFn: this.generateContractFn,
        getApplicationAssetFn: this.getApplicationAssetFn,
        deleteContractFileFn: this.deleteContractFileFn,
        syncContractDetailsToSfFn: this.syncContractDetailsToSfFn,
        getApplicationOwnerFn: this.getApplicationOwnerFn,
        getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
        updateApplicationFn: this.updateApplicationFn,
        getOriginatorBusinessByIdFn: this.getOriginatorBusinessByIdFn,
        application: this.application,
        apiUrl: this.apiUrl,
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }

  openContractStatusDialog() {
    this.subscriptions.push(
      this.applicationDialogService.openContractStatusDialog({
        getContractStatusFn: this.getContractStatusFn,
        applicationId: this.application.ApplicationId,
        declineContractFn: this.declineContractFn,
        envelopeId: this.activeContract?.envelopeId ?? ""
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }

  private displayRequiredDocumentsOnly(docs: GroupedDocument[]) {
    return docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }

  onAssetPricingEvent($event: AssetPricingComponentEvent) {
    this.assetPricingEvents.emit($event)
  }
}
