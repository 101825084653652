import {Component, inject, Input, OnInit} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AsyncPipe, JsonPipe, NgClass, NgStyle } from "@angular/common";
import {MatTableModule} from "@angular/material/table";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
 
import {ExtendedModule} from "@angular/flex-layout/extended";
import {MatSortModule} from "@angular/material/sort";
import {CustomPaginatorComponent} from '../../components/custom-paginator-component/custom-paginator/custom-paginator.component'
import {LooseDatePipe} from '../../pipes/loose-date.pipe';
import {PortalHotToastService} from '../../components/portal-hot-toast-component/hot-toast.service'
import { setupUntilDestroy, } from "../component-utils";
import {UntilDestroy} from "@ngneat/until-destroy";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {loadingFor} from "@ngneat/loadoff";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {
    DEFAULT_LIMIT,
    DEFAULT_OFFSET,
    getAmount,
    getCreatedDate,
    getDate,
    getIsCredit,
    getIsPendingTransaction,
    GetPismoAccountDetailsFn,
    GetPismoTransactionsFn, getRelativeTransactionAmount,
    getSoftDescriptor,
    getTime,
    getTransactionAttributes,
    getTransactionCategory,
    GetTransactionDetailsFn,
    PismoTransactionsIncludingPending,
    getPismoTransactionType, PismoTransaction, PismoGetAccountResponse, PismoGenericTransaction, PismoPendingTransaction, GetCardFn, PismoCardDetails, GetTransactionFromTimelineFn,
} from "@portal-workspace/grow-shared-library";
import {tap} from "rxjs/operators";
import {combineLatest} from 'rxjs';
import _ from "lodash";
import {PageEvent} from "@angular/material/paginator";
import {MatDividerModule} from "@angular/material/divider";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";


export class OverdraftAccountTransactionsComponentInternalDataSource implements DataSource<PismoTransactionsIncludingPending[number]> {

    subject: BehaviorSubject<PismoTransactionsIncludingPending[number][]> = new BehaviorSubject<PismoTransactionsIncludingPending[number][]>([]);

    connect(collectionViewer: CollectionViewer): Observable<PismoTransactionsIncludingPending[number][]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
    }

    update(c: PismoTransactionsIncludingPending[number][]) {
        this.subject.next(c);
    }
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'overdraft-account-transactions',
    standalone: true,
    templateUrl: './overdraft-account-transactions.component.html',
    styleUrls: ['./overdraft-account-transactions.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ display: 'none', height: '0px', minHeight: '0' })),
            state('expanded', style({ display: 'block', height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    AsyncPipe,
    JsonPipe,
    FlexModule,
    MatInputModule,
    NgClass,
    MatFormFieldModule,
    ExtendedModule,
    MatTableModule,
    MatSortModule,
    CustomPaginatorComponent,
    LooseDatePipe,
    MatDividerModule,
    NgStyle,
    CustomContentLoaderComponent
],
})
export class OverdraftAccountTransactionsComponent implements OnInit {

    transactionType = getPismoTransactionType
    createdAt = getCreatedDate
    getTime = getTime
    getRelativeTransactionAmount = getRelativeTransactionAmount
    is_credit = getIsCredit
    amount = getAmount
    soft_descriptor = getSoftDescriptor
    isPendingTransaction = getIsPendingTransaction
    transactionCategory = getTransactionCategory
    getTransactionAttributes = getTransactionAttributes
    getDate = getDate

    loader = loadingFor('tableLoading', 'rowContentLoading');
    subscriptions: Subscription[] = [];

    displayedColumns = ['icon','description', 'amount']
    expandedElement!: PismoTransactionsIncludingPending[number] | null;
    dataSource = new OverdraftAccountTransactionsComponentInternalDataSource();
    total = 0;
    limit = DEFAULT_LIMIT;
    offset = DEFAULT_OFFSET;
    sorts?: {
        prop: 'ABN' | 'EntityName' | 'SalesforceId' | 'ACN',
        dir: 'ASC' | 'DESC'
    };


    transactionDetails:PismoTransaction | null = null;
    pendingPaymentTransaction: PismoPendingTransaction | null = null;
    accountDetails:PismoGetAccountResponse | null = null;
    pendingTransactionDetails: PismoPendingTransaction | null = null;
    cardDetails: PismoCardDetails | null = null;
    merchantTransaction: PismoTransaction | PismoPendingTransaction | null = null;

    @Input({required: true}) pismoAccountNumber!: number;
    @Input({required: true}) getPismoTransactionsFn!: GetPismoTransactionsFn;
    @Input({required: true}) getTransactionDetailsFn!: GetTransactionDetailsFn;
    @Input({required: true}) getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
    @Input({ required: true }) getCardFn!: GetCardFn;
    @Input({ required: true }) getTransactionFromTimelineFn!: GetTransactionFromTimelineFn;


    toastService: PortalHotToastService;

    constructor() {
        this.toastService = inject(PortalHotToastService);
    }


    ngOnInit() {
        setupUntilDestroy(this);
        this.reload();
    }

    isRowExpanded(element: PismoTransactionsIncludingPending[number]) {
        return element == this.expandedElement;
    }


    getColumnTitles(column: string): string {
        switch (column) {
            case 'icon': return ' ';
            case 'description': return 'Description';
            case 'amount': return 'Amount';
            default: return column;
        }
    }


    toClass(element: PismoGenericTransaction) {
        return this.transactionCategory(element) ? _.toLower(this.transactionCategory(element).replace(/\s/g, '')) : '';
    }


    onPagination($event: PageEvent) {
        this.limit = $event.pageSize;
        this.offset = $event.pageIndex;
        this.reload();
    }

    reload() {
        this.subscriptions.push(
            this.getPismoTransactionsFn(this.pismoAccountNumber,
                {
                    page: {
                        limit: this.limit, offset: this.offset,
                    }
                })
                .pipe(
                    this.loader.tableLoading.track(),
                    tap(r => {
                        console.log(r);
                        this.total = r.total;
                        this.dataSource.update(r.payload as unknown as Array<PismoTransactionsIncludingPending[number]>);
                    })
                ).subscribe());
    }

    isPismoTransaction(e: PismoTransactionsIncludingPending[number]): e is PismoTransaction {
        return (e as any).id;
    }

    getMerchantTransactionId(element: PismoTransaction | PismoPendingTransaction | null): string | number {
        return (element as PismoTransaction)?.id ?? (element as PismoPendingTransaction)?.data?.item?.id;
    }

    getMerchantNetworkToken(element: PismoTransaction | PismoPendingTransaction | null): string | number | null {
        return (element as PismoPendingTransaction)?.data?.item?.token_information?.network_token ?? null;
    }

    onRowClicked(element: PismoTransactionsIncludingPending[number] | null) {
        if (this.expandedElement == element) {
            this.expandedElement = null;
        } else if (element == null) {
            this.expandedElement = null;
        } else {
            this.expandedElement = element;
            console.log('Expanded element', element);
            if ((element as PismoTransaction).id) { // Normal Pismo Transaction
                this.pendingTransactionDetails = null;
                this.pendingPaymentTransaction = null;
                if ((element as PismoTransaction)?.merchant?.name|| (element as PismoTransaction)?.merchant?.id) {
                    const merchantElement = element as PismoTransaction;
                    this.subscriptions.push(combineLatest([
                        this.getTransactionFromTimelineFn(element.account_id, (element as PismoTransaction).authorization?.id, (element as PismoTransaction).event_date),
                        this.getCardFn(merchantElement.account_id, Number(merchantElement.customer_id), merchantElement.card.id),
                    ])
                        .pipe(
                            this.loader.rowContentLoading.track(),
                            tap(r => {
                                const [pismoTransaction, pismoCardDetails] = r;
                                this.merchantTransaction = pismoTransaction ?? element;
                                this.transactionDetails = null;
                                this.cardDetails = pismoCardDetails;
                            })
                        ).subscribe()
                    )
                } else {
                    this.merchantTransaction = null;
                    this.cardDetails = null;
                    if(this.isPismoTransaction(element)){
                        this.transactionDetails = element;
                    }else{
                        of(null);
                    }
                    this.subscriptions.push(combineLatest([
                        // this.isPismoTransaction(element) ? this.getTransactionDetailsFn(this.pismoAccountNumber, element.id) : of(null),
                        this.getPismoAccountDetailsFn([this.pismoAccountNumber]),
                    ]).pipe(
                        this.loader.rowContentLoading.track(),
                        tap(r => {
                            // const [pismoTransaction, pismoGetAccountResponse] = r;
                            const [pismoGetAccountResponse] = r;
                            // console.log('Pismo transaction details', pismoTransaction)
                            // this.transactionDetails = pismoTransaction ?? null;
                            this.accountDetails = pismoGetAccountResponse?.[0] ?? null;
                        })
                    ).subscribe());
                }
            } else { // Pending transaction
                this.transactionDetails = null;
                this.merchantTransaction = null;
                this.cardDetails = null;
                const pendingElement = element as PismoPendingTransaction;
                if(pendingElement.type == 'TRANSACTION') {
                    if (pendingElement?.account_id && pendingElement?.data?.item?.customer_id && pendingElement.data.item.card_id) {
                        this.subscriptions.push(
                            this.getCardFn(pendingElement.account_id, pendingElement.data.item.customer_id, pendingElement.data.item.card_id).pipe(
                                this.loader.rowContentLoading.track(),
                                tap(r => {
                                    console.log('result', r)
                                    this.cardDetails = r;
                                    this.pendingTransactionDetails = element as PismoPendingTransaction
                                })
                            ).subscribe()
                        )
                    } else {
                        this.pendingTransactionDetails = element as PismoPendingTransaction
                    }
                }
                else{
                    this.pendingPaymentTransaction = pendingElement;
                    this.pendingTransactionDetails = null;
                    this.cardDetails = null;
                }
                // element.account_id
                // console.log('Pending transaction details', this.pendingTransactionDetails.data?.item?.token_information?.network_token)
            }
        }
    }

}
