import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {EditableCurrencyComponent} from "./editable-currency.component";
import {EditableNumberComponent} from "./editable-number.component";
import {EditablePercentageComponent} from './editable-percentage.component';
import {EditableTextComponent} from './editable-text.component';
import { EditableEmailComponent } from "./editable-email.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    EditableCurrencyComponent,
    EditableNumberComponent,
    EditablePercentageComponent,
    EditableTextComponent,
    EditableEmailComponent
  ],
  exports: [
    EditableCurrencyComponent,
    EditableNumberComponent,
    EditablePercentageComponent,
    EditableTextComponent,
    EditableEmailComponent
  ]
})
export class EditableComponentModule {

}
