import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import {formControlErrorKeys, formControlErrorMessage, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {delay, distinctUntilChanged, tap } from 'rxjs/operators';
import {Address2ComponentValue, compareMatch, TimeInAddressValue, TimeInAddressValueOptions} from '@portal-workspace/grow-shared-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { MarkDirective } from '../../directives/mark-as-dirty.directive';
import { CustomAddressComponent } from '../address-component/custom-address.component';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'time-in-address',
    templateUrl: './time-in-address.component.html',
    styleUrls: ['./time-in-address.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeInAddressComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => TimeInAddressComponent) },
    ],
    standalone: true,
    imports: [FlexModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, CustomAddressComponent, MarkDirective]
})
export class TimeInAddressComponent extends AbstractControlValueAccessor<TimeInAddressValue> implements OnInit, Mark {

  subscriptions: Subscription[] = [];
  markObservable: Subject<boolean> = new Subject<boolean>();
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  monthsOptions = TimeInAddressValueOptions;

  @Input({required: false}) title: string = 'Time at address';

  formGroup: FormGroup;
  formControlYears: FormControl<number | null>;
  formControlMonths: FormControl<number | null>;
  formControlPreviousAddress: FormControl<Address2ComponentValue>;
  requirePrevAddress = false;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControlYears = formBuilder.control(null, [Validators.required, Validators.pattern('^\\d+$')]);
    this.formControlMonths = formBuilder.control(null, [Validators.required]);
    this.formControlPreviousAddress = formBuilder.control(null,[Validators.required]);
    this.formGroup = this.formBuilder.group({
      years: this.formControlYears,
      months: this.formControlMonths,
      previousAddress: this.formControlPreviousAddress,
    });
    const sub1 = this.formControlYears.valueChanges.pipe(
      delay(0),
      tap(r => {
        if (r!=undefined && (Number(r) == 0 || Number(r) == 1) ) {
          this.requirePrevAddress = true;
          this.formControlPreviousAddress.setValidators([Validators.required]);
        } else {
          this.requirePrevAddress = false;
          this.formControlPreviousAddress.setValidators(null);
        }
        this.formControlPreviousAddress.updateValueAndValidity();
      })
    ).subscribe();
    this.subscriptions.push(sub1);

    const sub2 = this.formGroup.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formGroup.valid) {
          this.propagateChange(this.formGroup.value);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub2);
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  doWriteValue(v: TimeInAddressValue | null | undefined): void | TimeInAddressValue {
    if (v) {
      this.formControlYears.setValue(v.years);
      const m = this.monthsOptions.find(o => o === v.months);
      if (m !== null && m !== undefined) {
        this.formControlMonths.setValue(m);
      }
      if (v.previousAddress) {
        this.formControlPreviousAddress.setValue({
          address: v.previousAddress.address,
          StreetNumber: v.previousAddress.StreetNumber,
          StreetName: v.previousAddress.StreetName,
          StreetType: v.previousAddress.StreetType,
          Suburb: v.previousAddress.Suburb,
          State: v.previousAddress.State,
          UnitNumber: v.previousAddress.UnitNumber,
          Postcode: v.previousAddress.Postcode,
        });
      }
    }
    return undefined;
  }

  mark(): void {
    this.formGroup.markAllAsTouched();
    this.markObservable.next(true);

  }

}
