<div class="asset-notes-component">
  <div class="row">
    <div class="col-12">

    <custom-angular-editor
      [formControl]="formControlApplicationNotes"
      (click)="show()"
      style="resize: none;"
      [placeholder]="placeholder"
      [height]="showButton? 250 : 70"
    ></custom-angular-editor>

    @if (showButton && isInternalUser(loggedInUser) && showInternalOnlyButton) {
      <mat-checkbox  [formControl]="formControlNotesIsVisible" color="primary">Internal Only</mat-checkbox>
    }

    @if (showButton) {
      <div class="position">
        <span class="md4 mr-3">
          <button mat-stroked-button (click)="cancel()">Cancel</button>
        </span>
        <span>
          <button mat-stroked-button (click)="saveNotes()" [disabled]="!formControlApplicationNotes.valid">Save
          Notes</button>
        </span>
      </div>
    }
  </div>
</div>
<mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
  <mat-tab label="NOTES">
    <div>
      <notes
        [applicationNotes]="applicationNotes" [total]="total"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
      ></notes>
    </div>
  </mat-tab>
  @if (!onlyShowNotes) {
    <mat-tab label="AUDIT LOG">
      <div>
        <audit-log
          [isAssetUpdated]="isAssetUpdated"
          [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        ></audit-log>
      </div>
    </mat-tab>
  }
</mat-tab-group>

</div>
