<div class="dscr-history-table">
  <div class="row">
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" class="width-100 mb-2" multiTemplateDataRows>
        @for (column of columnsToDisplay; track column) {
          <ng-container matColumnDef="{{column}}">
            <th mat-header-cell *matHeaderCellDef [ngClass]="{
                'column-width-1': column === 'lastUpdatedTime',
                'column-width-2': column === 'updatedBy',
                'column-width-3': column === 'data'
              }"> <div class="cell">{{getColumnTitles(column)}}</div> 
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="cell">
                @if (column === 'lastUpdatedTime') {
                  {{moment(element.lastUpdatedTime).format('DD/MM/YYYY HH:mm:ss')}}
                }
                @if (column === 'updatedBy') {
                  {{element?.GivenName ?? ''}} {{element?.FamilyName ?? ''}}
                }
                @if (column === 'data') {
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Industry</div>
                    <div class="col-lg-6 col-md-12">{{element?.industry?.name ?? ''}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Industry EBITDA</div>
                    <div class="col-lg-6 col-md-12">{{element?.industryEbitda?.name ?? ''}} {{element?.industryEbitda?.percentage ?? 0}}%</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">% Adjustment</div>
                    <div class="col-lg-6 col-md-12">{{element?.adjustmentPercent ?? 0}}%</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Margin Adopted</div>
                    <div class="col-lg-6 col-md-12">{{element?.marginAdoptedPercent ?? 0}}%</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 3 months</div>
                    <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage3Months | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 6 months</div>
                    <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage6Months | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Monthly Sales Average - 12 months</div>
                    <div class="col-lg-6 col-md-12">{{element?.monthlySalesAverage12Months | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Average Monthly Sales Adopted</div>
                    <div class="col-lg-6 col-md-12">{{element?.averageMonthlySalesAdopted | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Sales Adjustment</div>
                    <div class="col-lg-6 col-md-12">{{element?.salesAdjustment | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Average Monthly Sales Adopted after Adjustment</div>
                    <div class="col-lg-6 col-md-12">{{element?.averageMonthlySalesAdoptedAfterAdjustment | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Cash Available for Debt Service (Monthly)</div>
                    <div class="col-lg-6 col-md-12">{{element?.cashAvailableForDebtServiceMonthly | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Existing Debt Commitments (Monthly)</div>
                    <div class="col-lg-6 col-md-12">{{element?.existingDebtCommitmentsMonthly | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">ATO Payment Plan (Monthly)</div>
                    <div class="col-lg-6 col-md-12">{{element?.atoPaymentPlanMonthly | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Additional Commitments If Required</div>
                    <div class="col-lg-6 col-md-12">{{element?.additionalCommitments | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Proposed Dyna Commitment (Monthly)</div>
                    <div class="col-lg-6 col-md-12">{{element?.proposedCommitmentsMonthly | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Total Financing Commitments (Monthly)</div>
                    <div class="col-lg-6 col-md-12">{{element?.totalFinancingCommitmentsMonthly | looseCurrency}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Debt Service Cover Ratio</div>
                    <div class="col-lg-6 col-md-12">{{element?.debtServiceCoverRatio}}</div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-12 date-text">Notes</div>
                    <div class="col-lg-6 col-md-12">{{element?.notes}}</div>
                  </div>
                }
              </div>
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
      </table>

    </div>
  </div>
  </div>
