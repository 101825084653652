<div class="transaction-type-selection-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Transaction Type</mat-label>
      <mat-select [formControl]="formControl" [compareWith]="compareWithFn" [disableControl]="disabled">
        @for (option of options; track option) {
          <mat-option
            [disabled]="option.internalAccess && (!isInternalUser)"
            [value]="option">
            {{option.name}}
          </mat-option>
        }
      </mat-select>
      @for (errorKey of errorKeys(formControl); track errorKey) {
        <mat-error>
          @if (formControl.touched && formControl.hasError(errorKey)) {
            {{errorMessage(formControl, errorKey)}}
          }
        </mat-error>
      }
    </mat-form-field>
  </form>
</div>
